import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Map as LeafletMap, TileLayer, Marker, Popup, ZoomControl,  Tooltip } from 'react-leaflet';
import { withStyles } from '@material-ui/core/styles';
import './data/leaflet.css';
import L from 'leaflet'

import { setLocationAndGetStoresNearby } from '../../actions/location';
import { setFilteredStores } from '../../actions/searchfilters';


const storeIcon = (companyLogoLink) => {  
  return  new L.Icon({
      iconUrl: companyLogoLink,
      iconRetinaUrl: companyLogoLink,
      iconAnchor: [20, 40],
      popupAnchor: [0, -35],
      iconSize: [80, 40],
      shadowUrl: './resources/images/marker-shadow.png',
      shadowSize: [29, 40],
      shadowAnchor: [7, 40],
      className: 'storeMapIcon'
     })
}

const makerIconGreen  =  new L.Icon({
  iconUrl: './resources/images/marker-icon_green.png',
  iconRetinaUrl: './resources/images/marker-icon_green.png',
  iconAnchor: [20, 40],
  popupAnchor: [0, -35],
  iconSize: [25, 41],
  shadowUrl: './resources/images/marker-shadow.png',
  shadowSize: [29, 40],
  shadowAnchor: [7, 40],
})

const checkmarkIcon =  new L.Icon({
      iconUrl: './resources/images/checkmark6.png',
      iconRetinaUrl: './resources/images/checkmark6.png',
      iconAnchor: [20, 40],
      popupAnchor: [0, -35],
      iconSize: [25, 15],
      shadowUrl: './resources/images/marker-shadow.png',
      shadowSize: [29, 40],
      shadowAnchor: [7, 40],
})


const styles = theme => ({ 
  locationButton: {
    justifyContent: 'center'
  }, 
  lable: {
      color: '#000 !important',    
  }, 
  mapMarker: {
    backgroundColor: '#fff'
  }
});

const DEFAULT_VIEWPORT = {
  center: [50, -106],
  zoom: 6,
}

class Map extends React.Component {
  state = {  
    viewport: DEFAULT_VIEWPORT,          
    selectedStores:[],    
  }

  constructor (props) {
    super(props);  
    this.state.selectedStores = this.props.searchfilters.filteredStores;    
  }

  mapRef = React.createRef();
  

  componentDidMount () {        
      this.setInitLocation()
  }

  
  setInitLocation = () =>{
    // if (localStorage.getItem('latitude')) {      
      const {latitude, longitude} = this.props.location;      
      let initView = {
        center: [latitude, longitude],
        zoom: 12,
      }      
      this.setState({ 
        viewport: initView
      })         
    // }    
  }

  onViewportChanged = (viewport) => {
    this.setState({ viewport })
  }

  onClickReset = () => {
    this.setState({ viewport: DEFAULT_VIEWPORT })
  }

  handleStoreClick = (e) =>{
    const storeId = e.sourceTarget.options.value;
    let isOnList = false;    
    
    let updatedFilteredStores = this.props.searchfilters.filteredStores;    
    for (let store of updatedFilteredStores) {
              
      if (store._id === storeId){
       
        isOnList = true;

        updatedFilteredStores = updatedFilteredStores.filter((store) => {
          return store._id !== storeId;
        });
        break;
      }
    }

    if (!isOnList){
      for (let store of this.props.location.storesNearby) {
        if (store._id === storeId) {
          updatedFilteredStores.push(store);
          break;
        }
      }  
    }

    let companyIndex = -1; 
    let companyName = ''; 

    for (let store of this.props.location.storesNearby) {      
      if (store._id === storeId){
        break;
      }
      if (store.companyData.name !== companyName){        
        companyName = store.companyData.name;     
        companyIndex++;   
      }        
      
    }
                             
    this.props.setFilteredStores(updatedFilteredStores);
    this.props.handleMapUpdate(updatedFilteredStores, companyIndex);
    
  } 
  
  
  render() {
    const { classes, searchfilters } = this.props;
    const stores = this.props.location.storesNearby; 
    
    //  console.log(this.props.location);
    return (
      <LeafletMap         
        maxZoom={18}
        attributionControl={true}
        zoomControl={true}
        doubleClickZoom={true}
        scrollWheelZoom={true}
        dragging={true}
        animate={true}
        easeLinearity={0.35}
        viewport={this.state.viewport}      
        ref={this.mapRef}  
        zoomControl={false}
                
        //onViewportChanged={this.onViewportChanged}
        //onClick={this.onClickReset}
        //className={classes.leafletPane}
      >
      <ZoomControl position="topright" />
        <TileLayer
          url='//{s}.tile.osm.org/{z}/{x}/{y}.png'
        />  
                 
        {
          stores.map((store) => {
            return (  
              <div key={store._id}>
                <Marker 
                  position={[store.location.lat, store.location.lon]}
                  icon={storeIcon(store.companyData.companyLogoLink)}
                  //icon={makerIconGreen}                  
                  onClick={this.handleStoreClick}
                  value = {store._id}                 
                >
                  <Popup>
                    {store.name} 
                  </Popup>
                  <Tooltip direction='center' offset={[0, -50]} opacity={0} permanent>
                       <span>{store.companyData.name}</span>
                  </Tooltip>
                </Marker>
              </div>
            )
          })
        }  
        {
          searchfilters.filteredStores.map((store) => {
            //if (this.state.selectedStores.includes(store._id)){
              return (  
                <div key={store._id}>
                  <Marker 
                    position={[store.location.lat, store.location.lon]}
                    icon={checkmarkIcon} 
                    onClick={this.handleStoreClick}
                    value = {store._id}                 
                  >                 
                  </Marker>
                </div>
              )
            //}                        
          })
        } 

        <Marker 
          position={this.state.viewport.center}          
          //icon={makerIconGreen}          
        >
          <Popup>
            Your location 
          </Popup>
          <Tooltip direction='center' offset={[0, -50]} opacity={1} permanent>
            <span>Your location</span>
          </Tooltip>
        </Marker>   

      </LeafletMap>
    );
  }
}

const mapStateToProps = (state, props) => ({
  location: state.location,  
  searchfilters: state.searchfilters,
});

const mapDispatchToProps = (dispatch, props) => ({
  setFilteredStores: (stores) => { dispatch(setFilteredStores(stores)) },
  //setLocation: (postal, latitude, longitude) => dispatch(setLocation(postal, latitude, longitude)),  
  //setLocationAndUpdateStoresNearby: (postal, latitude, longitude) => dispatch(setLocationAndUpdateStoresNearby(postal, latitude, longitude)),
  setLocationAndGetStoresNearby: (postal, latitude, longitude) => dispatch(setLocationAndGetStoresNearby(postal, latitude, longitude))
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Map));
