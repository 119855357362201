import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import ShareProductToEmailContent from './ShareProductToEmailContent';
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    //marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function ShareProductToEmailDialog(props) {
  const classes = useStyles();
  const { open, onClose, product, onShareSuccess, productShareMail} = props;
  
  return (
    <Dialog
     //fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      aria-labelledby="simple-dialog-title"
    >
      <DialogTitle  id="simple-dialog-title">
      Sharing this deal with your friends is really nice!
      </DialogTitle>
       <ShareProductToEmailContent 
              onShareSuccess={ onShareSuccess}  
              product = {product} 
              productShareMail={productShareMail}
            />              
    </Dialog>
  );
}
