import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: '2px 4px',
    display: "flex",
    alignItems: "center",
    width: 292,
    // marginBottom:'8px',
    backgroundColor: "#f6f6f6",
    border: "1px solid " + theme.palette.primary.main,
    borderRadius: "8px",
  },
  searchIcon: {
    zIndex: "1000",
    top: "0px",
    right: "0px",
    backgroundColor: "#2650a2",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: "4px 16px",
    color: "#FFF",
  },
}));

export default function SearchBox({value='', doSearch, placeholder='Search', searchOnChange=true }) {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState(value);
  const handleChange = (e) => {
    setSearchValue(e.target.value);
    searchOnChange && doSearch(e.target.value);
  };
  const handleClear = () => {
    setSearchValue("");
    doSearch("");
  };
  const keyPressed = ({ key }) => {
    if (key === "Enter") {
      handleClick();
    }
  };
  const handleClick=()=>{
    doSearch(searchValue);
  }
  const submitHandler = (e) => {
    // Prevent form submission on Enter key
    e.preventDefault();
  };
  return (
    <Paper component="form" className={classes.root} onSubmit={submitHandler}>
      <InputBase
        className={classes.input}
        placeholder={placeholder}
        onChange={handleChange}
        value={searchValue}
        defaultValue={value}
        onKeyPress={keyPressed}
        inputProps={{ "aria-label": "search" }}
        autoFocus={true}
      />
      <div className={classes.searchIcon}>
        {value.length > 0 ? (
          <IconButton
            onClick={handleClear}
            className={classes.iconButton}
            aria-label="clear"
          >
            <ClearIcon />
          </IconButton>
        ) : (
          <IconButton className={classes.iconButton} aria-label="search" onClick={handleClick}>
            <SearchIcon />
          </IconButton>
        )}
      </div>
    </Paper>
  );
}
