import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import ReactGA from "react-ga";

const styles = theme => ({
  errorContainer: {
    ...theme.mixins.gutters(),
    margin: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  }
});

const ErrorMessage = (props) => {
  const { classes } = props;
  return (
    <div>
      <Paper className={ classes.errorContainer } elevation={1}>
        <Typography variant="h5" component="h3" align="center">
          Sorry, something went wrong.
        </Typography>
        <Typography component="p" align="center">
          We're working on it and we'll get it fixed as soon as we can.
        </Typography>
      </Paper>
    </div>
  );
}

export default withStyles(styles)(ErrorMessage);
