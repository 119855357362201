import React, { Component } from "react";
// import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import { Utility } from "../../utility/utility";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import ErrorMessage from "../Error/ErrorMessage";
import { setSignUpLoader, setSignUpError } from "../../actions/session";
import globalStyles from "../../styles/styles";
import axios from "axios";
import envConfig from "../../bin/env.config";

const styles = (theme) => ({
  root: {},
  buttonProgress: {
    ...globalStyles.buttonSpinnerProgress,
  },
  notifyMessage:{
    maxWidth: "750px",
    // color: theme.palette.secondary.main,
    // margin:"8px 8px 8px 8px",
    padding:"8px",
    border:"1px solid rgb(230, 230, 230)",
    borderRadius: "4px",
    backgroundColor:"rgb(242, 242, 242)",
    textAlign: "center",
    fontFamily: "Montserrat",
    fontSize: "16px",
    // textJustify: "inter-word",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      fontWeight:"italic"
    },
    "& strong":{
      fontFamily: "Montserrat",
    }
  },
});

class ResetPasswordRequest extends Component {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
    this.onValueChange = this.onValueChange.bind(this);
    this.keyPress = this.keyPress.bind(this);
  }

  componentDidMount() {
    this.setState(this.getDefaultState());
    setSignUpLoader(false);
    setSignUpError(null);
  }

  getDefaultState = () => {
    return {
      fields: {
        email: "",
      },
      errors: {},
      showPasswordResetRequestLoader: false,
      showPasswordResetSuccess: false,
      resetError: false,

      showPasswordResetNotification: false,
    };
  };

  onValueChange = (e) => {
    e.persist();
    this.setState((state) => {
      return (state.fields[e.target.name] = e.target.value);
    });
    if (e.target.value.length === 0) {
      this.setState({
        errors: {},
      });
    }
  };
  
  keyPress(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.onResetPassword();
    }
  }
  validateForm = () => {
    let fields = this.state.fields;
    let isFormValid = true;
    let errors = {};

    if (
      !Utility.isSet(fields["email"]) ||
      fields["email"] === null ||
      fields["email"].length === 0
    ) {
      errors["email"] = `Email is required`;
      isFormValid = false;
    }

    if (!Utility.isEmailValid(fields["email"])) {
      errors["email"] = `Please enter a valid email address`;
      isFormValid = false;
    }

    this.setState({
      errors: errors,
    });

    return isFormValid;
  };

  hidePasswordResetNotification = () => {
    this.setState({
      showPasswordResetNotification: false,
    });
  };

  onResetPassword = () => {
    if (!this.validateForm()) {
      return;
    }

    this.setState({
      showPasswordResetRequestLoader: true,
    });
    const postData = {
      email: this.state.fields["email"],
      url: `${window.location.protocol}//${window.location.hostname}`,
    };
    const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/users/resetpassword/request`;
    axios
      .post(url, postData)
      .then((response) => {
        this.setState({
          showPasswordResetRequestLoader: false,
          showPasswordResetSuccess: true,
          showPasswordResetNotification: true,
        });
      })
      .catch((err) => {
        // console.log(err.response);
        if(err.response.data.success===false){          
          let errors = {};
          errors["email"] = err.response.data.err_msg;
          this.setState({
            errors: errors,
            showPasswordResetRequestLoader: false,
          });
        }else{
          this.setState({
            showPasswordResetRequestLoader: false,
            resetError: true,
          });
        }
      });
  };

  onBack = () => {
    this.props.history.push("/");
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <AppBar position="sticky" color="inherit">
          <Toolbar>
            <IconButton onClick={this.onBack}>
              <CloseIcon />
            </IconButton>

            <Typography variant="subtitle1" color="inherit">
              Reset Password
            </Typography>
          </Toolbar>
        </AppBar>
        <br />
        <Grid
          container
          alignItems="center"
          alignContent="center"
          justify="center"
        >
          <Grid item xs={10} sm={10} md={6} lg={4} xl={4}>
            <form className={classes.container} noValidate autoComplete="off">
              {this.state.resetError && <ErrorMessage />}
              {this.state.showPasswordResetNotification ? (
                <>
                  <Typography className={classes.notifyMessage}>
                    We’ve sent a link to reset your password to 
                    "{ this.state.fields["email"]}". If you have a BetterCart account, you’ll see our
                    email in your inbox. Please check your spam folder too.
                  </Typography>
                  <br/>
                  <Button onClick={this.onBack} color="secondary" variant="contained" fullWidth={true}>
                    Return home
                  </Button>
                </>
              ) : (
                <>
                  <Typography className={classes.notifyMessage}>
                    Enter your email address to reset your password. <br />
                    You may need to check your spam folder or unblock
                    support@bettercart.ca
                  </Typography>

                  <div>
                    <TextField
                      error={!!this.state.errors["email"]}
                      label="Email"
                      name="email"
                      type="email"
                      placeholder="Enter your email"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      onKeyDown={this.keyPress}
                      onChange={this.onValueChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      helperText={this.state.errors["email"]}
                    />
                  </div>

                  <Button
                    onClick={this.onResetPassword}
                    color="primary"
                    variant="contained"
                    fullWidth={true}
                    disabled={this.state.showPasswordResetRequestLoader}
                  >
                    Reset Password
                    {this.state.showPasswordResetRequestLoader && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </Button>
                </>
              )}
            </form>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(ResetPasswordRequest));
