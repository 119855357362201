import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import axios from "axios";
import envConfig from "../../bin/env.config";
import store from "../../store/store";

import SearchBox from "./SearchBox";
import ProductToMatch from "./PossibleExisting/ProductToMatch";
import MatchingGroup from "./PossibleExisting/MatchingGroup";
import SearchMatchingGroup from "./PossibleExisting/SearchMatchingGroup";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
}));

export default function PossibleExistingMatchingGroup(props) {
  const history = useHistory();
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);

  const [original, setOriginal] = useState({});
  const [possibleGroups, setPossibleGroups] = useState([]);
  const productID = props.match.params.id;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/possible-existing/${productID}`;
      const result = await axios(url, {
        headers: {
          Authorization: JSON.stringify(store.getState().session.token),
        },
      });
      // console.log(result.data.data.original);
      if(result.data.data.original.product_matching_group_id){
        history.push(`/matching-potential/${result.data.data.original._id}`);
      }else{
        setOriginal(result.data.data.original);
        setPossibleGroups(result.data.data.groups);
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  return (
    <>
      <AppBar position="sticky" color="inherit">
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={() => history.goBack()}
            aria-label="Close"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            Possible existing matching group
          </Typography>
          {/* <Typography variant="h6" noWrap>
            {original.name} {original.size}
          </Typography> */}
        </Toolbar>
      </AppBar>

      <Container maxWidth="lg">
        {isLoading ? (
          <Grid
            className={classes.root}
            container
            spacing={1}
            alignItems="center"
            justify="center"
          >
            <div>Loading ...</div>
          </Grid>
        ) : (
          <>
            <Grid
              className={classes.root}
              container
              spacing={1}
              // alignItems="center"
              // justify="center"
            >
              <Grid
                container
                alignItems="center"
                justify="center"
                style={{
                  backgroundColor: "black",
                  color: "white",
                  padding: "4px 8px",
                }}
              >
                <Typography className={classes.title} variant="h6" noWrap>
                  Product to match
                </Typography>
              </Grid>
              <Grid container>
                {original && <ProductToMatch product={original} />}
              </Grid>
              <Grid
                container
                alignItems="center"
                justify="center"
                style={{
                  backgroundColor: "#6b6b6b",
                  color: "white",
                  padding: "4px 8px",
                }}
              >
                <Typography className={classes.title} variant="h6" noWrap>
                  Possible existing matching groups
                </Typography>
              </Grid>
              <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                  {possibleGroups.map((item, i) => (
                    <MatchingGroup
                      productToMatch={original}
                      matchingGroup={item}
                    />
                  ))}
                </Table>
              </TableContainer>
              <SearchMatchingGroup productToMatch={original} />
              {/* {loadMore && <ProductMatchingItemLoading doLoadMore={doLoadMore} />} */}
            </Grid>
          </>
        )}
      </Container>
    </>
  );
}
