import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";

import Paper from "@material-ui/core/Paper";

import MatchingGroup from "./MatchingGroup";

import SearchBox from "../SearchBox";
import axios from "axios";
import envConfig from "../../../bin/env.config";
import store from "../../../store/store";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 350,
    "& tbody>.MuiTableRow-root:hover": {
      background: "#DDD",
    },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
}));

export default function SearchMatchingGroup({productToMatch}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [limit, setLimit] = useState(100);
  const [skip, setSkip] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [groups, setGroups] = useState([]);  
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/groups?orderBy=${orderBy}&order=${order}&limit=${limit}&skip=${skip}`;
      const result = await axios.post(
        url,
        { q: searchText },
        {
          headers: {
            Authorization: JSON.stringify(store.getState().session.token),
          },
        }
      );
      // console.log(result.data.data);
      setGroups(result.data.data);
      setDataSource(result.data.data)
      setIsLoading(false);
    };
    fetchData();
  }, [searchText]);
  const doSearch = (q) => {
    //doSearch
    setSearchText(q);
    // setGroups(
    //   dataSource.filter(
    //     (item) =>
    //       item.fullname.toLowerCase().includes(q.toLowerCase())
    //   )
    // );
  };  

  return (
    <>
      <Grid
        container
        alignItems="center"
        justify="center"
        style={{
          backgroundColor: "#dedede",
          color: "#000",
          padding: "4px 8px",
        }}
      >
        <Typography className={classes.title} variant="h6" noWrap>
          Search existing matching groups
        </Typography>
        <SearchBox value={searchText} doSearch={doSearch} />
      </Grid>
      <Grid container>
        {isLoading ? (
          <div>Loading ...</div>
        ) : (
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableBody>
                {groups.map((item, i) => (
                  <MatchingGroup
                    productToMatch={productToMatch}
                    matchingGroup={item}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
    </>
  );
}
