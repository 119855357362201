import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import Slide from "@material-ui/core/Slide";
import Slider from "react-slick";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Fade from "@material-ui/core/Fade";

import SearchIcon from "@material-ui/icons/Search";
import Help from "@material-ui/icons/Help";
import LocationOn from "@material-ui/icons/PinDrop";
import Store from "@material-ui/icons/Store";
import CloseIcon from "@material-ui/icons/Close";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Person from "@material-ui/icons/Person";
import Favorite from "@material-ui/icons/Favorite";
import ExitToApp from "@material-ui/icons/ExitToApp";
import Share from "@material-ui/icons/Share";
import ContactSupport from "@material-ui/icons/ContactSupport";
import HelpOutline from "@material-ui/icons/HelpOutline";
import ExpandMore from "@material-ui/icons/ExpandMore";

import Snackbar from "@material-ui/core/Snackbar";

import LocationPicker from "../LocationPicker/LocationPicker";
import LoginPage from "../Login/LoginPage";
import AdvertisingBanner from "../WhatInStock/AdvertisingBanner";

import { setFilteredStores } from "../../actions/searchfilters";
import {
  setLocation,
  setLocationAndUpdateStoresNearby,
} from "../../actions/location";


import { Helpers } from "../../utility/helpers";
import { logout } from "../../actions/session";

import Heading from "../Heading/Heading";

// import Iframe from "react-iframe";

import ReactGA from "react-ga";

import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from "react-share";

import {
  FacebookIcon,
  FacebookMessengerIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
} from "react-share";

let drawerWidth = 0;

const styles = (theme) => ({
  root: {
    display: "100%",
  },
  hero: {
    position: "relative",
    //backgroundColor: theme.palette.grey[10],
    color: theme.palette.common.white,
    //marginBottom: theme.spacing(1),
    borderRadius: "0px",
    boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
    backgroundColor: "#2c1c12",
    // backgroundImage: 'url(./resources/images/tomatoes-on-shelves2.jpg)',
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    paddingBottom: "50px",
    minHeight: "90vh",
    [theme.breakpoints.down("sm")]: {
      // backgroundImage: 'url(./resources/images/tomatoes-on-shelves2--mobile.jpg)',
      minHeight: "90vh",
      height: "auto",
      paddingBottom: theme.spacing(2) + "px",
    },
  },
  underHero: {
    fontFamily: "Montserrat",
    textAlign: "center",
    padding: "20px 20px 30px 40px",
    color: theme.palette.secondary.main,
    [theme.breakpoints.down("sm")]: {
      "& h2": {
        fontSize: "1.2em",
      },
    },
  },
  underHeroTitle: {
    fontSize: "3em",
    fontFamily: "Montserrat",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2em",
    },
  },
  underHero2: {
    fontFamily: "Montserrat",
    textAlign: "center",
    padding: "20px 20px 30px 20px",
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.down("sm")]: {
      "& h2": {
        fontSize: "1.2em",
      },
    },
  },
  underHero2Title: {
    fontFamily: "Montserrat",
    fontSize: "3em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2em",
    },
  },
  underHero3: {
    fontFamily: "Montserrat",
    textAlign: "center",
    padding: "20px 20px 30px 20px",
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      "& h2": {
        fontSize: "1.2em",
      },
    },
  },
  underHero3Title: {
    fontFamily: "Montserrat",
    fontSize: "3em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2em",
    },
  },
  welcomeMessage: {
    textAlign: "center",
    padding: theme.spacing(2) + "px",

    marginBottom: theme.spacing(3) + "px",
    background: theme.palette.primary.main,
    //backgroundImage: 'url(./resources/images/cart-background.jpg)',
    background: "transparent",
    backgroundPosition: "center",
    textShadow: "0 0 10px #000",
    // textShadow:"-1px -1px 0 #00B04F, 1px -1px 0 #00B04F,-1px 1px 0 #00B04F,1px 1px 0 #00B04F",
    // textShadow:"-1px -1px 0 rgba(0, 0, 0, 0.12), 1px -1px 0 rgba(0, 0, 0, 0.12),-1px 1px 0 rgba(0, 0, 0, 0.12),1px 1px 0 rgba(0, 0, 0, 0.12)",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2) + "px",
      marginBottom: theme.spacing(2) + "px",
    },
    [theme.breakpoints.up("lg")]: {
      // marginTop: theme.spacing(10),
    },
    [theme.breakpoints.up("lxl")]: {
      // marginTop: theme.spacing(10),
    },
    [theme.breakpoints.up("xl")]: {
      // marginTop: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "2px",
      marginBottom: "2px",
    },
  },
  welcomeMessageText: {
    color: "#fff",
    fontWeight: "bold",
    //textShadow: '0px 0px 35px rgba(0,0,0,0.99)',
    //paddingBottom: theme.spacing(8) + "px",
    fontSize: "5em",
    fontFamily: "Montserrat",
    //lineHeight: '1.5em',
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "3em",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.5em",
    },
  },
  welcomeMessageSubText: {
    display: "none",
    maxWidth: "80%",
    marginLeft: "auto",
    marginRight: "auto",
    fontSize: "28px",
    paddingTop: "10px",
    textShadow: "0 0 20px #000",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
    },
    [theme.breakpoints.up("down")]: {
      width: `100&`,
    },
  },
  button: {
    marginRight: "2px",
  },
  buttonText: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: "16px",
    margin: "4px 4px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      margin: "2px 2px",
    },
  },
  startButtonText: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: "26px",
    margin: "4px 4px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      margin: "2px 2px",
    },
  },
  iconButtonText: {
    fontSize: "large",
    [theme.breakpoints.down("sm")]: {
      fontSize: "medium",
    },
  },
  showMoreButton: {
    fontSize: "80px",
    fontWeight: "bold",
    position: "absolute",
    bottom: "25px",
    left: `calc(50% - 40px)`,
    textShadow: "0 0 20px #000",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  menuBar: {
    [theme.breakpoints.up("md")]: {
      marginRight: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
    },
    [theme.breakpoints.up("down")]: {
      width: `100&`,
    },
  },
  homeToolbar: {
    paddingLeft: "12px",
    [theme.breakpoints.down("sm")]: {
      padding: "5px",
    },
  },
  menuToolbar: {
    paddingLeft: "12px",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -10,
    // marginRight: 20,
  },
  link: {
    marginRight: theme.spacing(2),
    //margin: theme.spacing(1, 1.5),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    border: "1px solid #eee",
    // backgroundColor: fade(theme.palette.common.white, 0.15),
    // '&:hover': {
    //   backgroundColor: fade(theme.palette.common.white, 0.25),
    // },
    // backgroundColor: fade(theme.palette.common.black, 0.15),
    // '&:hover': {
    //   backgroundColor: fade(theme.palette.common.black, 0.25),
    // },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(3),
      //marginRight: theme.spacing(2),
      width: "auto",
    },
  },
  enterPostalInstructions: {
    color: "#000",
  },
  searchIcon: {
    width: theme.spacing(9),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "1000",
  },

  postalPopup: {
    textAlign: "center",
  },

  popupLogo: {
    width: "300px",
    margin: "20px auto",
    maxWidth: "100%",
  },

  secondaryToolbar: {
    padding: "0px",
    minHeight: "25px",
  },

  loginButton: {
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      marginRight: "5px !important",
      padding: "4px",
    },
  },

  loginIcon: {
    //marginRight: theme.spacing(1)
  },
  logoText: {
    color: "#fff",
    fontWeight: "600",
    display: "inline",
    textAlign: "center",
    fontSize: "36px",
    fontFamily: "Montserrat",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    textShadow: "0 0 20px #000",
    //padding: theme.spacing(2)  + 'px',
  },
  logo: {
    height: "60px",
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      height: "35px",
      padding: theme.spacing(1),
      display: "none",
    },
    //cursor: 'pointer'
  },

  slider: {
    padding: 0,
    margin: 0,
    "& .slick-dots": {
      //display: 'none !important'
      position: "absolute !important",
      left: "-24%",
      bottom: "5%",
    },
    [theme.breakpoints.down("sm")]: {
      "& .slick-dots": {
        //display: 'none !important'
        position: "absolute !important",
        left: "0%",
        top: "50%",
      },
      zIndex: "-1",
    },
  },

  marketing: {
    display: "flex !important",
    flexWrap: "wrap",
  },
  marketingContent: {
    flex: "1 300px",
    minWidth: 0,
    backgroundColor: "#fff",
    textAlign: "center",
    padding: theme.spacing(2),
  },
  marketingMedia: {
    flex: "1 300px",
    minWidth: 0,
  },
  marketingImage: {
    clipPath: "polygon(0 0, 100% 0%, 100% 100%, 15% 100%)",
    //clipPath: 'polygon(30% 0, 100% 0%, 100% 100%, 0 100%)',
    height: "450px",
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "100px",
      clipPath: "polygon(0 0, 100% 20%, 100% 100%, 0 100%)",
    },
  },
  marketingTitle: {
    //padding: '50px 5px !important',
    paddingTop: "20px",
    textAlign: "center",
    color: theme.palette.primary.main,
    fontWeight: "bold",
    marginBottom: "1em",
    fontFamily: "Montserrat",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  marketingDetails: {
    color: theme.palette.primary.main,
    textAlign: "center",
    marginBottom: "0.75em",
    fontSize: "1.4em",
    fontFamily: "Montserrat",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    [theme.breakpoints.up("md")]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  locationList: {
    paddingTop: "0",
  },
  cartButton: {
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(1),
    },
  },
  shareButton: {
    display: "inline-block",
    marginRight: "10px",
    "&:hover": {
      opacity: "0.5",
    },
  },
  map: {
    //background: theme.palette.secondary.main,
    //background: '#12C50D',
    color: theme.palette.primary.main,
    padding: theme.spacing(4),
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  mapImage: {
    width: "80%",
    maxWidth: "1000px",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },

  activityContent: {
    //background: theme.palette.secondary.main,
    display: "flex",
    flexWrap: "wrap",
    background: "#eef1f0",
    color: theme.palette.primary.main,
    paddingLeft: theme.spacing(10),
    padding: theme.spacing(4),
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      //paddingLeft: theme.spacing(10),
      padding: theme.spacing(3),
    },
  },
  card: {
    flex: "1 300px",
    margin: "10px 10px",
    maxWidth: 400,
    height: 450,
    [theme.breakpoints.down("sm")]: {
      height: 350,
    },
  },
  cardImage: {
    clipPath: "polygon(0 0, 100% 0%, 100% 100%, 0 80%)",
  },
  getStarted: {
    //background: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    padding: theme.spacing(4),
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  footer: {
    background: theme.palette.secondary.main,
    //background: '#12C50D',
    color: "#fff",
    padding: theme.spacing(4) + "px",
    textAlign: "center",
  },
  footerHeading: {
    color: "#fff",
    fontWeight: "bold",
    fontFamily: "Montserrat",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  footerCopyright: {
    color: "#fff",
    marginTop: theme.spacing(4) + "px",
    fontFamily: "Montserrat",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  footerLogo: {
    width: "150px",
    [theme.breakpoints.down("sm")]: {
      width: "70px",
    },
  },
  version:{
    width:'100%',
    textAlign:'right',
    color:'#008931'
  },
  storeLogos: {
    maxWidth: "80%",
    margin: "0 auto 50px auto",
    "& img": {
      padding: "15px",
      maxWidth: "70%",
      margin: "0 auto",
    },
  },
  discoverAndShare: {
    textAlign: "center",
    "& h2": {
      fontSize: "2em",
      marginBottom: 0,
      padding: "0 20px",
    },
    "& h3": {
      padding: "10px 20px",
    },
  },
  discoverProducts: {
    backgroundImage: "url(./resources/images/banner3.jpeg)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    minHeight: "350px",
    color: "#fff",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    textShadow: "0 0 20px #000",
  },
  shareUs: {
    fontFamily: "Montserrat",
    textAlign: "center",
    padding: "20px 20px 30px 20px",
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      "& h2": {
        fontSize: "1.2em",
      },
    },
  },
  shareUsTitle: {
    fontFamily: "Montserrat",
    fontSize: "3em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2em",
    },
  },
});

class LandingPage extends Component {
  state = {
    isLocationPickerOpen: false,
    isLoginModalOpen: false,
    isBetaModalOpen: true,
    betaPassword: undefined,
    showUserLoginWelcome: false,
    isCartDrawerOpen: false,
    isTellAFriendOpen: false,
    isContactUsOpen: false,
    proximityMenuAnchorEl: undefined,
    drawerMobileOpen: false,
    isHeadRoomShowing: true,
    open: true,
    srcBackgroundImg: null,
  };

  constructor(props) {
    super(props);
    ReactGA.pageview("LandingPage");
  }

  refMarketing = React.createRef();
  refHero = React.createRef();

  componentDidMount() {
    if (
      this.props.location.postal &&
      this.props.location.latitude &&
      this.props.location.longitude
    ) {
      this.goHomePage();
    }

    //Load background Image
    const imageLoader = new Image();
    imageLoader.src = "./resources/images/tomatoes-on-shelves2.jpg";
    imageLoader.onload = () => {
      this.setState({ srcBackgroundImg: imageLoader.src });
    };
  }

  componentWillMount() {
    if (localStorage.getItem("betaClosed")) {
      this.setState({
        isBetaModalOpen: false,
      });
    }
  }

  handleScrollTo = (elRef) => {
    // Incase the ref supplied isn't ref.current
    const el = elRef.current ? elRef.current : elRef;

    // Scroll the element into view
    el.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  openLocationPickerModal = () => {
    this.setState({ isLocationPickerOpen: true });
  };

  closeLoationPickerModal = () => {
    this.setState({ isLocationPickerOpen: false });
  };

  openLoginPageModal = () => {
    this.setState({ isLoginModalOpen: true });
  };

  closeLoginPageModal = () => {
    this.setState({ isLoginModalOpen: false });
  };

  onLoginSuccessHandler = () => {
    this.setState({
      isLoginModalOpen: false,
      showUserLoginWelcome: true,
    });
    this.goHomePage();
  };

  hideUserLoginWelcome = () => {
    this.setState({
      showUserLoginWelcome: false,
    });
  };

  showProximityMenuOptions = (e) => {
    this.setState({
      proximityMenuAnchorEl: e.currentTarget,
    });
  };

  hideProximityMenuOptions = (e) => {
    this.setState({
      proximityMenuAnchorEl: undefined,
    });
  };

  onLogOut = () => {
    this.props.logout();
    //Clean all cache info
    this.props.setLocation("");
    this.props.setFilteredStores([]);
  };

  onSearchInputChange = (
    e,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    // console.log('onSearchInputChange');

    // const searchTerm = e.target.value;
    //const searchTerm = suggestion.label;
    //const searchCategory = suggestion.category;

    let searchVal = suggestion.label;
    let searchTerm = suggestion.label;
    let searchCategory = suggestion.category;
    let stopPostiton = searchVal.lastIndexOf(" in ");
    if (stopPostiton > 0) {
      searchTerm = searchVal.substring(0, stopPostiton);
      searchCategory = searchVal.substring(stopPostiton + 4);
    }

    if (searchTerm) {
      // console.log(this.props.searchfilters.filteredStores);
      // this.props.searchProduct(searchTerm, this.props.cart, this.props.location.latitude, this.props.location.longitude, this.props.searchfilters.filteredStores);
      this.props.searchProduct(searchTerm, searchCategory);
    } else {
      this.props.clearSearchHistory();
    }
  };

  goHomePage = () => {
    this.props.history.push("home");
  };

  goStorePickerPage = () => {
    this.props.history.push("stores");
  };

  onLocationPick = () => {
    this.setState({
      isLocationPickerOpen: false,
    });
  };

  changeLocation = () => {
    this.setState({
      isLocationPickerOpen: true,
    });
  };

  onFilterProximity = () => {
    this.hideProximityMenuOptions();

    const { postal, latitude, longitude } = this.props.location;
    this.props.setLocationAndUpdateStoresNearby(postal, latitude, longitude);
    // this.props.searchProduct(this.props.products.searchTerm);
  };

  renderSidePanel = () => {
    const { classes, products } = this.props;
    return (
      <div>
        <div className={classes.toolbar}>
          <img
            src="./resources/images/Better-Cart-Logo.png"
            className={classes.logo}
            onClick={this.goHome}
          />
        </div>
      </div>
    );
  };

  renderMarketingText = () => {
    const { classes } = this.props;

    return <div></div>;
  };

  renderMarketingSliderRow = () => {
    const { classes } = this.props;
    const sliderSettings = {
      // lazyLoad: 'ondemand',
      dots: true,
      infinite: true,
      arrows: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      //centerMode: true,
      //autoplay: true,
      //autoplaySpeed: 5000,
      //cssEase: 'linear',
      //variableWidth: true,
      //variableHeight: true
      // You can unslick at a given breakpoint now by adding:
      //settings: "unslick"
      // instead of a settings object
      // zIndex:-1
    };

    // console.log('slider ' + products.length)

    return (
      <div ref={this.refMarketing}>
        <div className={classes.underHero}>
          <h1 className={classes.underHeroTitle}>Save Money</h1>
          <h2>
            We compare the price of food and help you reduce your grocery bill.
          </h2>
        </div>
        <div className={classes.underHero2}>
          <h1 className={classes.underHero2Title}>Gain Time</h1>
          <h2>
            We show you where your groceries are the cheapest, so you can save
            time.
          </h2>
        </div>
        <div className={classes.underHero3}>
          <h1 className={classes.underHero3Title}>Shop Smart</h1>
          <h2>
            We want you to know your local food prices so you never overpay.{" "}
          </h2>
        </div>
      </div>
    );
  };

  renderMap = () => {
    const { classes } = this.props;

    return (
      <div className={classes.map}>
        <Typography variant="h4" className={classes.marketingTitle}>
          We're helping shoppers all across Canada
        </Typography>
        <img
          className={classes.mapImage}
          src="./resources/images/bettercart-map-2.png"
        />
      </div>
    );
  };

  renderStoreLogos = () => {
    const { classes } = this.props;
    return (
      <div>
        <Typography variant="h4" className={classes.marketingTitle}>
          Save money at the following grocery stores
        </Typography>

        <Grid container spacing={0} className={classes.storeLogos}>
          <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
            <img src="./resources/images/store-logos/atlantic-superstore.png" />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
            <img src="./resources/images/store-logos/coop.png" />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
            <img src="./resources/images/store-logos/dominion-newoundland-labrador-grocery.png" />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
            <img src="./resources/images/store-logos/extra-foods.png" />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
            <img src="./resources/images/store-logos/foodland.png" />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
            <img src="./resources/images/store-logos/fortinos.png" />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
            <img src="./resources/images/store-logos/independent-city-market.png" />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
            <img src="./resources/images/store-logos/independent-grocer.png" />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
            <img src="./resources/images/store-logos/loblaws.png" />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
            <img src="./resources/images/store-logos/maxi.png" />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
            <img src="./resources/images/store-logos/nofrills.png" />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
            <img src="./resources/images/store-logos/qualityfoods.png" />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
            <img src="./resources/images/store-logos/real-canadian-superstore.png" />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
            <img src="./resources/images/store-logos/safeway.png" />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
            <img src="./resources/images/store-logos/save-on-foods.png" />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
            <img src="./resources/images/store-logos/sobeys.png" />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
            <img src="./resources/images/store-logos/thriftyfoods.png" />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
            <img src="./resources/images/store-logos/valu-mart.png" />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
            <img src="./resources/images/store-logos/walmart.png" />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
            <img src="./resources/images/store-logos/zehrs.png" />
          </Grid>
        </Grid>
      </div>
    );
  };

  renderDiscoverShare = () => {
    const { classes } = this.props;
    let shareUrl = "https://bettercart.ca";
    let shareQuote =
      "Check out this awesome grocery shopping comparison site I found:";
    let shareImage =
      "./resources/images/better-cart-logo-just-cart-square-200.png";
    let shareSubject = "A great site to save on groceries";
    return (
      <div className={classes.shareUs}>
        <h1 className={classes.shareUsTitle}>Share Us</h1>
        <h2>
          We deliver savings that are worth sharing with family and friends.
        </h2>
        <br />
        <div className={classes.shareButtons}>
          <FacebookShareButton
            url={shareUrl}
            quote={shareQuote}
            className={classes.shareButton}
          >
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>
          <FacebookMessengerShareButton
            //appId='941839156172819'
            url={shareUrl}
            quote={shareQuote}
            className={classes.shareButton}
          >
            <FacebookMessengerIcon size={32} round={true} />
          </FacebookMessengerShareButton>
          <TwitterShareButton
            url={shareUrl}
            title={shareQuote}
            className={classes.shareButton}
          >
            <TwitterIcon size={32} round={true} />
          </TwitterShareButton>
          <WhatsappShareButton
            url={shareUrl}
            title={shareQuote}
            className={classes.shareButton}
          >
            <WhatsappIcon size={32} round={true} />
          </WhatsappShareButton>
          <EmailShareButton
            url={shareUrl}
            subject={shareSubject}
            body={shareQuote}
            media={shareImage}
            image={shareImage}
            className={classes.shareButton}
          >
            <EmailIcon size={32} round={true} />
          </EmailShareButton>
        </div>
      </div>
    );
  };

  renderGetStarted = () => {
    const { classes } = this.props;

    return (
      <div className={classes.getStarted}>
        <Typography variant="h4" className={classes.marketingTitle}>
          Ready to shop?
        </Typography>
        <Button
          className={classes.button}
          onClick={() => {
            this.handleScrollTo(this.refHero);
          }}
          variant="contained"
          color="secondary"
          disableElevation
        >
          <Typography className={classes.startButtonText}>
            Get started
          </Typography>
        </Button>
        <br />
        <br />
      </div>
    );
  };

  renderFooter = () => {
    const { classes } = this.props;
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    var pjson = require("../../../package.json");
    return (
      <div className={classes.footer}>
        <img
          src="./resources/images/Better-Cart-Logo-just-cart-square-500pxwhite-1-300x300.png"
          className={classes.footerLogo}
        />
        <Typography variant="h4" className={classes.footerHeading}>
          Helping you save money every day
        </Typography>
        <Typography className={classes.footerCopyright}>
          © {currentYear} BetterCart&trade;
        </Typography>
        <div className={classes.version}>
          {pjson.version}
        </div>
      </div>
    );
  };

  onPin = () => {
    this.setState({
      isHeadRoomShowing: true,
    });
  };

  onUnpin = () => {
    this.setState({
      isHeadRoomShowing: false,
    });
  };

  handleDrawerToggle = () => {
    this.setState({
      drawerMobileOpen: !this.state.drawerMobileOpen,
    });
  };

  render() {
    // console.log(this.props.location.postal);
    const { classes, location, products } = this.props;
    const isMenuOpen = Boolean(this.state.proximityMenuAnchorEl);
    const shareUrl = "https://bettercart.ca";
    const shareQuote =
      "Check out this awesome grocery shopping comparison site I found: ";
    const shareSubject = "Cool grocery savings site!";
    const shareBody = shareQuote + shareUrl;

    return (
      <div className={classes.root} ref={this.refHero}>
        <Heading page={"landingpage"} />
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-PK5TRM8"
            height="0"
            width="0"
            style="display:none;visibility:hidden"
          ></iframe>
        </noscript>
        <div
          className={classes.hero}
          style={{
            backgroundImage: `url(${this.state.srcBackgroundImg ||
              "./resources/images/tomatoes-on-shelves2-loading.jpg"})`,
          }}
        >
          <AdvertisingBanner postalCode={location.postal} />
          <AppBar
            color="inherit"
            position="static"
            style={{ background: "transparent", boxShadow: "none" }}
          >
            <Toolbar className={classes.homeToolbar}>
              <img
                src="./resources/images/bettercart-logo-white-no-tag.png"
                className={classes.logo}
              />

              <div className={classes.grow} />

              {Helpers.isLoggedIn(this.props.session.user) ? (
                <Button
                  color="secondary"
                  variant="contained"
                  className={classes.brand}
                  onClick={this.onLogOut}
                >
                  <Hidden smDown>
                    <Typography className={classes.buttonText}>
                      {this.props.session.user.firstName}
                    </Typography>
                  </Hidden>
                  <ExitToApp />
                </Button>
              ) : (
                <Button
                  color="secondary"
                  variant="contained"
                  className={classes.loginButton}
                  onClick={this.openLoginPageModal}
                >
                  <Hidden smDown>
                    <Person />
                  </Hidden>
                  <Typography className={classes.buttonText}>
                    Sign Up / Log In{" "}
                  </Typography>
                </Button>
              )}
            </Toolbar>
          </AppBar>
          <div className={classes.welcomeMessage}>
            <Typography variant="h1" className={classes.welcomeMessageText}>
              Welcome to BetterCart
            </Typography>
            <Typography variant="h4" className={classes.welcomeMessageSubText}>
              Where you'll save the most money on your grocery bill
            </Typography>
          </div>

          <LocationPicker
            onLocationPick={this.onLocationPick}
            goNextPage={this.goStorePickerPage}
          />
        </div>

        {//this.renderMarketingText()
        this.renderMarketingSliderRow()}
        {this.renderMap()}
        {this.renderStoreLogos()}
        {this.renderDiscoverShare()}
        {this.renderGetStarted()}
        {this.renderFooter()}

        <LoginPage
          open={this.state.isLoginModalOpen}
          onClose={this.closeLoginPageModal}
          onLoginSuccess={this.onLoginSuccessHandler}
          title="Let's get you saving!"
          subTitle="Sign up or log in below:"
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  cart: state.cart.items,
  location: state.location,
  searchfilters: state.searchfilters,
  session: state.session,
  products: state.products,
});
const mapDispatchToProps = (dispatch, props) => ({
  setLocation: (postal, latitude, longitude) =>
    dispatch(setLocation(postal, latitude, longitude)),
  setFilteredStores: (stores) => {
    dispatch(setFilteredStores(stores));
  },
  setLocationAndUpdateStoresNearby: (postal, latitude, longitude) =>
    dispatch(setLocationAndUpdateStoresNearby(postal, latitude, longitude)),
  logout: () => dispatch(logout()),
  dispatch: (params) => dispatch(params),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(LandingPage)
);
