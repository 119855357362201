import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  //  width: 400,
    backgroundColor: "#f6f6f6",
    border: "2px solid " + theme.palette.primary.main,
    borderRadius: "8px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  
}));

export default function SearchBox({doSearch}) {
  const classes = useStyles();
  const [searchValue, setSearchValue]=useState("");
  const handleChange=(e)=>{
    setSearchValue(e.target.value);
  }
  const keyPressed = ({ key }) => {
    if (key === "Enter") {
      handleClick();
    }
  };
  const handleClick=()=>{
    doSearch(searchValue);
  }
  const submitHandler = (e) => {
    // Prevent form submission on Enter key
    e.preventDefault();
  };
  return (
    <Paper component="form" className={classes.root} onSubmit={submitHandler}>
      
      <InputBase
        className={classes.input}
        placeholder="Search"
        value={searchValue}
        onChange={handleChange}
        onKeyPress={keyPressed}
        inputProps={{ 'aria-label': 'search' }}
      />
      <IconButton onClick={handleClick} className={classes.iconButton} aria-label="search">
        <SearchIcon />
      </IconButton>      
    </Paper>
  );
}