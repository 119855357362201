import React from "react";

import LearnMore from "./help/LearnMore";
import AppBarBackButton from "./help/AppBarBackButton";

export default function LearnMorePage() {
  return (
    <div>
      <AppBarBackButton />
      <LearnMore />
    </div>
  );
}
