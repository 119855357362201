import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LocationOn from '@material-ui/icons/LocationOn';

const styles = theme => ({
  storeLogoContainer: {
    width: '100%',
    // margin: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginBottom: 0
    }

    // opacity: "0.9",
    // padding: theme.spacing(1)
    // ,
    // backgroundColor: '#ccc'
  },
  storeLogo: {
    width: '200px',
    height: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    marginTop: theme.spacing(1)
  },
  storeName: {
    textAlign: 'center'
  },
  locationIcon: {
    fontSize: "0.8rem",
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
});

const StoreLogo = (props) => {
  const { classes, company, store } = props;
  const companyName = company.name;
  // let textToDisplay = companyName;
  let imgLink = company.companyLogoLink;

  if (companyName.indexOf('Independent') > -1) {
    imgLink = `../resources/images/independent-grocer.png`;
  } else if (companyName.indexOf('Loblaws') > -1) {
    imgLink = `../resources/images/loblaws.png`;
  } else if (companyName.indexOf('Superstore') > -1) {
    imgLink = `../resources/images/real-canadian-superstore.png`;
  } else if (companyName.indexOf('Thrifty') > -1) {
    imgLink = `../resources/images/thriftyfoods.gif`;
  } else if (companyName.indexOf('Save') > -1) {
    imgLink = `../resources/images/save-on-foods.png`;
  } else if (companyName.indexOf('Sobeys') > -1) {
    imgLink = `../resources/images/sobeys.png`;
  } else if (companyName.indexOf('Independent') > -1) {
    imgLink = `../resources/images/independent-grocer.png`;
  } else if (companyName.indexOf('Extra') > -1) {
    imgLink = `../resources/images/extra-foods.png`;
  } else if (companyName.indexOf('Walmart') > -1) {
    imgLink = `../resources/images/walmart.gif`;
  } else if (companyName.indexOf('Co-op') > -1) {
    imgLink = `../resources/images/coop.gif`;
  }else if (companyName.indexOf('Quality') > -1) {
    imgLink = `../resources/images/qualityfoods.gif`;
  }else if (companyName.indexOf('Frills') > -1) {
    imgLink = `../resources/images/nofrills.gif`;
  }else if (companyName.indexOf('Foodland') > -1) {
    imgLink = `../resources/images/foodland.png`;
  }else if (companyName.indexOf('Atlantic') > -1) {
    imgLink = `../resources/images/atlantic-superstore.png`;
  }else if (companyName.indexOf('Fortinos') > -1) {
    imgLink = `../resources/images/fortinos.png`;
  }else if (companyName.indexOf('Independent City') > -1) {
    imgLink = `../resources/images/independent-city-market.png`;
  }else if (companyName.indexOf('Maxi') > -1) {
    imgLink = `../resources/images/maxi.png`;
  }else if (companyName.indexOf('Dominion') > -1) {
    imgLink = `../resources/images/dominion-newoundland-labrador-grocery.png`;
  }else if (companyName.indexOf('Valu-Mart') > -1) {
    imgLink = `../resources/images/valu-mart.png`;
  }else if (companyName.indexOf('Zehrs') > -1) {
    imgLink = `../resources/images/zehrs.png`;
  }else if (companyName.indexOf('Safeway') > -1) {
    imgLink = `../resources/images/safeway.png`;
  }


  // if (storeName) {
  //   textToDisplay = storeName;
  // }

  return (
    <div className={ classes.storeLogoContainer }>
      <img className={ classes.storeLogo } src={ imgLink } />
      {
        store && (
          <Typography className={ classes.storeName } variant="subtitle2" gutterBottom={true} noWrap={true}>{ store.name }</Typography>
        )
      }
    </div>
  );
}

export default withStyles(styles)(StoreLogo);
