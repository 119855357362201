const productsReducerDefaultState = {

  // Canada place coordinates
  // postal: 'V6C 3T4',
  // latitude: 49.2888248,
  // longitude: -123.1133096

  postal: undefined,
  latitude: undefined,
  longitude: undefined,
  province: 'SK',
  // proximity: 10, // 10 km default

  isLoadingStoresNearby: false,
  errorOccurredWhileLoadingStoresNearby: false,
  storesNearby: []
};

export default (state = productsReducerDefaultState, action) => {

  switch (action.type) {
    case 'SET_LOCATION' :
      return {
        ...state,
        postal: action.postal,
        latitude: action.latitude,
        longitude: action.longitude,
        province: action.province
      };

    case 'SET_LOADING_STORES_NEARBY':
      return {
        ...state,
        isLoadingStoresNearby: action.isLoadingStoresNearby,
        errorOccurredWhileLoadingStoresNearby: false
      }

    case 'SET_ERROR_STORES_NEARBY':
      return {
        ...state,
        isLoadingStoresNearby: false,
        errorOccurredWhileLoadingStoresNearby: action.errorOccurredWhileLoadingStoresNearby
      }

    case 'SET_STORES_NEARBY':
      return {
        ...state,
        isLoadingStoresNearby: false,
        errorOccurredWhileLoadingStoresNearby: false,
        storesNearby: action.storesNearby
      }

    case 'SET_FILTERED_STORES':
      //const stores = action.filteredStores.map((store) => store._id);
      //localStorage.setItem('stores', stores.join(','));

      return {
        ...state,
        filteredStores: action.filteredStores
      }

    default:
      return state;
  }
};
