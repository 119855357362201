import { v4 as uuidv4 } from 'uuid';
import Cookies from 'universal-cookie';
import axios from "axios";
import envConfig from "../bin/env.config";
export class Helpers {
  
  static isAdmin(user){
    let adminEmails = [
      "namtran@bettercart.ca",
      "nam@bettercart.ca",
      "melanie@bettercart.ca",
      "elise@bettercart.ca",
      "victor@bettercart.ca",
      "stephen@bettercart.ca",
      "todd@bettercart.ca",
      "todd.morrison@usask.ca",
      "jomorr@hotmail.com",
      "murray@bettercart.ca",      
      "JoEllen@bettercart.ca",      
      "joellen@bettercart.ca", 
      "jwacharibasam@gmail.com",     
      "choenden@bettercart.ca",
      "shelley@bettercart.ca",     
      "carys@bettercart.ca",     
      "hannah@bettercart.ca", 
      "haley@bettercart.ca",
      "badhon@wave9.co",         
    ];
    return user && adminEmails.includes(user.email);
  }

  static companyLogo(companyName){
    let imgLink = `/resources/images/logo_missing.png`;
  if (companyName.indexOf('Independent') > -1) {
    imgLink = `/resources/images/independent-grocer.png`;
  } else if (companyName.indexOf('Loblaws') > -1) {
    imgLink = `/resources/images/loblaws.png`;
  } else if (companyName.indexOf('Superstore') > -1) {
    imgLink = `/resources/images/real-canadian-superstore.png`;
  } else if (companyName.indexOf('Thrifty') > -1) {
    imgLink = `/resources/images/thriftyfoods.gif`;
  } else if (companyName.indexOf('Save') > -1) {
    imgLink = `/resources/images/save-on-foods.png`;
  } else if (companyName.indexOf('Safeway by Voila') > -1) {
    imgLink = `/resources/images/safewayvoila.png`;
  } else if (companyName.indexOf('Sobeys by Voila') > -1) {
    imgLink = `/resources/images/sobeyvoila.png`;  
  } else if (companyName.indexOf('Sobeys') > -1) {
    imgLink = `/resources/images/sobeys.png`;
  } else if (companyName.indexOf('Independent') > -1) {
    imgLink = `/resources/images/independent-grocer.png`;
  } else if (companyName.indexOf('Extra') > -1) {
    imgLink = `/resources/images/extra-foods.png`;
  } else if (companyName.indexOf('Walmart') > -1) {
    imgLink = `/resources/images/walmart.gif`;
  } else if (companyName.indexOf('Co-op') > -1||companyName.indexOf('Coop') > -1) {
    imgLink = `/resources/images/coop.gif`;
  }else if (companyName.indexOf('Quality') > -1) {
    imgLink = `/resources/images/qualityfoods.gif`;
  }else if (companyName.indexOf('Frills') > -1) {
    imgLink = `/resources/images/nofrills.gif`;
  }else if (companyName.indexOf('Foodland') > -1) {
    imgLink = `/resources/images/foodland.png`;
  }else if (companyName.indexOf('Atlantic') > -1) {
    imgLink = `/resources/images/atlantic-superstore.png`;
  }else if (companyName.indexOf('Fortinos') > -1) {
    imgLink = `/resources/images/fortinos.png`;
  }else if (companyName.indexOf('Independent City') > -1) {
    imgLink = `/resources/images/independent-city-market.png`;
  }else if (companyName.indexOf('Maxi') > -1) {
    imgLink = `/resources/images/maxi.png`;
  }else if (companyName.indexOf('Dominion') > -1) {
    imgLink = `/resources/images/dominion-newoundland-labrador-grocery.png`;
  }else if (companyName.indexOf('Newfoundland') > -1) {
    imgLink = `/resources/images/dominion-newoundland-labrador-grocery.png`;  
  }else if (companyName.indexOf('Valu-Mart') > -1) {
    imgLink = `/resources/images/valu-mart.png`;
  }else if (companyName.indexOf('Zehrs') > -1) {
    imgLink = `/resources/images/zehrs.png`;
  }else if (companyName.indexOf('Safeway') > -1) {
    imgLink = `/resources/images/safeway.png`;
  }else if (companyName.indexOf('Voila') > -1) {
    imgLink = `/resources/images/voila.png`;
  }else if (companyName.indexOf('Red') > -1) {
    imgLink = `/resources/images/redbarn.png`;
  }else if (companyName.indexOf('Provigo') > -1) {
    imgLink = `/resources/images/provigo.png`;
  }else if (companyName.indexOf('IGA') > -1) {
    imgLink = `/resources/images/iga.png`;
  }else if (companyName.indexOf('Metro') > -1) {
    imgLink = `/resources/images/metro.png`;
  }else if (companyName.indexOf('WholeSaleClub') > -1) {
    imgLink = `/resources/images/wholesaleclub.png`;
  }else if (companyName.indexOf('London Drugs') > -1) {
    imgLink = `/resources/images/londondrugs.png`;
  }
  
    return  imgLink;
  }

  static isLoggedIn (user) {
    return user !== null && user !== undefined;
  }

  static dealText (product) {
    // const { product } = this.props;
    //return ''; 
       
    if (product.priceDisplayText2==='1') {
      product.priceDisplayText2 = '';
    }

    if (product.priceDisplayText2==='' && product.priceUnit!=='') {
      product.priceDisplayText2 = product.priceUnit;
    }

    if (product.priceDisplayText2==='' && product.pricing.saleDescription!=='') {
      product.priceDisplayText2 = product.pricing.saleDescription;
    }
    
    if (product.priceDisplayText2 && product.priceDisplayText2.indexOf('SAVE')>=0){
      product.priceDisplayText2 = '';
    }

        
    return product.priceDisplayText2;
  }
  // pricingText = () => {
  //   const { classes, product } = this.props;
  //   return (
  //     <div>
  //       <span className={classes.productPrice}>
  //         {product.priceDisplayText1 != "Flyer Item"
  //           ? product.priceDisplayText1
  //           : ""}
  //       </span>
  //     </div>
  //   );
  // };
  static pricingText (product) {
    let pricing = '';
    if(product.priceDisplayText1 != "Flyer Item" && product.priceDisplayText1 && product.priceDisplayText1.length>0 ){
      pricing = product.priceDisplayText1;
    }else if (product.pricing.currentPrice>0) {
      pricing = '$' + this.asDecimalText(product.pricing.currentPrice);
    }
    return pricing;    
  }
  
  static isSpecialDiscount (product) {
    // const { product } = this.props;
    //if (!product.pricing) return 0;
    const discountType = product.pricing.discountType
    return discountType === 3 || discountType === 4 || discountType === 5
  }

  static calculateRealPrice (product) {
    const productPricing = product.pricing;
    let price = productPricing.currentPrice;

    // 3 = X for $X discount
    if (productPricing.discountType === 3) {
      price = productPricing.discountAmount / (productPricing.discountCondition ? productPricing.discountCondition : 1);
    }

    // 4 = Buy X get X free
    if (productPricing.discountType === 4) {
      price = productPricing.currentPrice / productPricing.discountAmount + (productPricing.discountCondition ? productPricing.discountCondition : 0);
    }

    // 5 = Bulk Discount ($5 MIN 2)
    if (productPricing.discountType === 5) {
      price = productPricing.currentPrice;
    }

    return price;
  }

  static savePrice(product){
      const productPricing = product.pricing;
      let saveValue =0;
      
      if (product.pricing.realizedPercentDiscount>0){
        if (productPricing.regularPrice > productPricing.currentPrice)
        {
          saveValue = productPricing.regularPrice - productPricing.currentPrice;
          saveValue = this.asDecimalText(saveValue);
          return saveValue>0? saveValue : '';
        } 
        
  
        if (productPricing.currentPrice>0){
          saveValue = productPricing.currentPrice/(1-product.pricing.realizedPercentDiscount/100) - productPricing.currentPrice;
          saveValue = this.asDecimalText(saveValue);
          return saveValue>0? saveValue : '';
        }
  
        
                           
        saveValue = this.calculateRealPrice (product)/(1-product.pricing.realizedPercentDiscount/100) - this.calculateRealPrice (product);    
        saveValue = (productPricing.discountCondition ? productPricing.discountCondition : 1) * saveValue;       
        saveValue = this.asDecimalText(saveValue);                
      }
            
      return saveValue>0? saveValue: '';
  }

  static productSize(product){
    if (product.size.length>1&&product.size[0]==='/'){
      if (product.pricing.currentPrice>0) {
        return  '$' + this.asDecimalText(product.pricing.currentPrice)+product.size;
      }       
    }
    return product.size;
    
  }
  static fullProductNameWithBrand (product){
    if (product.fullName){
      return product.fullName.includes( product.brand ) || product.brand === "unknown" ? product.fullName : Helpers.titleCase(product.brand) + " " + product.fullName ;
    }else{
      return product.fullName;
    }
    
    // return "hellooo";
  }

  static roundTo2Decimal (num) {
    return Math.round(num * Math.pow(10, 2)) / Math.pow(10, 2);
  }

  static asDecimalText (num) {
    return this.roundTo2Decimal(num).toFixed(2);
  }

  static titleCase(str) {
     var splitStr = str.toLowerCase().split(' ');
     for (var i = 0; i < splitStr.length; i++) {
         // You do not need to check if i is larger than splitStr length, as your for does that for you
         // Assign it back to the array
         splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
     }
     // Directly return the joined string
     return splitStr.join(' '); 
  } 
  static updateUserId(){
    const cookies = new Cookies();
    const data = {
      bcuid: cookies.get('bcuid'),
    };   
    let url = `${envConfig.API_ROOT}${
      envConfig.API_VERSION
    }/collect/bcuid`;
    axios.post(url, data);  
  }   
  static getCookies(){
    const cookies = new Cookies();
    const data = {
      bcuid: cookies.get('bcuid'),
      bsuid: cookies.get('bsuid'),
      searchId: cookies.get('searchid'),
    };
   
    // console.log(bcuid);
    // console.log(bsuid);
    if(!data.bsuid){
      data.bcuid = uuidv4();
      cookies.set('bcuid', data.bcuid, { path: '/' }); 
      // store information to server
      
  
      let url = `${envConfig.API_ROOT}${
        envConfig.API_VERSION
      }/collect/bcuid`;
      axios.post(url, data).then((response)=>{
        // console.log(response);
        // data.bsuid = ;
        cookies.set('bsuid', response.data.bsuid, { path: '/' }); 
      });
    }
    return data;    
  }
  static collectSearch(term, category){
    const cookies = new Cookies();
    const cookieData =  Helpers.getCookies(); 
    const postData = {
      cookies: cookieData,
      term: term,
      category: category
    };
    // console.log(postData);
    let url = `${envConfig.API_ROOT}${
      envConfig.API_VERSION
    }/collect/search`;
    axios.post(url, postData).then((response)=>{
      // console.log(response);
      // data.bsuid = ;
      cookies.set('searchid', response.data.id, { path: '/' }); 
    });;
  }
  static resetSearchId(){
    const cookies = new Cookies();
    // console.log('test');
    cookies.set('searchid', 0, { path: '/' }); 
  }
  static collectClick(productId, actionId){
    const cookies =  Helpers.getCookies(); 
    // console.log(cookies);
    const postData = {
      cookies: cookies,
      productId: productId,
      actionId: actionId
    };
    // console.log(postData);
    let url = `${envConfig.API_ROOT}${
      envConfig.API_VERSION
    }/collect/click`;
    axios.post(url, postData);
  }
};
