import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import envConfig from "../../../../bin/env.config";
import store from "../../../../store/store";

import ReviewItem from "./ReviewItem";
import ReviewItemOriginal from "./ReviewItemOriginal";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  rootLeft: {
    position: "-webkit-sticky",
    position: "sticky",
    top: 64,
    backgroundColor: theme.palette.primary.main,
    // paddingTop: "64px",
    // width:"50%"
  },
  rootRight: {
    display: "flex",
  },
  acceptContainer: {
    backgroundColor: "#DDD",
  },
}));

export default function ReviewCoopPanel({ selectedGroup, showAcceptedList}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [product, setProduct] = useState({});
  
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (selectedGroup.id > 0) {
       
        const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/pbs/review-coop`;
        const result = await axios.post(
          url,
          { id: selectedGroup.id },
          {
            headers: {
              Authorization: JSON.stringify(store.getState().session.token),
            },
          }
        );
        console.log (result.data.data);
        setProduct(result.data.data);
      }
      setIsLoading(false);
    };
    selectedGroup && fetchData();
  }, [selectedGroup]);
  // const handleNotAMatch = (productMatch) => {
  //   let temp = { ...product };
  //   //remove from product.matches
  //   temp.matches = temp.matches.filter((p) => p.id != selectedGroup.id);
  //   // temp.potentials.push(productMatch);
  //   setProduct(temp);
  //   //add to product.potentials
  // };
  const handleMatches = (productMatch) => {
    let temp = { ...product };    
    //update: incase group
    let wrongProducts = temp.wrongs.filter((p) => {
      return p.id != productMatch.id; 
    }); 
    temp.wrongs = [...wrongProducts]  
    setProduct(temp);
    //add to product.matches        
  };
 
  return (
    <>
      {isLoading ? (
        <Grid
          className={classes.root}
          container
          spacing={0}
          alignItems="center"
          justify="center"
        >
          <div>Loading ...</div>
        </Grid>
      ) : (
        <>
           {product && product.original && (
            <ReviewItemOriginal
              originalProduct={product.original}
              locked={product.locked}              
            />
          )}
          <Grid
            className={classes.root}
            container
            spacing={0}
            // alignItems="center"
            // justify="center"
          >            
            {!showAcceptedList && product &&
              product.wrongs &&
              product.wrongs.map((item, i) => (
                <ReviewItem
                  key={i}
                  productMatch={item}
                  groupId={selectedGroup.id}
                  showType={"wrong"}
                  handleMatches={handleMatches}
                />
               
              ))}            
           
          </Grid>
        </>
      )}
    </>
  );
}
