import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import StorefrontIcon from "@material-ui/icons/Storefront";

import ProductItem from "../Product/ProductItem";
import ErrorMessage from "../Error/ErrorMessage";
import Slider from "react-slick";
import Skeleton from "react-loading-skeleton";
import StoreLogoOnline from "../StoreLogo/StoreLogoOnline";
import Divider from "@material-ui/core/Divider";
import ProductAutoComplete from "../ProductAutoComplete/ProductAutoComplete";
import { Helpers } from "../../utility/helpers";
import ReactGA from "react-ga";

import ViewAllDialogLoadMore from "../Search/SearchList/ViewAllDialogLoadMore";
import TodaysDealsCategoriesFilter from "./TodaysDealsCategoriesFilter";

import {
  fetchCategories
} from "../../actions/categories";

import Alert from "../Alert/Alert";

const styles = (theme) => ({
  bestDealsWrapper: {},
  slider: {
    // marginLeft: theme.spacing(2) + 'px',
    // marginRight: theme.spacing(2) + 'px',
    marginBottom: theme.spacing(5) + "px",
    padding: 0,
    paddingLeft: "45px",
    paddingRight: "45px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "5px",
      paddingRight: "0px",
      "& .slick-list": {
        //display: 'none !important'
        padding: "0 20% 0 0",
      },
    },
  },
  todaysDealsSkeleton: {
    marginLeft: theme.spacing(4) + "px",
  },
  welcomeMessage: {
    textAlign: "center",
    padding: theme.spacing(8) + "px",
    background: theme.palette.primary.main,
    backgroundImage: "url(./resources/images/cart-background.jpg)",
    backgroundPosition: "center",
  },
  welcomeMessageText: {
    color: "#336699",
    //fontWeight: 'bold',
    textShadow: "0px 0px 35px rgba(0,0,0,0.99)",
    paddingBottom: theme.spacing(8) + "px",
    fontSize: "44px",
    lineHeight: "1.5em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
    },
  },
  sectionTitle: {
    padding: theme.spacing(4) + "px",
    paddingBottom: 0,
    textAlign: "center",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
      paddingBottom: theme.spacing(2) + "px",
    },
  },
  storeLogos: {
    textAlign: "center",
    padding: theme.spacing(4) + "px",
  },
  storeLogo: {
    width: "128px",
    height: "40px",
    margin: theme.spacing(1) + "px",
    opacity: "0.9",
  },
  instructions: {
    background: theme.palette.primary.main,
    textAlign: "center",
    padding: theme.spacing(6) + "px",
    boxShadow: "inset 0 0 30px #022974",
  },
  instructionDetails: {
    color: "#fff",
    textAlign: "center",
    marginBottom: "0.75em",
    fontSize: "1.4em",
  },
  instructionsText: {
    color: "#fff",
    fontWeight: "bold",
    marginBottom: "1.3em",
  },
  storesList: {
    textAlign: "center",
    padding: theme.spacing(4) + "px",
    background: theme.palette.primary.light,
  },

  bestDealsDescription: {
    textAlign: "center",
    marginBottom: "1.5em",
    paddingLeft: "1em",
    paddingRight: "1em",
  },
  divider: {
    height: "20px",
    backgroundColor: "#f6f6f6",
  },
  viewAllContainer: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      marginBottom: 0,
    },
    cursor: "pointer",
    textTransform: "uppercase",
  },
  viewAllStoreName: {
    fontFamily: "Montserrat",
    fontWeight: "600",
    fontSize: "1.125rem",
  },
  viewAllButton: {
    fontFamily: "Montserrat",
    fontWeight: "600",
    fontSize: "1.125rem",
    color: theme.palette.primary.main,
  },
});

class TodaysDealsPage extends Component {
  constructor() {
    super();
    this.state = {
      isViewAllDialogOpen: false,
      viewAllDialogData: {},
      countRender: 0
    };
    this.state.width = window.innerWidth;
  }
  componentDidMount() {

    //console.log(this.props.categories);
    // const productsArray = this.props.todaysDeals.reduce(function(a, b) {
    //   return [...a,...b.products];
    // }, []);
    // // console.log(productsArray);
    // const categoriesArray = productsArray.map((item)=>item.categoryId) ;
    // // console.log(Array.from(new Set(categoriesArray)));
  }
  openViewAllDialog = (companyData) => {
    this.setState({
      isViewAllDialogOpen: true,
      viewAllDialogData: companyData,
    });
  };

  closeViewAllDialog = () => {
    this.setState({ isViewAllDialogOpen: false });
  };
  // showLoadingState = () => {
  //   return (
  //     <Typography variant="h5" color="inherit" align="center">
  //       <CircularProgress />
  //       <br/>
  //       <br/>
  //       Loading today's deals...
  //     </Typography>
  //   );
  // }

  renderHeader = () => {
    const { classes } = this.props;
    return (
      <div className={classes.welcomeMessage}>
        <Typography variant="h4" className={classes.footerHeading}>
          Welcome to BetterCart&trade;
        </Typography>
        <p className={classes.instructionDetails}>
          <b>Ready to get started?</b>{" "}
        </p>
        <p className={classes.instructionDetails}>
          <b>Search</b> for your first product above OR
        </p>
        <p className={classes.instructionDetails}>
          <b>Take</b> a quick peek below at Today's Best Deals
        </p>
      </div>
    );
  };

  renderSearch = () => {
    const { classes } = this.props;
    return (
      <div>
        <ProductAutoComplete onSuggestionSelected={this.onSearchInputChange} />
      </div>
    );
  };

  showLoadingState = () => {
    const { classes } = this.props;
    const sliderSettings = {
      // lazyLoad: 'ondemand',
      dots: true,
      infinite: false,
      arrows: false,
      speed: 500,
      slidesToShow: 4.5,
      slidesToScroll: 4,
      // centerMode: true,
      // autoplay: true,
      // autoplaySpeed: 2000,
      dots: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ],
    };

    // <div className={classes.todaysDealsSkeleton}>
    //           <Skeleton width={200}  />
    //         </div>
    return (
      <div>
        <Slider
          {...sliderSettings}
          className={classes.slider}
          ref={(slider) => (this.slider = slider)}
        >
          {[0, 1, 2, 3, 4, 5].map((id) => {
            return <ProductItem isLoading={true} key={id}></ProductItem>;
          })}
        </Slider>
        <Slider {...sliderSettings} className={classes.slider}>
          {[0, 1, 2, 3, 4, 5].map((id) => {
            return <ProductItem isLoading={true} key={id}></ProductItem>;
          })}
        </Slider>
      </div>
    );
  };

  // renderTodaysDeals = () => {
  //   return (
  //     <Grid container spacing={8}>
  //       {
  //         this.props.todaysDeals.map((item) => (
  //           <Grid
  //             item
  //             xs={12}
  //             sm={6}
  //             md={3}
  //             lg={3}
  //             key={item._id} >
  //             <ProductItem
  //               product={item}
  //               productSelect={ this.props.productSelect }
  //               productView={ this.props.productView }
  //             />
  //           </Grid>
  //         ))
  //       }
  //     </Grid>

  //   )
  // }

  renderSliderRow = (products) => {
    const { classes } = this.props;
    const sliderSettings = {
      // lazyLoad: 'ondemand',
      dots: true,
      infinite: false,
      arrows: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      // centerMode: true,
      // autoplay: true,
      // autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            dots: true,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3.5,
            slidesToScroll: 3,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2.5,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1.5,
            slidesToScroll: 1,
            arrows: false,
            dots: false,
          },
        },
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ],
    };

    // console.log('slider ' + products.length)

    return (
      <div>
        <Slider {...sliderSettings} className={classes.slider}>
          {products.map((product) => {
            return (
              <ProductItem
                product={product}
                productSelect={this.props.productSelect}
                productView={this.props.productView}
                productShare={this.props.productShare}
                productLike={this.props.productLike}
                productAlert={this.props.productAlert}
                key={product._id}
                showAlert={this.props.showAlert}
                openEditProductDialog={this.props.openEditProductDialog}
                isAdmin={this.props.isAdmin}
              />
            );
          })}
        </Slider>
      </div>
    );
  };
  renderViewAllDialog = () => {
    return (
      this.state.isViewAllDialogOpen && (
        <ViewAllDialogLoadMore
          open={this.state.isViewAllDialogOpen}
          onClose={this.closeViewAllDialog}
          companyData={this.state.viewAllDialogData}
          products={this.state.viewAllDialogData.products}
          productShare={this.props.productShare}
          productSelect={this.props.productSelect}
          productView={this.props.productView}
          productLike={this.props.productLike}
          productAlert={this.props.productAlert}
          showAlert={this.props.showAlert}
          openEditProductDialog={this.props.openEditProductDialog}
          isAdmin={this.props.isAdmin}
        />
      )
    );
  };
  renderViewAllButton = (companyData) => {
    const { classes } = this.props;
    return (
      <Grid
        container
        alignItems="center"
        alignContent="center"
        justify="center"
      >
        <Grid item>
          {companyData.products.length > 4 && (
            <div className={classes.viewAllContainer}>
              <span
                className={classes.viewAllStoreName}
                onClick={() => this.openViewAllDialog(companyData)}
              >
                {companyData.products[0]&&companyData.products[0].store.name}
                &nbsp; &#x000B7; &nbsp;
              </span>
              <span
                className={classes.viewAllButton}
                onClick={() => this.openViewAllDialog(companyData)}
                // endIcon={<StorefrontIcon/>}
              >
                VIEW ALL
              </span>
            </div>
          )}
          {companyData.products.length <= 4 && (
            <div className={classes.viewAllContainer}>
              <span className={classes.viewAllStoreName}>
                {companyData.products[0]&&companyData.products[0].store.name}
              </span>
            </div>
          )}
        </Grid>
      </Grid>
    );
  };
  renderCompany = (companyData) => {
    const { classes } = this.props;
    const company = companyData.company;
    let rows = [];
    rows.push(<Divider className={classes.divider} />);
    rows.push(
      <StoreLogoOnline key={company._id} company={company}></StoreLogoOnline>
    );
    rows.push(this.renderViewAllButton(companyData));
    
    rows.push(this.renderSliderRow(companyData.products));
    // }
    return rows;
  };

  renderTodaysDeals = () => {
    let rows = [];

    for (let companyData of this.props.todaysDeals) {
      rows.push(this.renderCompany(companyData));
    }

    return rows;
  };

  renderWelcomeMessage = () => {
    const { classes } = this.props;

    return (
      <div className={classes.welcomeMessage}>
        <Typography className={classes.welcomeMessageText} variant="h1">
          Welcome to Bettercart! We compare grocery prices so you can get the
          best deal on your shopping cart!
        </Typography>
      </div>
    );
  };

  renderInstructions = () => {
    const { classes } = this.props;

    return (
      <div className={classes.instructions}>
        <Typography variant="h4" className={classes.instructionsText}>
          How does BetterCart work? Well, it’s easy!
        </Typography>
        <p className={classes.instructionDetails}>
          <b>Search</b> for the product you want to buy
        </p>
        <p className={classes.instructionDetails}>
          <b>Add</b> it to your shopping cart
        </p>
        <p className={classes.instructionDetails}>
          <b>View</b> your cart
        </p>
        <p className={classes.instructionDetails}>
          <b>Click</b> on "See your price comparisons"
        </p>
        <p className={classes.instructionDetails}>
          <b>Be amazed</b> at the money you’ll save!
        </p>
      </div>
    );
  };

  renderStoreLogos = () => {
    const { classes } = this.props;

    return (
      <div className={classes.storeLogos}>
        <img
          src="./resources/images/real-canadian-superstore.png"
          className={classes.storeLogo}
        />{" "}
        <img
          src="./resources/images/save-on-foods.png"
          className={classes.storeLogo}
        />{" "}
        <img
          src="./resources/images/sobeys.png"
          className={classes.storeLogo}
        />{" "}
        <img
          src="./resources/images/independent-grocer.png"
          className={classes.storeLogo}
        />{" "}
        <img
          src="./resources/images/extra-foods.png"
          className={classes.storeLogo}
        />{" "}
        <img
          src="./resources/images/walmart.gif"
          className={classes.storeLogo}
        />{" "}
        <img
          src="./resources/images/thriftyfoods.gif"
          className={classes.storeLogo}
        />{" "}
        <img src="./resources/images/coop.gif" className={classes.storeLogo} />{" "}
        <img
          src="./resources/images/qualityfoods.gif"
          className={classes.storeLogo}
        />{" "}
        <img
          src="./resources/images/nofrills.gif"
          className={classes.storeLogo}
        />{" "}
        <img
          src="./resources/images/foodland.png"
          className={classes.storeLogo}
        />{" "}
        <img
          src="./resources/images/atlantic-superstore.png"
          className={classes.storeLogo}
        />{" "}
        <img
          src="./resources/images/fortinos.png"
          className={classes.storeLogo}
        />{" "}
        <img
          src="./resources/images/independent-city-market.png"
          className={classes.storeLogo}
        />{" "}
        <img src="./resources/images/maxi.png" className={classes.storeLogo} />{" "}
        <img
          src="./resources/images/dominion-newoundland-labrador-grocery.png"
          className={classes.storeLogo}
        />{" "}
        <img
          src="./resources/images/valu-mart.png"
          className={classes.storeLogo}
        />{" "}
        <img src="./resources/images/zehrs.png" className={classes.storeLogo} />{" "}
        <img
          src="./resources/images/safeway.png"
          className={classes.storeLogo}
        />
      </div>
    );
  };

  render() {
    const { classes } = this.props;
   
    return (
      <div className={"homepage"}>
        {this.props.isLoadingTodaysDeals ? (
          this.showLoadingState()
        ) : this.props.errorOccurredTodaysDeals ||
          this.props.errorOccurredWhileLoadingStoresNearby ? (
          <ErrorMessage></ErrorMessage>
        ) : (
          <div className={classes.bestDealsWrapper}>
            {
              //this.renderHeader()
              //this.renderWelcomeMessage()
            }
            {
              //this.renderSearch()
              //this.renderStoreLogos()
            }
            {
              //this.renderInstructions()
            }
            <Typography
              variant="h4"
              className={classes.sectionTitle}
              gutterBottom={false}
            >
              Best deals of the week at your stores:
            </Typography>
            <Typography
              color="secondary"
              className={classes.bestDealsDescription}
            >
              We've crunched the numbers on thousands of products to find the
              biggest discounts at each store near you.
            </Typography>
            {/* <TodaysDealsCategoriesFilter/> */}
            {this.renderTodaysDeals()}
            {this.renderViewAllDialog()}
          </div>
        )}
        <Alert ref={this.alertPopup} />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  errorOccurredWhileLoadingStoresNearby:
    state.location.errorOccurredWhileLoadingStoresNearby,
  errorOccurredTodaysDeals: state.products.errorOccurredTodaysDeals,
  isLoadingTodaysDeals: state.products.isLoadingTodaysDeals,
  todaysDeals: state.products.todaysDeals,
  categories: state.categories,
});
const mapDispatchToProps = (dispatch, props) => ({
  fetchCategories: () => {
    dispatch(fetchCategories());
  },
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(TodaysDealsPage)
);
