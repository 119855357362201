
let user = null;
let token = null

if (localStorage.getItem('user') && localStorage.getItem('token')) {
  try {
    const parsedUser = JSON.parse(localStorage.getItem('user'));
    user = parsedUser;
    token = localStorage.getItem('token');
    // this.props.dispatch(setAuthToken({
    //   user: user,
    //   token: localStorage.getItem('token')
    // }));
  } catch (e) {

  }
}

const sessionDefaultState = {
  showLoginLoader: false,
  loginError: false,

  showFacebookLoginLoader: false,
  facebookLoginError: false,

  showSignUpLoader: false,
  signUpError: false,

  user: user,
  token: token,
  
  hideInstruction: false
};

export default (state = sessionDefaultState, action) => {
  switch (action.type) {
    case 'SET_LOG_IN_LOADER' :
      return {
        ...state,
        showLoginLoader: action.showLoginLoader
      };
    case 'SET_LOG_IN_ERROR' :
      return {
        ...state,
        loginError: action.loginError
      };

    case 'SET_FACEBOOK_LOG_IN_LOADER' :
      return {
        ...state,
        showFacebookLoginLoader: action.showFacebookLoginLoader
      };
    case 'SET_FACEBOOK_LOG_IN_ERROR' :
      return {
        ...state,
        facebookLoginError: action.facebookLoginError
      };

    case 'SET_LOG_IN_USER' :
      return {
        ...state,
        user: action.user
      };
    case 'SET_AUTH_TOKEN' :
      return {
        ...state,
        token: action.token
      }
    case 'SET_SIGN_UP_LOADER' :
      return {
        ...state,
        showSignUpLoader: action.showSignUpLoader
      }
    case 'SET_SIGN_UP_ERROR' :
      return {
        ...state,
        signUpError: action.signUpError
      }
    case 'LOGOUT' :
      //localStorage.removeItem('postal');
      //localStorage.removeItem('latitude');
      //localStorage.removeItem('longitude');
      localStorage.removeItem('stores');
      localStorage.removeItem('pricecomparison');
      localStorage.removeItem('intro');
      localStorage.removeItem('cart');
      return {
        ...state,
        user: null,
        token: null
      }
    case 'SET_HIDE_INSTRUCTION' :
      //localStorage.setItem('intro', action.hideInstruction);
      return {
          ...state,
          hideInstruction: action.hideInstruction
      };
        
    default:
      return state;
  }
};
