import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
// import AddIcon from '@material-ui/icons/Add';
import AddShoppingCart from '@material-ui/icons/AddShoppingCart';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Snackbar from '@material-ui/core/Snackbar';
 
import { fetchUserProfile, removeSavedCart } from '../../actions/session';
import { startAddProductToCart } from '../../actions/cart';

import ErrorMessage from '../Error/ErrorMessage';
import Skeleton from 'react-loading-skeleton';

import ReactGA from 'react-ga';

import Heading from "../Heading/Heading";


const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  },
  title: {
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  progressSpinner: {
    marginTop: theme.spacing(2)
  },
  singleSavedList: {
    marginBottom: theme.spacing(2),
    '&:hover': {
      opacity: '0.4'
    }
  }
});

class FavoriteProducts extends Component {

  constructor (props) {
    super(props);
    this.state = {
      isProductAddSuccessShowing: false
    }
    ReactGA.pageview("SavedLists");    
  }

  componentDidMount () {
    this.props.fetchUserProfile();
  }

  showProducAddToCartSuccess = () => {
    this.setState({
      isProductAddSuccessShowing: true
    });
  }

  hideProductAddToCartSuccess = () => {
    this.setState({
      isProductAddSuccessShowing: false
    });
  }

  onBack = () => {
    this.props.history.push('/home');
  }

  onAddProductToCart = (cart) => {

    for (let product of cart.products) {
      this.props.startAddProductToCart(product);      
    }

    // this.showProducAddToCartSuccess();
    this.props.history.push("/pricecompare");
  }

  onRemoveProductFromFavorites = (cart) => {
     this.props.removeSavedCart(cart.name);
  }

  renderLoadedState = () => {
    const { classes, session } = this.props;
    return (
      <div>
        {
          session.user.savedCarts.length === 0 && (
            <div>
              <br />
              <Typography align="center" variant="h5" color="primary" className={ classes.title }>
                No shopping lists to show
              </Typography>
              <Typography align="center" variant="h6" color="primary" className={ classes.title }>
                You can save lists of your regular grocery items to make weekly shopping a breeze!
              </Typography>            
            </div>
          )
        }

        {
          session.user.savedCarts.length > 0 && (
            <div>
              <Typography variant="h5" align="center" className={ classes.title }>
                Click on a saved list and have BetterCart compare the prices for you.
              </Typography>
              <br />
                <List> 
                  {
                    session.user.savedCarts.map((cart) => {
                      return (
                      <Paper className={ classes.singleSavedList } >
                        <ListItem key={ cart.name }>
                          <ListItemText
                            primary={ cart.name }
                            onClick={ () => { this.onAddProductToCart(cart) } }
                          >
                          </ListItemText>

                          <ListItemSecondaryAction>
                            <IconButton
                              onClick={ () => { this.onAddProductToCart(cart) } }
                            >
                              <AddShoppingCart/>
                            </IconButton>
                            <IconButton
                              onClick={ () => { this.onRemoveProductFromFavorites(cart) } }
                              >
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>

                        </ListItem>
                      </Paper>                   
                      )
                    })
                  }
                </List> 
            </div>
          )
        }

      </div>
    );
  }

  render () {
    const { classes, user, session } = this.props;

    return (
      <div>
        <Heading page={"savedcarts"}/>      
        <AppBar position="sticky" color="inherit">
          <Toolbar>
            <IconButton color="inherit" onClick={this.onBack} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="title" color="inherit">
              Your saved lists
            </Typography>
          </Toolbar>
        </AppBar>

        <Grid container justify="center" className={ classes.root }>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            {
              user.removeSavedCartLoader && (
                <Typography align="center" className={ classes.progressSpinner }>
                  <CircularProgress />
                </Typography>
              )
            }
            
            {
              (!user.removeSavedCartLoader && !user.removeSavedCaartError) && this.renderLoadedState()
            }

            {
              user.removeSavedCaartError && <ErrorMessage />
            }
          </Grid>
        </Grid>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.isProductAddSuccessShowing}
          autoHideDuration={3000}
          onClose={this.hideProductAddToCartSuccess}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">Product has been added to your list</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.hideProductAddToCartSuccess}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </div>
    )

  }
}

const mapStateToProps = (state, props) => ({
  session: state.session,
  user: state.user,
  cart: state.cart
});

const mapDispatchToProps = (dispatch, props) => ({
  fetchUserProfile: () => dispatch(fetchUserProfile()),
  startAddProductToCart: (product) => dispatch(startAddProductToCart(product)),
  removeSavedCart: (product) => dispatch(removeSavedCart(product))
});

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(FavoriteProducts)));



