import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";

import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import NearMe from "@material-ui/icons/NearMe";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import LocationOn from "@material-ui/icons/PinDrop";
import Favorite from "@material-ui/icons/Favorite";
import Store from "@material-ui/icons/Store";
import CloseIcon from "@material-ui/icons/Close";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Person from "@material-ui/icons/Person";
import ExitToApp from "@material-ui/icons/ExitToApp";
import Share from "@material-ui/icons/Share";
import ContactSupport from "@material-ui/icons/ContactSupport";
import Email from "@material-ui/icons/Email";
import HelpOutline from "@material-ui/icons/HelpOutline";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import ListIcon from "@material-ui/icons/List";
import ShoppingCartOutlined from "@material-ui/icons/ShoppingCartOutlined";
import Fab from "@material-ui/core/Fab";

import Snackbar from "@material-ui/core/Snackbar";

import SearchPage from "../Search/SearchPage/SearchPage";
import LocationPickerPage from "../LocationPicker/LocationPickerPage";
import StorePickerComponent from "../StorePicker/StorePickerComponent";
import LoginPage from "../Login/LoginPage";
import ProductAutoComplete from "../ProductAutoComplete/ProductAutoComplete";
import ProductSearchBox from "../ProductAutoComplete/ProductSearchBox";

import AdvertisingBanner from "../WhatInStock/AdvertisingBanner";
import AdminBanner from "../ProductMatching/AdminBanner";
import Heading from "../Heading/Heading";
import Introduction from "./Introduction";
import ReactGA from "react-ga";


// const Introduction = React.lazy(() => import('./Introduction'));

import {
  setLocation,
  setLocationAndUpdateStoresNearby,
  getStoresNearby,
  setLocationAndGetStoresNearby,
} from "../../actions/location";
import { setProximity, setFilteredStores } from "../../actions/searchfilters";
import { fetchCategories } from "../../actions/categories";

import {
  fetchFeaturedProducts,
  searchProduct,
  clearSearchHistory,
  fetchStoreFeaturedProducts,
} from "../../actions/products";
import { loadSavedCart } from "../../actions/cart";
import { logout, setHideInstruction } from "../../actions/session";
import { Helpers } from "../../utility/helpers";
import CartButton from "../CartButton/CartButton";


import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  ViberShareButton,
  EmailShareButton,
  FacebookMessengerShareButton,
} from "react-share";

import {
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
  ViberIcon,
  EmailIcon,
  FacebookMessengerIcon,
} from "react-share";

let drawerWidth = 0;

const styles = (theme) => ({
  root: {
    display: "100%",
  },

  appBar: {
    [theme.breakpoints.up("md")]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
    },
    [theme.breakpoints.up("down")]: {
      width: `100&`,
    },
    boxShadow: "0 4px 3px -3px rgba(0,0,0,0.12)",
  },
  buttonAppBar: {
    [theme.breakpoints.up("md")]: {
      paddingRight: "10px",
      paddingLeft: "10px",
    },
  },
  homeToolbar: {
    paddingLeft: "13px",
    paddingBottom: "5px",
    paddingRight: "14px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "5px",
      paddingRight: "5px",
    },
  },
  menuToolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: "10px",
    justifyContent: "space-between",
    background: "#fff",
    minHeight: "55px",
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "5px",
      marginRight: "5px",
    },
  },

  shoppingListText: {
    fontFamily: "Montserrat",
    fontWeight: "600",
    paddingLeft: "5px",
    fontSize: "1.2rem",
  },

  shareDialogText: {
    textAlign: "center",
  },

  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    border: "1px solid #eee",
    // backgroundColor: fade(theme.palette.common.white, 0.15),
    // '&:hover': {
    //   backgroundColor: fade(theme.palette.common.white, 0.25),
    // },
    // backgroundColor: fade(theme.palette.common.black, 0.15),
    // '&:hover': {
    //   backgroundColor: fade(theme.palette.common.black, 0.25),
    // },
    marginLeft: 0,
    marginRight: "5px",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(3),
      //marginRight: theme.spacing(2),
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: "0",
    },
  },
  enterPostalInstructions: {
    color: "#000",
  },

  postalPopup: {
    textAlign: "center",
  },

  popupLogo: {
    width: "300px",
    margin: "20px auto",
    maxWidth: "100%",
  },

  loginButton: {
    padding: "1px 2px",
    margin: "8px 5px 2px 5px",
    width: "230px",
    alignItems: "center",
    height: theme.spacing(5),
    fontSize: "13px",
    [theme.breakpoints.down("sm")]: {
      height: theme.spacing(6),
      flexDirection: "column",
      margin: "3px 2px 2px 2px",
      minWidth: "48px",
      flex: "auto",
    },
  },

  logo: {
    height: "42.5 px",
    width: "220px",
    margin: theme.spacing(1),
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      height: "18 px",
      width: "55px",
    },
  },
  iconButtonText: {
    fontSize: "large",
    [theme.breakpoints.down("sm")]: {
      fontSize: "medium",
    },
  },

  toolbarButton: {
    padding: "1px 2px",
    margin: "8px 5px 2px 5px",
    display: "flex",
    flex: "1",
    width: "100%",
    alignItems: "center",
    height: theme.spacing(5),
    fontSize: "13px",
    [theme.breakpoints.down("sm")]: {
      height: theme.spacing(6),
      flexDirection: "column",
      margin: "3px 1px 2px 1px",
      minWidth: "50px",
      flex: "auto",
    },
  },
  buttonText: {
    color: "#000",
    fontWeight: "bold",
    fontSize: "14px",
    paddingLeft: "5px",
    fontFamily: "Montserrat",
    [theme.breakpoints.down("sm")]: {
      fontSize: "9px",
    },
  },
  loginButtonText: {
    color: "#fff",
    fontWeight: "bold",
    fontSize: "14px",
    paddingLeft: "5px",
    fontFamily: "Montserrat",
    [theme.breakpoints.down("sm")]: {
      fontSize: "9px",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },

  content: {
    [theme.breakpoints.up("md")]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  locationList: {
    paddingTop: "0",
  },
  shareButton: {
    display: "inline-block",
    marginRight: "10px",
    "&:hover": {
      opacity: "0.5",
    },
  },

  badge: {
    top: "12px",
    left: "-25px",
    right: "auto",
    height: "32px",
    width: "32px",
    borderRadius: "50%",
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.down("sm")]: {
      left: "-20px",
    },
  },
  fab: {
    top: "auto",
    // right: 20,
    left: "auto",
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    // ,
    // height: '80px'
  },
  shoppingCartButton: {
    height: theme.spacing(5.5),
    width: "230px",
    fontSize: "13px",
    marginLeft: "5px",
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down("sm")]: {
      width: "55px",
      height: theme.spacing(6),
      display: "flex",
      flexDirection: "column",
    },
  },
  shareTitle: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "28px",
    fontFamily: "Montserrat",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  shareSubTitle: {
    color: theme.palette.secondary.main,
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "1.1rem",
    fontFamily: "Montserrat",
    display: "block",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1rem",
    },
  },
  shareHeart: {
    marginTop: "-10px",
    marginBottom: "10px",
    size: "1.8rem",
  },

  footer: {
    background: theme.palette.secondary.main,
    //background: '#12C50D',
    color: "#fff",
    padding: theme.spacing(4) + "px",
    textAlign: "center",
  },
  footerHeading: {
    color: "#fff",
    fontWeight: "bold",
  },
  footerCopyright: {
    color: "#fff",
    marginTop: theme.spacing(4) + "px",
  },
  footerLogo: {
    width: "150px",
  },
  version:{
    width:'100%',
    textAlign:'right',
    color:'#008931'
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class HomePage extends Component {
  state = {
    isLocationPickerOpen: false,
    isLoginModalOpen: false,
    LoginTitle: "Let's get you saving!",
    LoginSubTitle: "Sign up or log in below:",
    isBetaModalOpen: true,
    betaPassword: undefined,
    showUserLoginWelcome: false,
    isCartDrawerOpen: false,
    isTellAFriendOpen: false,
    isContactUsOpen: false,
    proximityMenuAnchorEl: undefined,
    drawerMobileOpen: false,
    isHeadRoomShowing: true,
    isIntroductionShowing: 1,
    location: undefined,
    stores: undefined,
    selectedStores: [],
    searchKeyword: "",
  };

  constructor(props) {
    super(props);
    ReactGA.pageview("TodaysDeals");
    // console.log(this.props.session);
    this.state.selectedStores = this.props.searchfilters.filteredStores;
  }
  refTop = React.createRef();

  handleScrollTo = (elRef) => {
    // Incase the ref supplied isn't ref.current
    const el = elRef.current ? elRef.current : elRef;

    // Scroll the element into view
    el.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  componentDidMount() {
    this.props.fetchCategories();
    const { postal } = this.props.location;
    const { filteredStores } = this.props.searchfilters;
    if (!postal) {
      this.props.history.push("/");
    } else {
      //console.log(this.props);
      if (!(Array.isArray(filteredStores) && filteredStores.length)) {
        this.props.history.push("/stores");
      }
    }
    this.handleScrollTo(this.refTop);
  }

  componentWillMount() {
    if (localStorage.getItem("betaClosed")) {
      this.setState({
        isBetaModalOpen: false,
      });
    }
  }

  openLocationPickerModal = () => {
    this.setState({ isLocationPickerOpen: true });
  };

  closeLocationPickerModal = () => {
    this.setState({ isLocationPickerOpen: false });
  };

  goHome = () => {
    this.props.clearSearchHistory();
  };

  // openStorePickerModal = () => {
  //   this.setState({ isStorePickerOpen: true });
  // }

  // closeStorePickerModal = () => {
  //   this.setState({ isStorePickerOpen: false });
  // }

  openLoginPageModal = () => {
    this.setState({ isLoginModalOpen: true });
  };

  closeLoginPageModal = () => {
    this.setState({
      isLoginModalOpen: false,
    });
  };

  onLoginSuccessHandler = () => {
    this.setState({
      isLoginModalOpen: false,
      showUserLoginWelcome: true,
    });
  };

  hideUserLoginWelcome = () => {
    this.setState({
      showUserLoginWelcome: false,
    });
  };

  showProximityMenuOptions = (e) => {
    this.setState({
      proximityMenuAnchorEl: e.currentTarget,
    });
  };

  hideProximityMenuOptions = (e) => {
    this.setState({
      proximityMenuAnchorEl: undefined,
    });
  };

  onLogOut = () => {
    this.props.logout();
    //Clean all cache info

    this.props.setLocation("");
    this.props.setFilteredStores([]);

    this.props.history.push("/");
  };

  onSuggestionValueChanged = (value) => {
    // console.log("Home page:onSuggestionValueChanged ")
    this.setState({
      searchKeyword: value,
    });
  };

  onSearchClick = () => {
    const { searchTerm } = this.props;
    const { searchKeyword } = this.state;
    // console.log(this.props);
    if (searchTerm) {
    } else {
      this.props.searchProduct(searchKeyword);
    }
  };
  onSearchInputChange = (
    e,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    // console.log('onSearchInputChange');
    let searchVal = suggestion.label;
    let searchTerm = suggestion.label;
    let searchCategory = suggestion.category;
    let stopPostiton = searchVal.lastIndexOf(" in ");
    if (stopPostiton > 0) {
      searchTerm = searchVal.substring(0, stopPostiton);
      searchCategory = searchVal.substring(stopPostiton + 4);
    }

    if (searchTerm) {
      console.log(searchCategory)
      Helpers.collectSearch(searchTerm, searchCategory);
      this.props.searchProduct(searchTerm, searchCategory);
    } else {
      this.props.clearSearchHistory();
    }
  };

  onLocationPick = () => {
    this.setState({
      isLocationPickerOpen: false,
    });
  };

  changeLocation = () => {
    this.setState({
      isLocationPickerOpen: true,
    });
  };

  onFilterStores = (stores) => {
    if (this.props.products.searchTerm) {
      //console.log(this.props.products.searchTerm);
      this.props.searchProduct(
        this.props.products.searchTerm,
        this.props.products.searchCategory
      );
    } else {
      this.props.fetchFeaturedProducts(
        [],
        this.props.location.latitude,
        this.props.location.longitude
      );
    }
  };

  onFilterProximity = () => {
    this.hideProximityMenuOptions();

    const { postal, latitude, longitude } = this.props.location;
    this.props.setLocationAndUpdateStoresNearby(postal, latitude, longitude);
    // this.props.searchProduct(this.props.products.searchTerm);
  };

  onShowFavoriteProducts = () => {
    this.props.history.push("products/favorites");
  };

  onClickLocationPicker = () => {
    this.props.setLocation("");
    this.props.history.push("/");
  };

  onClickStorePicker = () => {
    this.props.history.push("stores");
  };

  onShowSavedCarts = () => {
    this.props.history.push("carts");
  };

  onTellAFriend = () => {
    // console.log('----');
    // console.log(this.state.isTellAFriendOpen);
    this.setState({ isTellAFriendOpen: !this.state.isTellAFriendOpen });
    if (this.state.isTellAFriendOpen) {
      ReactGA.pageview("TellAFriend");
    }
  };

  onContactUs = () => {
    this.setState({ isContactUsOpen: !this.state.isContactUsOpen });
    if (this.state.isContactUsOpen) {
      ReactGA.pageview("ContactUs");
    }
  };

  onShowHelp = () => {
    // alert('test');
    this.props.history.push("help");
  };

  onClickShoppingList = () => {
    this.props.history.push("cart");
  };

  renderSidePanel = () => {
    const { classes, products } = this.props;
    return (
      <div>
        <div className={classes.toolbar}>
          <img
            src="./resources/images/Better-Cart-Logo.png"
            className={classes.logo}
            onClick={this.goHome}
          />
        </div>
        <Divider />

        <List className={classes.locationList}>
          {Helpers.isLoggedIn(this.props.session.user) ? (
            <ListItem>
              <Typography variant="h6">
                Hi {this.props.session.user.firstName} 👋
              </Typography>
            </ListItem>
          ) : (
            <ListItem button onClick={this.openLoginPageModal}>
              <ListItemIcon>
                <Person />
              </ListItemIcon>
              <ListItemText primary="Log in / Sign up"></ListItemText>
            </ListItem>
          )}
          <Divider />

          <Hidden smDown>
            <ListItem
              button
              onClick={this.changeLocation}
              disabled={products.isLoadingTodaysDeals}
            >
              <ListItemIcon>
                <LocationOn />
              </ListItemIcon>
              <ListItemText
                primary={this.props.location && this.props.location.postal}
                secondary="Change location"
              ></ListItemText>
            </ListItem>

            <Divider />

            <ListItem
              button
              onClick={this.showProximityMenuOptions}
              disabled={products.isLoadingTodaysDeals}
            >
              <ListItemIcon>
                <NearMe />
              </ListItemIcon>
              <ListItemText
                primary={
                  this.props.location &&
                  this.props.searchfilters.proximity + " km"
                }
                secondary="Change proximity"
              ></ListItemText>
            </ListItem>
          </Hidden>

          {!products.isLoadingTodaysDeals && (
            <div>
              <Divider />
              {Helpers.isLoggedIn(this.props.session.user) && (
                <div>
                  <ListItem button onClick={this.onShowFavoriteProducts}>
                    <ListItemIcon>
                      <Favorite />
                    </ListItemIcon>
                    <ListItemText primary="Favorite Products"></ListItemText>
                  </ListItem>

                  <ListItem button onClick={this.onShowSavedCarts}>
                    <ListItemIcon>
                      <ShoppingCart />
                    </ListItemIcon>
                    <ListItemText primary="Saved Lists"></ListItemText>
                  </ListItem>

                  <ListItem button onClick={this.onLogOut}>
                    <ListItemIcon>
                      <ExitToApp />
                    </ListItemIcon>
                    <ListItemText primary="Log out"></ListItemText>
                  </ListItem>

                  <Divider />
                </div>
              )}
            </div>
          )}
          <Divider />

          <ListItem button onClick={this.onTellAFriend}>
            <ListItemIcon>
              <Share />
            </ListItemIcon>
            <ListItemText primary="Tell a Friend"></ListItemText>
          </ListItem>
          <ListItem button onClick={this.onShowHelp}>
            <ListItemIcon>
              <HelpOutline />
            </ListItemIcon>
            <ListItemText primary="Help"></ListItemText>
          </ListItem>
          <ListItem button onClick={this.onContactUs}>
            <ListItemIcon>
              <ContactSupport />
            </ListItemIcon>
            <ListItemText primary="Contact us"></ListItemText>
          </ListItem>
          <Divider />
          <ListItem>
            <StorePickerComponent
              stores={this.props.location.storesNearby}
              selectedStores={this.onFilterStores}
            />
          </ListItem>
        </List>
        <Divider />
      </div>
    );
  };

  renderBody = () => {
    return <SearchPage />;
  };

  renderFooter = () => {
    const { classes } = this.props;
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    var pjson = require("../../../package.json");
    // const cookies =Helpers.getCookies();
    // console.log(cookies);
    return (
      <div className={classes.footer}>
        <img
          src="./resources/images/Better-Cart-Logo-just-cart-square-500pxwhite-1-300x300.png"
          className={classes.footerLogo}
        />
        <Typography variant="h4" className={classes.footerHeading}>
          Helping you save money every day
        </Typography>
        <Typography className={classes.footerCopyright}>
          © {currentYear} BetterCart&trade; 
        </Typography>
        <div className={classes.version}>
          {pjson.version}
        </div>
      </div>
    );
  };

  onPin = () => {
    this.setState({
      isHeadRoomShowing: true,
    });
  };

  onUnpin = () => {
    this.setState({
      isHeadRoomShowing: false,
    });
  };

  handleDrawerToggle = () => {
    this.setState({
      drawerMobileOpen: !this.state.drawerMobileOpen,
    });
  };

  shoppingListButton = () => {
    const { cart, classes, fixedFab } = this.props;
    return (
      <Fab
        color="primary"
        className={fixedFab ? classes.fab : classes.shoppingCartButton}
        aria-label="cart"
        size="large"
        variant="extended"
        onClick={this.onClickShoppingList}
      >
        {cart.length > 0 ? (
          <Badge
            badgeContent={cart.length}
            color="error"
            classes={{ badge: classes.badge }}
          >
            <ShoppingCart className={classes.shopppingCartWithBadge} />
          </Badge>
        ) : (
          <ShoppingCartOutlined onClick={this.onClickShoppingList} />
        )}
        <Hidden smDown>
          <Typography className={classes.shoppingListText}>
            &nbsp;My cart
          </Typography>
        </Hidden>
      </Fab>
    );
  };

  render() {
    const { classes, location, products, cart } = this.props;
    const selectedStores = this.state.selectedStores;
    const isMenuOpen = Boolean(this.state.proximityMenuAnchorEl);
    const shareUrl = "https://bettercart.ca";
    const shareQuote =
      "Check out this awesome grocery shopping comparison site I found: ";
    const shareSubject = "Amazing grocery savings site!";
    const shareBody = shareQuote + shareUrl;

    let likedProduct = 0;
    let savedList = 0;
    if (Helpers.isLoggedIn(this.props.session.user)) {
      likedProduct = this.props.session.user.favoriteProducts.length;
      savedList = this.props.session.user.savedCarts.length;
    }

    let isLoginModalOpen = this.state.isLoginModalOpen;
    // let LoginTitle = this.state.LoginTitle;
    // let LoginSubTitle = this.state.LoginSubTitle;
    let loginMessageNumber = 0;

    let isAdmin = Helpers.isAdmin(this.props.session.user);
    return (
      <div className={classes.root} ref={this.refTop}>
        <Heading page={"home"} />
        {isAdmin?<AdminBanner/>:<AdvertisingBanner postalCode={location.postal} />}
        <AppBar
          color="transparent"
          position="relative"
          className={(classes.appBar, classes.buttonAppBar)}
        >
          <Toolbar
            className={(classes.menuToolbar, "mainToolbar")}
            disableGutters={true}
          >
            <Button
              size="small"
              variant="outlined"
              onClick={this.onClickLocationPicker}
              disabled={products.isLoadingTodaysDeals}
              className={classes.toolbarButton}
            >
              <LocationOn />
              <Typography className={classes.buttonText}>
                {location && location.postal ? location.postal : ""}
              </Typography>
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={this.onClickStorePicker}
              disabled={products.isLoadingTodaysDeals}
              className={classes.toolbarButton}
            >
              <Store />
              <Typography className={classes.buttonText}>
                <Hidden smDown>{selectedStores.length}</Hidden> Stores
              </Typography>
            </Button>
            {Helpers.isLoggedIn(this.props.session.user) && (
              <Button
                size="small"
                variant="outlined"
                disabled={products.isLoadingTodaysDeals}
                className={classes.toolbarButton}
                onClick={this.onShowFavoriteProducts}
              >
                {/* <Favorite color='#ff0000'/> */}
                <Favorite />
                <Typography className={classes.buttonText}>
                  <Hidden smDown>{likedProduct ? likedProduct : ""} </Hidden>
                  Liked
                </Typography>
              </Button>
            )}
            {Helpers.isLoggedIn(this.props.session.user) && (
              <Button
                size="small"
                variant="outlined"
                disabled={products.isLoadingTodaysDeals}
                className={classes.toolbarButton}
                onClick={this.onShowSavedCarts}
              >
                <ListIcon />
                <Typography className={classes.buttonText}>
                  <Hidden smDown>{savedList ? savedList : ""} Saved</Hidden>{" "}
                  Lists
                </Typography>
              </Button>
            )}
            <Hidden smUp>
              <Button
                size="small"
                variant="outlined"
                disabled={products.isLoadingTodaysDeals}
                className={classes.toolbarButton}
              >
                <MoreHoriz />
                <Typography
                  className={classes.buttonText}
                  //onClick={ this.onTellAFriend }
                >
                  More
                </Typography>
              </Button>
            </Hidden>
            <Hidden smDown>
              <Button
                size="small"
                variant="outlined"
                disabled={products.isLoadingTodaysDeals}
                className={classes.toolbarButton}
                onClick={this.onTellAFriend}
              >
                <Share />
                <Typography
                  className={classes.buttonText}
                  
                >
                  Share
                </Typography>
              </Button>

              <Button
                size="small"
                variant="outlined"
                disabled={products.isLoadingTodaysDeals}
                className={classes.toolbarButton}
                onClick={this.onShowHelp}
              >
                <ContactSupport />
                <Typography
                  className={classes.buttonText}
                 
                >
                  Help
                </Typography>
              </Button>

              <Button
                size="small"
                variant="outlined"
                disabled={products.isLoadingTodaysDeals}
                className={classes.toolbarButton}
                onClick={this.onContactUs}
              >
                <Email />
                <Typography
                  className={classes.buttonText}
                
                >
                  Contact us
                </Typography>
              </Button>
            </Hidden>

            {Helpers.isLoggedIn(this.props.session.user) ? (
              <Button
                size="small"
                variant="outlined"
                className={classes.loginButton}
                onClick={this.onLogOut}
              >
                <Hidden smDown>
                  <Typography className={classes.buttonText}>Logout</Typography>
                </Hidden>
                <ExitToApp />
                <Hidden smUp>
                  <Typography className={classes.buttonText}>Logout</Typography>
                </Hidden>
              </Button>
            ) : (
              <Button
                color="secondary"
                variant="contained"
                className={classes.loginButton}
                onClick={this.openLoginPageModal}
              >
                <Person className={classes.iconButtonText} />
                <Typography className={classes.loginButtonText}>
                  <Hidden smDown>Sign Up / </Hidden>Log In{" "}
                </Typography>
              </Button>
            )}
          </Toolbar>
        </AppBar>

        <AppBar
          color="inherit"
          position="sticky"
          elevation={0}
          className={classes.appBar}
        >
          <Toolbar className={classes.homeToolbar} disableGutters={true}>
            <Hidden smDown>
              <img
                src="./resources/images/BC-Logo.png"
                className={classes.logo}
                onClick={this.goHome}
              />
            </Hidden>
            <div className={classes.search}>
              {/* <ProductAutoComplete
                onSuggestionSelected={this.onSearchInputChange}
                onSuggestionValueChanged={this.onSuggestionValueChanged}
              /> */}
              <ProductSearchBox
                onSuggestionSelected={this.onSearchInputChange}
                onSuggestionValueChanged={this.onSuggestionValueChanged}
              />
            </div>
            <CartButton />
            {/* {this.shoppingListButton()} */}
          </Toolbar>
        </AppBar>

        <main className={classes.content}>
          <Introduction
            hideInstruction={this.props.session.hideInstruction}
            setHideInstruction={this.props.setHideInstruction}
          />
          {this.renderBody()}
          {!this.state.isHeadRoomShowing && <CartButton fixedFab={true} />}
        </main>
        {this.renderFooter()}
        <Dialog
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
          open={this.state.isLocationPickerOpen}
          onClose={this.closeLocationPickerModal}
          TransitionComponent={Transition}
          aria-labelledby="responsive-dialog-title"
          className={classes.postalPopup}
        >
          {/* <img src="./resources/images/Better-Cart-Logo.png" className={ classes.popupLogo } /> */}
          <DialogTitle id="responsive-dialog-title">
            We compare grocery store prices so you can get the best deal on your
            shopping cart!
          </DialogTitle>

          <DialogContent>
            <DialogContentText className={classes.enterPostalInstructions}>
              Enter your postal code so we can find deals near you:
            </DialogContentText>

            <br />

            <LocationPickerPage onLocationPick={this.onLocationPick} />
          </DialogContent>
        </Dialog>

        <LoginPage
          open={isLoginModalOpen}
          onClose={this.closeLoginPageModal}
          onLoginSuccess={this.onLoginSuccessHandler}
          // title = {LoginTitle}
          // subTitle = {LoginSubTitle}
          messageNumber={loginMessageNumber}
        />

        <Dialog
          open={this.state.isTellAFriendOpen}
          onClose={this.onTellAFriend}
        >
          <DialogTitle
            className={(classes.shareDialogText, classes.shareTitle)}
          >
            Share BetterCart
          </DialogTitle>
          <DialogContent className={classes.shareDialogText}>
            <Favorite
              color="secondary"
              className={classes.shareHeart}
            ></Favorite>
            <br />
            <span className={classes.shareSubTitle}>
              Help your friends save money!
            </span>
            <br />
            Select a method to share below:
            <br />
            <br />
            <FacebookShareButton
              url={shareUrl}
              quote={shareQuote}
              className={classes.shareButton}
            >
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>{" "}
            <FacebookMessengerShareButton
              url={shareUrl}
              quote={shareQuote}
              className={classes.shareButton}
            >
              <FacebookMessengerIcon size={32} round={true} />
            </FacebookMessengerShareButton>{" "}
            <TwitterShareButton
              url={shareUrl}
              title={shareQuote}
              className={classes.shareButton}
            >
              <TwitterIcon size={32} round={true} />{" "}
            </TwitterShareButton>
            <WhatsappShareButton
              url={shareUrl}
              title={shareQuote}
              className={classes.shareButton}
            >
              <WhatsappIcon size={32} round={true} />
            </WhatsappShareButton>{" "}
            <EmailShareButton
              url={shareUrl}
              subject={shareSubject}
              body={shareBody}
              className={classes.shareButton}
            >
              <EmailIcon size={32} round={true} />
            </EmailShareButton>{" "}
            {/* <ViberShareButton
              url={shareUrl}
              title={shareQuote}
              className={classes.shareButton}
            >
              <ViberIcon size={32} round={true} />
            </ViberShareButton> */}
            <br />
            <br />
          </DialogContent>
        </Dialog>

        <Dialog open={this.state.isContactUsOpen} onClose={this.onContactUs}>
          <DialogTitle>We'd love to hear from you!</DialogTitle>
          <DialogContent>
            Please email us at{" "}
            <a href="mailto:support@bettercart.ca">support@bettercart.ca</a>
            <br />
            <br />
            Thanks,
            <br />
            <br />
            The BetterCart Team
          </DialogContent>
        </Dialog>

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.showUserLoginWelcome}
          autoHideDuration={3000}
          onClose={this.hideUserLoginWelcome}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">Welcome!</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.hideUserLoginWelcome}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  cart: state.cart.items,
  location: state.location,
  searchfilters: state.searchfilters,
  searchTerm: state.products.searchTerm,
  session: state.session,
  products: state.products,
  categories:state.categories,
});
const mapDispatchToProps = (dispatch, props) => ({
  fetchCategories: () =>
    dispatch(fetchCategories()),
  setLocation: (postal, latitude, longitude) =>
    dispatch(setLocation(postal, latitude, longitude)),
  loadSavedCart: (cart) => dispatch(loadSavedCart(cart)),
  getStoresNearby: (latitude, longitude, proximity) =>
    dispatch(getStoresNearby(latitude, longitude, proximity)),
  setFilteredStores: (stores) => {
    dispatch(setFilteredStores(stores));
  },
  setHideInstruction: (hideInstruction) => {
    dispatch(setHideInstruction(hideInstruction));
  },
  setProximity: (proximity) => {
    dispatch(setProximity(proximity));
  },
  clearSearchHistory: () => dispatch(clearSearchHistory()),
  searchProduct: (
    searchTerm,
    searchCategory,
    cart,
    latitude,
    longitude,
    filteredStores
  ) =>
    dispatch(
      searchProduct(
        searchTerm,
        searchCategory,
        cart,
        latitude,
        longitude,
        filteredStores
      )
    ),
  fetchFeaturedProducts: (cart = [], latitude, longitude) =>
    dispatch(fetchFeaturedProducts((cart = []), latitude, longitude)),
  fetchStoreFeaturedProducts: (
    cart = [],
    filteredStores,
    latitude,
    longitude
  ) =>
    dispatch(
      fetchStoreFeaturedProducts(
        (cart = []),
        filteredStores,
        latitude,
        longitude
      )
    ),
  setLocationAndUpdateStoresNearby: (postal, latitude, longitude) =>
    dispatch(setLocationAndUpdateStoresNearby(postal, latitude, longitude)),
  setLocationAndGetStoresNearby: (postal, latitude, longitude, proximity) =>
    dispatch(
      setLocationAndGetStoresNearby(postal, latitude, longitude, proximity)
    ),
  logout: () => dispatch(logout()),
  dispatch: (params) => dispatch(params),
});

export default withRouter(
  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(HomePage))
);
