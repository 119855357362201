const productsReducerDefaultState = {
  isSearching: false,
  errorOccurred: false,
  searchResult: [],
  searchTerm: '',
  searchCategory: '',

  isLoadingTodaysDeals: true,
  errorOccurredTodaysDeals: false,
  todaysDeals: [],

  isLoadingProduct: false,
  errorOccurredLoadingProduct: false,
  productBeingViewed: undefined
};

export default (state = productsReducerDefaultState, action) => {

  switch (action.type) {
    case 'SET_SEARCHING' :
      return {
        ...state,
        isSearching: action.isSearching,
        errorOccurred: false
      };

    case 'SET_ERROR_OCCURRED' :
      return {
        ...state,
        isSearching: false,
        errorOccurred: action.errorOccurred
      };

    case 'SET_SEARCH_RESULT' :
      // console.log(action.searchResult);
      return {
        ...state,
        isSearching: false,
        errorOccurred: false,
        searchResult: action.searchResult,
        searchTerm: action.searchTerm,
        searchCategory: action.searchCategory
      }; 
    
    case 'CLEAR_SEARCH_HISTORY' :
      return {
        ...state,
        searchTerm: '',
        searchCategory: '',
        searchResult: []
      }

    case 'SET_LOAD_PRODUCT_SPINNER' :
      return {
        ...state,
        isLoadingProduct: action.isLoadingProduct,
        errorOccurredLoadingProduct: false
      }

    case 'SET_ERROR_OCCURRED_LOADING_PRODUCT' :
      return {
        ...state,
        isLoadingProduct: false,
        errorOccurredLoadingProduct: action.errorOccurredLoadingProduct
      }

    case 'SET_PRODUCT_BEING_VIEWED' :
      // console.log({
      //   ...state,
      //   productBeingViewed: action.productBeingViewed
      // });
      return {
        ...state,
        isLoadingProduct: false,
        errorOccurredLoadingProduct: false,
        productBeingViewed: action.productBeingViewed
      }

    case 'UPDATE_SEARCH_HISTORY' :
      return {
        ...state,
        searchResult: state.searchResult.map((product) => {
          if (product._id === action.product._id) {
            return action.product;
          } else {
            return product;
          }
        })
      };

    case 'UPDATE_TODAYS_DEALS' :
      return {
        ...state,
        todaysDeals: state.todaysDeals.map((product) => {
          if (product._id === action.product._id) {
            return action.product;
          } else {
            return product;
          }
        })
      };

    case 'MAKE_ALL_PRODUCTS_AVAILABLE' :
      return {
        ...state,
        searchResult: state.searchResult.map((product) => {
          product.inTheCart = false;
          return product;
        })
      };

    case 'SET_LOADING_TODAYS_DEALS' :
      return {
        ...state,
        isLoadingTodaysDeals: action.isLoadingTodaysDeals,
        errorOccurredTodaysDeals: false
      }

    case 'SET_ERROR_OCCURRED_TODAYS_DEALS' :
      return {
        ...state,
        isLoadingTodaysDeals: false,
        errorOccurredTodaysDeals: action.errorOccurredTodaysDeals
      }

    case 'SET_TODAYS_DEALS' :
      return {
        ...state,
        isLoadingTodaysDeals: false,
        errorOccurredTodaysDeals: false,
        todaysDeals: action.todaysDeals
      }

    default:
      return state;
  }
};
