import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import WhatInStockBanner from "./WhatInStockBanner";
import WhyWeCreateSiteContent from "./WhyWeCreateSiteContent";
import ReactGA from "react-ga";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      //padding: theme.spacing(2),
    },
  },
  content: {
    textAlign: "center",
    padding: theme.spacing(4),
    // width: "70%",
    [theme.breakpoints.down("sm")]: {
      // width: "100%",
      padding: theme.spacing(2),
    },
  },
  contentText1: {
    maxWidth:"750px",
    fontSize: "25px",
    fontFamily: "Montserrat",
    fontWeight: "500",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },

  signImgContainer: {
    //paddingTop: "16px",
    display: "block",
  },
  signImg: {
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(14),
    },
  },
  contentText2: {
    maxWidth:"750px",
    fontSize: "30px",
    fontWeight: "bold",
    fontFamily: "Montserrat",
    color: theme.palette.secondary.main,
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },

  logoImgContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  logoImg: {
    [theme.breakpoints.down("sm")]: {
      width: "55px",
    },
    width: "150px",
    // display: "block",
  },
  logoText: {
    fontFamily: "Montserrat",
    fontWeight: "600",
    color: "#004093",

    fontSize: "34px",
    // lineHeight: "26px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px",
      lineHeight: "26px",
    },
  },
  logoSubText: {
    fontSize: "12px",
    lineHeight: "8px",
    letterSpacing: "0.86px",
    fontFamily: "Montserrat",
    fontWeight: "600",
    color: "#30A23B",

    [theme.breakpoints.down("sm")]: {
      fontSize: "7px",
      lineHeight: "8px",
      letterSpacing: "0.86px",
    },
  },
  buttonEmailUsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 40px",
  },
  buttonEmailUs: {
    // padding: "20px",
    width: "458px",
    height: "78px",
    lineHeight: "78px",
    backgroundColor: "#00B04F",
    border: "solid 1px #00B04F",
    borderRadius: "4px",
    color: "#fff",
    fontWeight: "bold",
    fontSize: "25px",
    fontFamily: "Montserrat",
    textAlign: "center",
    verticalAlign: "middle",
    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      width: "248px",
      height: "46px",
      lineHeight: "46px",
    },
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#fff",
      color: theme.palette.secondary.main,
    },
  },
  buttonEmailUsText: {},
  // mailtoButton: {
  //   display: "block",
  //   fontWeight: "bold",
  //   fontSize: "14px",
  //   fontFamily: "Montserrat",
  //   width: "100px",
  //   height: "20px",
  // },
}));

export default function LearnMore() {
  const classes = useStyles();
  const onButtonEmailUsClick = () => {
    alert("Email Us");
  };

  return (
    <div className={classes.root}>
      <WhatInStockBanner />
      <WhyWeCreateSiteContent />
      <div className={classes.content}>
        <Typography className={classes.contentText1}>
          Right now, Canadians are struggling to find the products they need.
          Our goal is to help Canadians find out if the products they want to
          buy are available before they shop. We hope this helps people gain
          time and lessen their exposure.
          {/* At the current time Canadians are
          struggling to find the products their families need. Our goal is to
          help Canadians help each other find these products through crowd
          sourcing and by leveraging our existing grocery and drug store
          database. */}
        </Typography>
      </div>
      <div className={classes.content}>
        <Typography className={classes.contentText1}>
          To help, simply take a moment after you shop to update a few stock
          levels for the next shopper. We really appreciate it, and others will
          too!
        </Typography>
      </div>
      <div className={classes.content}>
        <Typography className={classes.contentText1}>
          Sending you and your loved ones our very best,
        </Typography>
      </div>
      <div className={classes.content}>
        <Typography className={classes.signImgContainer}>
          <img
            src="./resources/images/Melanie-signature.png"
            className={classes.signImg}
          />
        </Typography>
        <Typography className={classes.contentText1}>
          CEO of BetterCart
        </Typography>
      </div>
      <Typography className={classes.logoImgContainer}>
        <img
          src="./resources/images/Better-Cart-Logo---just-cart---square@3x.png"
          className={classes.logoImg}
        />
        <span className={classes.logoText}>BetterCart</span>
        <span className={classes.logoSubText}>helping you save everyday</span>
      </Typography>
     
      
      <div className={classes.content}>
        <Typography className={classes.contentText1}>
        To find out more about BetterCart, please contact us.
        </Typography>
      </div>

      <div className={classes.buttonEmailUsContainer}>
        <a
          className={classes.buttonEmailUs}
          href="mailto:press@bettercart.ca?subject=Find out more about BetterCart"
        >
          press@bettercart.ca
        </a>
      </div>
    </div>
  );
}
