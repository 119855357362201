import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "485px",
    height: "446px",
    border: "1px solid #00B04F",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "241px",
      height: "223px",
    },
  },
  closeButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      // fontSize: "14px",
    },
  },
  closeButton: {
    zIndex: "9999",
    [theme.breakpoints.down("sm")]: {
      padding: "4px",
    },
  },
  iconClose:{
    height: "1.5em",
    width: "1.5em",
    [theme.breakpoints.down("sm")]: {
      height: "1em",
      width: "1em",
    },
  },
  heartImg: {
    height: "auto",
    width: "200px",
    [theme.breakpoints.down("sm")]: {
      width: "62px",
    },
  },
  message: {
    width: "242px",
    paddingTop: "21px",

    // lineHeight: "28px",
    fontSize: "34px",
    fontWeight: "bold",
    fontFamily: "Montserrat",

    textAlign: "center",
    color: theme.palette.secondary.main,
    [theme.breakpoints.down("sm")]: {
      width: "162px",
      paddingTop: "21px",
      lineHeight: "28px",
      fontSize: "23px",
    },
  },
}));

export default function DialogStatusUpdate(props) {
  const classes = useStyles();

  const { open, onClose } = props;

  useEffect(() => {
    const interval = setInterval(() => {
      onClose();
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <div className={classes.container}>
        <div className={classes.closeButtonContainer}>
          <IconButton
            className={classes.closeButton}
            aria-label="close"
            onClick={onClose}
          >
            <CloseIcon className={classes.iconClose} />
          </IconButton>
        </div>
        <img
          src="./resources/images/Canadian-Heart.png"
          className={classes.heartImg}
        />
        <Typography className={classes.message}>
          Thank you for helping!
        </Typography>
      </div>
    </Dialog>
  );
}
