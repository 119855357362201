import axios from 'axios';
import envConfig from '../bin/env.config';

import { fetchFeaturedProducts, searchProduct } from './products';
import { setFilteredStores, setProximity } from './searchfilters';

export const setLocation = (postal, latitude, longitude,province=undefined) => ({
  type: 'SET_LOCATION',
  postal,
  latitude,
  longitude,
  province
});

export const setLocationAndUpdateStoresNearby = (postal, latitude, longitude) => {
  return (dispatch) => {
    dispatch(setLocation(postal, latitude, longitude));
    dispatch(updateStoresNearby(latitude, longitude));
  };
};


export const setLocationAndGetStoresNearby = (postal, latitude, longitude, proximity) => {
  //  console.log('setLocationAndGetStoresNearby');
  return (dispatch) => {
    dispatch(setLocation(postal, latitude, longitude));
    dispatch(setProximity(proximity));
    dispatch(getStoresNearby(latitude, longitude, proximity));
    
  };
};

export const setLoadingStoresNearby = (isLoadingStoresNearby = true) => ({
  type: 'SET_LOADING_STORES_NEARBY',
  isLoadingStoresNearby
});

export const setErrorStoresNearby = (errorOccurredWhileLoadingStoresNearby = true) => ({
  type: 'SET_ERROR_STORES_NEARBY',
  errorOccurredWhileLoadingStoresNearby
});

export const setStoresNearby = (storesNearby = []) => ({
  type: 'SET_STORES_NEARBY',
  storesNearby
});

export const updateStoresNearby = (latitude, longitude) => {
  return (dispatch, getState) => {
    dispatch(setLoadingStoresNearby(true));
    // dispatch(setErrorStoresNearby(false));

    const state = getState();
    const proximity = state.searchfilters.proximity;
    //console.log('location'+ JSON.stringify(state.location));
    

    const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/stores/nearby?latitude=${latitude}&longitude=${longitude}&proximity=${proximity}`;
    axios.get(url)
      .then((response) => {
        const data = response.data.data;
        
        // setTimeout(() => {
          // dispatch(setLoadingStoresNearby(false));
          dispatch(setStoresNearby(data));
          dispatch(setFilteredStores(data));
          dispatch(fetchFeaturedProducts([], latitude, longitude));

          if (state.products.searchResult.length > 0 || state.products.searchTerm !== '') {
            dispatch(searchProduct(state.products.searchTerm));
          }
        // }, 3000);
      })
      .catch((err) => {
        // dispatch(setLoadingStoresNearby(false));
        dispatch(setErrorStoresNearby(true));
      });
  };
}

export const getStoresNearby = (latitude, longitude, proximity) => {
  // console.log("getStoresNearby");
  return (dispatch, getState) => {
    dispatch(setLoadingStoresNearby(true));
    // dispatch(setErrorStoresNearby(false));

    //const state = getState();
    //const proximity = state.searchfilters.proximity;
    //console.log('proximity'+ proximity);
    

    const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/stores/nearby?latitude=${latitude}&longitude=${longitude}&proximity=${proximity}`;
    // console.log("#api get no6"+url);
    axios.get(url)
      .then((response) => {
        const data = response.data.data;
        
        // setTimeout(() => {
          // dispatch(setLoadingStoresNearby(false));
          dispatch(setStoresNearby(data));       
             
          //dispatch(setFilteredStores(data));
          //dispatch(fetchFeaturedProducts([], latitude, longitude));

          //if (state.products.searchResult.length > 0 || state.products.searchTerm !== '') {
          //  dispatch(searchProduct(state.products.searchTerm));
          //}
        // }, 3000);
      })
      .catch((err) => {
        // dispatch(setLoadingStoresNearby(false));
        dispatch(setErrorStoresNearby(true));
      });
  };
}