import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { InputBase } from '@material-ui/core';
import axios from "axios";
import envConfig from "../../bin/env.config";

import { useDispatch, useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: "rgba(255,255,0,0.3)",
    padding:'2px 8px 2px 8px',
    fontSize:'0.875rem'
  }, 
}));

export default function EditPMGTextBox(props) {
  const classes = useStyles();
  const { pmgId, value, field } = props;
  const [inputValue, setInputValue] = useState(value)
  const token = useSelector((state) => state.session.token);

  const onChange = (event) => {
    //Update data
    setInputValue(event.target.value)
    const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/group/update`;
    axios.post(
      url,
      { 
        productMatchingGroupID: pmgId,
        label: field==='label'?event.target.value:undefined,
        name: field==='name'?event.target.value:undefined,
        size: field==='size'?event.target.value:undefined,
      },
      {
        headers: {
          Authorization: JSON.stringify(token),
        },
      }
    );    
  };
  return (
    <InputBase
        className={classes.input}
        placeholder={`enter ${field}`}
        value={inputValue}
        onChange={onChange}
    />
  );
}
