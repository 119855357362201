import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import StoreLogo from "../../StoreLogo/StoreLogo";
import ProductItem from "../../Product/ProductItem";

const useStyles = makeStyles((theme) => ({
  root: {},
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function ViewAllDialog(props) {
  const classes = useStyles();
  const { open, onClose, companyData, products } = props;
  const {
    productShare,
    productSelect,
    productView,
    productLike,
    productAlert,
    showAlert,
    openEditProductDialog,
    isAdmin
  } = props;

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar position="sticky" color="inherit">
        <Toolbar>
          <IconButton color="inherit" onClick={onClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="title" color="inherit">
            {companyData.company.name}
          </Typography>
        </Toolbar>
      </AppBar>

      <StoreLogo company={companyData.company} />

      <Grid container spacing={24}>
        {products.map((item) => (
          <Grid item xs={12} sm={12} md={4} lg={3} key={item._id}>
            <ProductItem
              key={item._id}
              product={item}
              productShare={productShare}
              productSelect={productSelect}
              productView={productView}
              productLike={productLike}
              productAlert={productAlert}
              showAlert={showAlert}
              openEditProductDialog={openEditProductDialog}
              isAdmin={isAdmin}
            />
          </Grid>
        ))}
      </Grid>
    </Dialog>
  );
}
