import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
const useStyles = makeStyles((theme) => ({
  tableCell:{
    padding: theme.spacing(1)
  },
  removeButton: {
    color: "red",
    borderColor: "red",
  },
}));

export default function ListItem({productMatchingGroup, doSelect, doRemove, isManage} ) {
  const classes = useStyles();
  return (
      <TableRow key={productMatchingGroup.id}>
        <TableCell align="center" className={classes.tableCell}>{productMatchingGroup.id}</TableCell>
        <TableCell align="left"  className={classes.tableCell}>{productMatchingGroup.name}</TableCell>
        <TableCell align="center"  className={classes.tableCell}>{productMatchingGroup.brand_name}</TableCell>        
        <TableCell align="center"  className={classes.tableCell}>{productMatchingGroup.size}</TableCell>
        {/* <TableCell align="center">{productMatchingGroup.products_count}</TableCell>
        <TableCell align="center">{productMatchingGroup.match_score_avg}</TableCell> */}
        {!isManage&&
        <TableCell align="center"  className={classes.tableCell}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => doSelect(productMatchingGroup)}
          >
            select
          </Button>
        
        </TableCell>
        }
        {isManage&&
        <TableCell align="center"  className={classes.tableCell}>
          <IconButton
            size="small"
            className={classes.removeButton}
            onClick={() => doRemove(productMatchingGroup)}
          >
            <DeleteForeverIcon/>
          </IconButton>       
        
        </TableCell>
        }
      </TableRow>
  );
}
