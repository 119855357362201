import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import CardHeader from '@material-ui/core/CardHeader';
import Grid from "@material-ui/core/Grid";
import VizSensor from "react-visibility-sensor";

const useStyles = makeStyles((theme) => ({
  loadMore: {
    //  object-fit is not supported by IE11.
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: "10px",
  },
  
  ingroup: {},
}));

export default function ProductItemLoadMore({ doLoadMore }) {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={12} md={4} lg={3} className={classes.ingroup}>
      <VizSensor onChange={doLoadMore}>
          <div class={classes.loadMore}>&nbsp;&nbsp;&nbsp;</div>
      </VizSensor>
    </Grid>
  );
}
