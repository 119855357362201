import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import envConfig from "../../../../bin/env.config";
import store from "../../../../store/store";

import ReviewItem from "./ReviewItem";
import ReviewItemOriginal from "./ReviewItemOriginal";
import ReviewItemBlank from "./ReviewItemBlank";
import MergeConfirmDialog from "./MergeConfirmDialog";
import MoreButtonsMenu from "./MoreButtonsMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  rootLeft: {
    position: "-webkit-sticky",
    position: "sticky",
    top: 64,
    backgroundColor: theme.palette.secondary.main,
    // paddingTop: "64px",
    // width:"50%"
  },
  rootRight: {
    display: "flex",
  },

  acceptContainer: {
    backgroundColor: "#DDD",
  },
  allWrongButton:{
    color: '#fff',
    backgroundColor: 'rgb(220, 0, 78)',
    borderColor: 'rgb(220, 0, 78)',
    '&:hover': {
      backgroundColor: 'rgb(154, 0, 54)',     
    },
    '&:active': {
      backgroundColor: 'rgb(154, 0, 54)',     
    },
    '&:focus': {
      backgroundColor: 'rgb(154, 0, 54)',     
    },
  }
}));

export default function ReviewPanel({
  selectedGroup,
  simValue,
  simBrandValue,
  simSizeValue,
  simSource,
  // doAlert,
  handleAccept,
  handleAllWrong,
}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [product, setProduct] = useState({});
  const [showMatched, setShowMatched] = useState(true);
  const [isMergeConfirmDialogOpen, setIsMergeConfirmDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (selectedGroup.id > 0) {
        const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/pbs/review`;
        const result = await axios.post(
          url,
          {
            id: selectedGroup.id,
            sim: simValue,
            simBrand: simBrandValue,
            simSize: simSizeValue,
            simSource: simSource,
          },
          {
            headers: {
              Authorization: JSON.stringify(store.getState().session.token),
            },
          }
        );
        setProduct({
          locked:result.data.data.locked,
          original:result.data.data.original,
          matchingData: [...result.data.data.matches,...result.data.data.variety,...result.data.data.potentials,...result.data.data.wrongs]
        });
      }
      setIsLoading(false);     
    };
    selectedGroup && fetchData();
  }, [selectedGroup, simValue, simBrandValue, simSizeValue, simSource]);
  const handleNotAMatch = (productMatch) => {
    let temp = product.matchingData.map((p) => {
      return  p.id === productMatch.id?{...p,type:'wrong'}:p; 
    })    
    // temp.potentials.push(productMatch);
    setProduct({...product, matchingData:temp});
    //add to product.potentials
  };
  const handleAcceptClick = (e) => {
    let originalGroupCount = product.original.product.product_matching_group_id > 0?1:0;
    let tempPotential = product.matchingData.filter((p) =>p.type==='potential');
    let potentialGroupCount = originalGroupCount + tempPotential.reduce((a, b) => a + (b.product_matching_group_id&&b.product_matching_group_id>0?1:0), 0);
    //console.log(originalGroupCount);
    // console.log(potentialGroupCount);
    if(potentialGroupCount>=2){
      //show confirm merge dialog
      setIsMergeConfirmDialogOpen(true);
    }else{
     
      handleAcceptLocal()
    }
  }
  const handleAllWrongClick = (e)=>{
    let removedIds =  product.matchingData.filter((p) => p.type === 'potential').map((p)=>p.id);
    let temp = product.matchingData.map((p) => {
      return  p.type === 'potential'?{...p,type:'wrong'}:p; 
    }) 
    if(removedIds.length>0){    
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/pbs/wrongs`;
        const postData = {
          product_matched_ids: removedIds,
          product_id: product.original.product.id,
          simSource: simSource,
        };
        // console.log(postData);
        axios
          .post(url, postData, {
            headers: {
              Authorization: JSON.stringify(store.getState().session.token),
            },
          })
          .then((response) => {          
            // temp.potentials.push(productMatch);
              setProduct({...product, matchingData:temp});
              handleAllWrong && handleAllWrong();
          })
          .catch((err) => {
            console.log(err);
          });
    }else{
      handleAllWrong && handleAllWrong();
    }      
  }
  const handleAcceptLocal = (e) => {
    console.log("----"*100);
    console.log(product.matchingData);
    let matchesIds = product.matchingData.filter((p) => p.type === 'potential').map((p)=>p.id);
    let temp = product.matchingData.map((p) => {
      return  p.type === 'potential'?{...p,type:'matches'}:p; 
    }) 
    const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/pbs/accept`;
    const postData = {
      product_matched_ids: matchesIds,
      product_id: product.original.product.id,
      simSource: simSource,
    };
    
    axios
      .post(url, postData, {
        headers: {
          Authorization: JSON.stringify(store.getState().session.token),
        },
      })
      .then((response) => {          
        // temp.potentials.push(productMatch);
          setProduct({...product, matchingData:temp});
          setIsMergeConfirmDialogOpen(false);
          handleAccept && handleAccept();
      })
      .catch((err) => {
        console.log(err);
      });    
  };
  const handleWrong = (productMatch) => {
    let temp = product.matchingData.map((p) => {
      return  (productMatch.product_matching_group_id&&(p.product_matching_group_id === productMatch.product_matching_group_id))||(p.id === productMatch.id)?{...p,type:'wrong'}:p; 
    }) 
    let removedIds = temp.filter((p) =>p.type==='wrong').map(p=>p.id);
    // console.log(removedIds);

    const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/pbs/wrongs`;
    const postData = {
      product_matched_ids: removedIds,
      product_id: product.original.product.id,
      simSource: simSource,
    };
    // console.log(postData);
    axios
      .post(url, postData, {
        headers: {
          Authorization: JSON.stringify(store.getState().session.token),
        },
      })
      .then((response) => {          
          // temp.potentials.push(productMatch);
          setProduct({...product, matchingData:temp});
      })
      .catch((err) => {
        console.log(err);
      });     
  };
  const handleMatches = (productMatch) => {      
    //update: incase group
    let temp = product.matchingData.map((p) => {
      return  (productMatch.product_matching_group_id&&(p.product_matching_group_id === productMatch.product_matching_group_id))||(p.id === productMatch.id)?{...p,type:'matched'}:p; 
    })    
    setProduct({...product, matchingData:temp});
    //add to product.matches        
  };
  const openMoreButtonsMenu = (event,productMatch) => {
    setSelectedProduct(productMatch);
    setAnchorEl(event.currentTarget);
  };
  const closeMoreButtonsMenu = () => {
    setAnchorEl(null);
  };
      
  const updateIsMultiVariety = (productMatch) =>{
    let temp = { ...product };  
    let mIndex = temp.matchingData.findIndex(element => element.id==productMatch.id)
    mIndex>=0 && (temp.matchingData[mIndex] = {...productMatch});    
    setProduct(temp);    
  } 
  return (
    <>
      {isLoading ? (
        <Grid
          className={classes.root}
          container
          spacing={0}
          alignItems="center"
          justify="center"
        >
          <div>Loading ...</div>
        </Grid>
      ) : (
        <>{ console.log(product)}
          {product && product.original && (
            <ReviewItemOriginal
              originalProduct={product.original}
              locked={product.locked}
            />
          )}
          <Grid
            className={classes.root}
            container
            spacing={0}
            // alignItems="center"
            // justify="center"
          >
            { product &&
              product.matchingData &&
              product.matchingData.map((item, i) => (
                item.type!="wrong"&&
                    <ReviewItem
                      key={i}
                      productMatch={item}
                      groupId={selectedGroup.id}
                      simSource={simSource}
                      handleNotAMatch={handleNotAMatch}
                      handleWrong={handleWrong}
                      handleMatches={handleMatches}
                      openMoreButtonsMenu={openMoreButtonsMenu}
                    />                               
              ))}            
            
            {product && product.potentials && product.potentials.length === 0 && (
              <ReviewItemBlank/>
            )}
              <Grid
                // className={classes.root}
                container
                spacing={0}
                alignItems="flex-end"
                justify="flex-start"
                className={classes.acceptContainer}
              >
                <div style={{ padding: "20px" }}>
                  <Button
                    // ref={btnAcceptRef}
                    variant="contained"
                    color="secondary"
                    // size="small"
                    // className={classes.removeButton}
                    // disabled={acceptDisabled}
                    onClick={handleAcceptClick}
                  >
                    Accept
                  </Button>
                  </div>  
                  <div style={{ padding: "20px 0px" }}>
                  <Button
                    // ref={btnAllWrongRef}
                    variant="contained"
                    // color="secondary"
                    // size="small"
                    className={classes.allWrongButton}
                    // disabled={acceptDisabled}
                    onClick={handleAllWrongClick}
                  >
                    All Wrong
                  </Button>
                </div>
              </Grid>
            {/* )} */}
          </Grid>
          <MergeConfirmDialog
            open={isMergeConfirmDialogOpen}
            onClose={()=>setIsMergeConfirmDialogOpen(false)}
            product={product}
            handleAccept={handleAcceptLocal}
          />
          <MoreButtonsMenu 
          closeMoreButtonsMenu = {closeMoreButtonsMenu}
          anchorEl={anchorEl}
          groupId={selectedGroup.id}
          productMatch={selectedProduct}
          updateIsMultiVariety={updateIsMultiVariety}
          />
        </>
      )}
    </>
  );
}
