import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";

import statusStock from "../data/statusStock";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "485px",
    paddingBottom: "26px",
    //height: "223px",
    border: "1px solid #00B04F",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "241px",
      // paddingBottom: "26px",
    },
  },
  closeButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    // fontSize: "25px",
    // //height: "34px",
    [theme.breakpoints.down("sm")]: {
      // fontSize: "14px",
      //height: "22px",
    },
  },
  closeButton: {
    zIndex: "9999",
    [theme.breakpoints.down("sm")]: {
      padding: "4px",
    },
  },
  iconClose:{
    height: "1.5em",
    width: "1.5em",
    [theme.breakpoints.down("sm")]: {
      height: "1em",
      width: "1em",
    },
  },
  descriptionContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "nowrap",
    width: "472px",
    [theme.breakpoints.down("sm")]: {
      width: "209px",
    },
    //zIndex:"-1"
    //paddingTop: "8px",
  },
  decriptionText: {
    // fontWeight: "bold",
    fontFamily: "Montserrat",
    fontSize: "25px",
    // lineHeight: "23px",
    ///marginLeft: "-100",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "23px",
    },
    // padding: theme.spacing(2),
  },
  statusButtonContainer: {
    width: "100%",
    padding: "8px 0px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "4px 0px",
      // lineHeight: "23px",
    },
  },
  statusButton: {
    width: "400px",
    height: "76px",
    textTransform: "none",
    fontWeight: "bold",
    fontFamily: "Montserrat",
    fontSize: "27px",
    color: "#FFF",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      width: "209px",
      height: "42px",

      // lineHeight: "23px",
    },
  },
}));

export default function DialogStatusUpdate(props) {
  const classes = useStyles();

  const { open, onClose, onStatusClick, product } = props;

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <div className={classes.container}>
        <div className={classes.closeButtonContainer}>
          <IconButton
            className={classes.closeButton}
            aria-label="close"
            onClick={onClose}
          >
            <CloseIcon className={classes.iconClose} />
          </IconButton>
        </div>
        <div className={classes.descriptionContainer}>
          <Typography className={classes.decriptionText}>
            What is the current stock level for <strong>{product.name}?</strong>
          </Typography>
        </div>
        {Object.keys(statusStock)
          .reverse()
          .map((i) => {
            const styleColor = {
              backgroundColor: statusStock[i].color,
            };
            if (i > 0) {
              return (
                <div key={i} className={classes.statusButtonContainer}>
                  <Button
                    className={classes.statusButton}
                    style={styleColor}
                    onClick={() => onStatusClick(i)}
                  >
                    {statusStock[i].name}
                  </Button>
                </div>
              );
            } else {
              return "";
            }
          })}
      </div>
    </Dialog>
  );
}
