import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Skeleton from "react-loading-skeleton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { Utility } from "../../utility/utility";
import { Helpers } from "../../utility/helpers";
import envConfig from "../../bin/env.config";

import {
  setLocation,
  setLocationAndGetStoresNearby,
} from "../../actions/location";
import {
  fetchStoreFeaturedProducts,
  clearSearchHistory,
} from "../../actions/products";

import { setFilteredStores } from "../../actions/searchfilters";
import { saveFavoriteStores } from "../../actions/session";
import Alert from "../Alert/Alert";
import Map from "../Map/Map";

import Box from "@material-ui/core/Box";

import ReactGA from "react-ga";

const styles = (theme) => ({
  storeList: {
    width: "100%",
  },
  rootItem: {},
  item: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  root: {
    //background: theme.palette.primary.main,
    textAlign: "center",
    // height: `calc(100vh - 288px)`,
    height: `calc(100vh - ${envConfig.TURN_ON_ADS?'288px':'140px'})`,
    [theme.breakpoints.down("sm")]: {
      // height: `calc(100vh - 154px)`,
      height: `calc(100vh - ${envConfig.TURN_ON_ADS?'154px':'90px'})`,
    },
    //padding: theme.spacing(2) + 'px',
    ///boxShadow: 'inset 0 0 30px #022974'
  },
  mainGrid: {
    height: "100%",
  },
  list: {
    //background: theme.palette.primary.main,
    textAlign: "center",
    alignItems: "center",
    padding: theme.spacing(5),
    marginRight: "auto",
    marginLeft: "auto",
    paddingTop: 0,
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(1),
      paddingLeft: 0,
      paddingRight: 0,
    },
    ///boxShadow: 'inset 0 0 30px #022974'
  },
  itemList: {
    //background: theme.palette.primary.main,
    textAlign: "left",
    //padding: theme.spacing(6) + 'px',
    ///boxShadow: 'inset 0 0 30px #022974'
  },
  reportButtonText: {
    color: "#fff",
    //marginTop:'1em'
    fontWeight: "500",
    // fontFamily: 'Montserrat',
    fontSize: "14px",
    padding: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  },
  iconButton: {
    padding: 10,
  },
  companyLogo: {
    height: "42.5 px",
    width: "120px",
    //margin: theme.spacing(1),
    cursor: "pointer",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  introText: {
    marginTop: theme.spacing(2) + "px",
    // marginBottom: theme.spacing(2) + "px",
    fontWeight: "bold",
    fontFamily: "Montserrat",
    fontSize: "36px",
    lineHeight: "1.2em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  introTextContainer: {
    display: "block",
    textAlign: "center",
  },
  button: {
    padding: "2px 4px",
    display: "block",
    alignItems: "center",
    fontFamily: "Montserrat",
    width: "350px",
    height: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: theme.spacing(6),
    },
  },
  buttonText: {
    color: "#fff",
    //fontWeight: 'bold',
    fontSize: "25px",
    fontFamily: "Montserrat",
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  },
  hideSelectWarning: {
    display: "none",
  },
  showSelectWarning: {
    padding: "20px",
  },
  buttonEdit: {
    padding: "1px 2px",
    margin: "1px 1px",
    display: "inline",
    width: "35px",
    height: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      width: "25px",
      height: theme.spacing(4),
    },
  },
  changeLocation: {
    fontWeight: "bold",
    fontFamily: "Montserrat",
    fontSize: "1rem",
    // lineHeight: "23px",
    color: theme.palette.primary.main,
    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      //fontSize: "14px",
      paddingTop: "8px",
      lineHeight: "23px",
    },
  },
  currentLocationContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.secondary.main,
    fontSize: "20px",
    padding: "8px",
    lineHeight: "1.5em",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      fontSize: "16px",
    },
  },
  currentLocationLink: {
    fontWeight: "bold",
    fontFamily: "Montserrat",
    // lineHeight: "23px",
    color: theme.palette.secondary.main,
    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      //fontSize: "14px",
      paddingTop: "8px",
    },
  },
  singleStore: {
    border: "1px solid #ccc",
    padding: "5px",
    borderRadius: "4px",
    display: "flex",
    marginBottom: "8px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  content: {
    height: "100%",
  },
  item: {
    minWidth: 0,
  },
  locationList: {
    paddingTop: "0",
    height: "100%",
    position: "relative",
    width: "100%",
    //maxWidth: 550,
  },
  leftGrid: {
    overflow: "auto",
    height: "100%",
  },
  map: {
    //border: '1px solid #ffffaa',
    display: "flex",
    width: "100%",
    //maxWidth: 800,
    paddingLeft: "0px",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    "& .leaflet-container": {
      height: "100%",
      width: "100%",
    },
  },
  snackbarRoot: {
    color: "#ff0000",
    borderColor: theme.palette.secondary.main,
    borderWidth: "2px",
    borderStyle: "solid",
    backgroundColor: "#fff",
    fontWeight: "bold",
    marginTop: "60px",
    marginRight: "-10px",
  },
  storeSelection: {
    width: "100%",
    marginLeft: "0",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
    },
  },
  tabRoot: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: 550,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: 40,
    },
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },

  formButton: {
    width: "100%",
  },
  buttonSave: {
    position: "fixed !important",
    bottom: "20px",
    right: "2%",
  },
  buttonReport: {
    position: "fixed !important",
    bottom: "20px",
    left: "2%",
  },
});

// masonry columns
const breakpointColumnsObj = {
  default: 3,
  1100: 3,
  700: 2,
  500: 1,
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
};

class StoresPicker extends Component {
  state = {};

  constructor(props) {
    super(props);
    this.state = {
      isHidden: false,
      isReportDialogOpen: false,
      isLoading: true,
      proximity: undefined,
      location: undefined,
      stores: undefined,
      tabSelected: 0,
      selectedStores: [],
    };
    this.alertPopup = React.createRef();
  }
  openAlertPopup = (severity, message) => {
    this.alertPopup.current.doAlert(severity, message);
  };
  componentDidMount() {
    // console.log("componentDidMount")
    this.loadData();
  }

  loadData = () => {
    const {filteredStores}  = this.props.searchfilters;
    // const filteredStores  = this.props.searchfilters.filteredStores.filter((store)=>{
    //   return this.props.location.storesNearby.findIndex((item)=>item._id===store._id)>0
    // });
    if (
      Helpers.isLoggedIn(this.props.session.user) &&
      this.props.session.user.favoriteStores.length > 0
    ) {
      // console.log('Login user');
      // const selectedStoresTemp = this.props.session.user.favoriteStores.filter((store)=>{
      //   return this.props.location.storesNearby.findIndex((item)=>item._id===store._id)>0
      // }); 
      this.setState({
        selectedStores: this.props.session.user.favoriteStores,
        // selectedStores: selectedStoresTemp,
      });
    } else {
      this.setState({
        selectedStores: filteredStores,
      });
    }
  };
  // localSavedInfo = () =>{
  //   if (localStorage.getItem('stores')) {
  //     const localSavedStores = JSON.parse(localStorage.getItem('stores'));
  //     this.setState({
  //       selectedStores: localSavedStores
  //     });
  //   }
  // }

  // loadUserSavedInfo = () =>{
  //   //console.log('favoriteStores: '+ this.props.session.user.favoriteStores);
  //   if (Helpers.isLoggedIn(this.props.session.user)){
  //       this.setState({
  //         selectedStores: this.props.session.user.favoriteStores
  //       });
  //   }
  // }
  handleChange = (storeID) => (event) => {
    // this.setState({ [storeID]: event.target.checked });

    const checked = event.target.checked;
    //let updatedFilteredStores = this.props.searchfilters.filteredStores;
    let updatedFilteredStores = this.state.selectedStores;
    if (checked) {
      for (let store of this.props.location.storesNearby) {
        if (
          store._id === storeID &&
          updatedFilteredStores.findIndex((s) => s._id === storeID) === -1
        ) {
          updatedFilteredStores.push(store);
          break;
        }
      }
    } else {
      updatedFilteredStores = updatedFilteredStores.filter(
        (store) => store._id !== storeID
      );
    }

    this.props.setFilteredStores(updatedFilteredStores);
    this.setState({
      selectedStores: updatedFilteredStores,
    });
  };

  handleMapUpdate = (filteredStores, companyIndex) => {
    this.setState({
      selectedStores: filteredStores,
    });

    let ref = `refItem${companyIndex}`;
    this.handleScrollTo(this[ref]);
    // console.log(ref);
  };

  onSaveButtonClick = () => {
    //if (this.state.searchValue!==''){
    this.onSave();
    //this.props.goNextPage();
    //}
  };
  onEditButtonClick = () => {
    //if (this.state.searchValue!==''){
    // this.onSave();

    //#Trello task: Clear postal code to make landing page work without redirect to home page
    this.props.setLocation("");
    this.props.goLandingPage();
    //}
  };

  onSave = () => {
    //this.state.selectedStores.length === 0
    //const selectedStores = this.props.location.storesNearby.filter((store) => this.state[store._id]);
    // const temp =  this.state.selectedStores.filter((store)=>{
    //   return this.props.location.storesNearby.findIndex((item)=>item._id===store._id)>0
    // }); 
    const selectedStores = this.state.selectedStores.filter((store)=>{
                                                              return this.props.location.storesNearby.findIndex((item)=>item._id===store._id)>=0
                                                            })
                                                    .sort((a, b) => (a.companyData.sort > b.companyData.sort) ? 1 : -1);
    const { postal, latitude, longitude } = this.props.location;
    const { proximity } = this.props.searchfilters;
    // console.log("selectedStores:" + selectedStores);
    if (selectedStores.length <= 0) {
      this.openAlertPopup(
        "error",
        "Please select at least one store to continue."
      );
    } else {
      // console.log(selectedStores); 
      this.props.setFilteredStores(selectedStores);
      //this.props.selectedStores(selectedStores);

      let favoriteStores = "";
      if (selectedStores && selectedStores.length > 0) {
        const stores = selectedStores.map((store) => store._id);
        favoriteStores = stores.join(",");
      }
      if (Helpers.isLoggedIn(this.props.session.user)) {
        this.props.saveFavoriteStores(
          favoriteStores,
          this.props.location.postal
        );
      }
      //fire event to load stores nearby
      this.props.clearSearchHistory();
      this.props.fetchStoreFeaturedProducts(
        [],
        selectedStores,
        latitude,
        longitude
      );
      //end fire
      this.props.goNextPage();
    }
  };

  onOpenReportDialog = () => {
    this.setState({ isReportDialogOpen: true });
    // console.log("setting to false");
    ReactGA.pageview("ReportAMissingStore");
  };

  onCloseReportDialog = () => {
    this.setState({ isReportDialogOpen: false });
    // console.log("setting to false");
  };

  toggleHidden = () => {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  };

  renderLoadingState = () => {
    return (
      <div>
        <Skeleton width={180} />
        <br />
        <Skeleton width={180} />
        <br />
        <Skeleton width={180} />
      </div>
    );
  };

  handleChangeTab = (event, value) => {
    this.setState({
      tabSelected: value,
    });

    let ref = `refItem${value}`;
    this.handleScrollTo(this[ref]);
  };

  handleScrollTo = (elRef) => {
    // Incase the ref supplied isn't ref.current
    const el = elRef.current ? elRef.current : elRef;

    // Scroll the element into view
    el.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  tabProps = (index) => {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  };

  renderVerticalTabs = () => {
    const { classes, session, index } = this.props;
    let value = this.state.tabSelected;
    let companyLogo = "";
    let companies = [];
    // console.log(this.props.location.storesNearby);
    this.props.location.storesNearby.map((store) => {
      if (store.companyData.companyLogoLink !== companyLogo) {
        companyLogo = store.companyData.companyLogoLink;
        //companies.push(<img src={companyLogo} className={ classes.companyLogo }/>);
        companies.push(store.companyData.companyLogoLink);
      }
    });
    let i = 0;
    return (
      <div className={classes.storeSelection}>
        <div className={classes.tabRoot}>
          {
            <div className={classes.list}>
              <FormGroup className={classes.storeList}>
                {this.props.location.storesNearby.map((store) => {
                  let companyDisplay = "";
                  if (store.companyData.companyLogoLink !== companyLogo) {
                    companyLogo = store.companyData.companyLogoLink;
                    let refI = `refItem${i++}`;
                    this[refI] = React.createRef();
                    companyDisplay = (
                      <img
                        src={companyLogo}
                        className={classes.companyLogo}
                        ref={this[refI]}
                      />
                    );
                  } else {
                    companyDisplay = "";
                  }

                  let content = store.name;
                  if (store.distance) {
                    content +=
                      " - " + Utility.roundTo1Decimal(store.distance) + "km";
                  }

                  return (
                    <div className={classes.itemList} key={store._id}>
                      {companyDisplay}
                      <FormControlLabel
                        className={classes.singleStore}
                        key={store._id}
                        control={
                          <Checkbox
                            checked={this.state[store._id]}
                            onChange={this.handleChange(store._id)}
                            value={store._id}
                          />
                        }
                        label={content}
                      />
                    </div>
                  );
                })}
              </FormGroup>
              <br />
              <div className={"here"}>
              <Hidden smDown>
                <div className={classes.bottomBar}>
                  <div className={classes.buttonSave}>
                    <Button
                      className={classes.button}
                      onClick={() => this.onSaveButtonClick()}
                      //disabled={ this.state.selectedStores.length === 0 }
                      color="secondary"
                      variant="contained"
                      fullWidth={true}
                    >
                      <Typography className={classes.buttonText}>
                        Save and Continue
                      </Typography>
                    </Button>
                  </div>

                  {/*
                  //Trello #200522 
                  <div className={classes.buttonReport}>
                    <Button
                      onClick={() => this.onOpenReportDialog()}
                      className={classes.button}
                      size="small"
                      fullWidth={true}
                      variant="outlined"
                    >
                      <Typography className={classes.reportButtonText}>
                        {" "}
                        Report a missing store
                      </Typography>
                    </Button>
                  </div> */}
                </div>
                {/*Trello #200522*/}
                <div
                  className={
                    this.state.selectedStores.length === 0
                      ? classes.showSelectWarning
                      : classes.hideSelectWarning
                  }
                >
                  Please select at least one store to continue.
                </div>
                <br />
                <Button
                  onClick={() => this.onOpenReportDialog()}
                  size="small"
                  fullWidth={true}
                  disabled={this.state.selectedStores.length === 0}
                  className={classes.reportingButton}
                >
                  Report a missing store
                </Button>
                <br />
                <br />
                <br />
                {/* //End Trello #200522 */}
              </Hidden>

              <Hidden lgUp>
                <Button
                  className={classes.button}
                  onClick={() => this.onSaveButtonClick()}
                  disabled={this.state.selectedStores.length === 0}
                  color="secondary"
                  variant="contained"
                  fullWidth={true}
                >
                  <Typography className={classes.buttonText}>
                    Save and Continue
                  </Typography>
                </Button>
                <div
                  className={
                    this.state.selectedStores.length === 0
                      ? classes.showSelectWarning
                      : classes.hideSelectWarning
                  }
                >
                  Please select at least one store to continue.
                </div>
                <br />
                <Button
                  onClick={() => this.onOpenReportDialog()}
                  size="small"
                  fullWidth={true}
                  disabled={this.state.selectedStores.length === 0}
                  className={classes.reportingButton}
                >
                  Report a missing store
                </Button>
                <br />
                <br />
                <br />
              </Hidden>
              </div>    
            </div>
          }
          {/* {
            companies.map((company, index)=>{    
              let ref =`refTab${index}`; 
              this[ref] = React.createRef();          
              return (
                <div >
                  <TabPanel value={value} index={index} >                                     
                     {
                      this.props.location.storesNearby.map((store) => {                                               
                        if (1 || store.companyData.companyLogoLink === company){            
                          let content =  store.name;                  
                          if (store.distance){
                            content +=' - ' + Utility.roundTo1Decimal(store.distance) + 'km';
                          }  
                          console.log(content); 
                          let ref =`refTab${index}`; 
                          this[ref] = React.createRef();                                                  
                          return (                   
                            <div ref={this[ref]}>                        
                              <Checkbox
                                  checked={this.state[store._id]}                          
                                  onChange={this.handleChange(store._id)}
                                  value={ store._id }
                                />                      
                                { content }                                           
                            </div>
                          );
                        }                      
                      })    
                    }                     
                    }
                  </TabPanel>                  
                </div>
              );
            })
          }  */}
        </div>
      </div>
    );
  };

  renderForm = () => {
    const { classes, session } = this.props;
    let companyLogo = "";
    return (
      <div className={classes.list}>
        <FormGroup className={classes.storeList}>
          {this.props.location.storesNearby.map((store) => {
            let company = "";
            if (store.companyData.companyLogoLink !== companyLogo) {
              companyLogo = store.companyData.companyLogoLink;
              company = (
                <img src={companyLogo} className={classes.companyLogo} />
              );
            } else {
              company = "";
            }

            let content = store.name;
            if (store.distance) {
              content += " - " + Utility.roundTo1Decimal(store.distance) + "km";
            }

            return (
              <div className={classes.itemList}>
                {company}
                <FormControlLabel
                  className={classes.singleStore}
                  key={store._id}
                  control={
                    <Checkbox
                      checked={this.state[store._id]}
                      onChange={this.handleChange(store._id)}
                      value={store._id}
                    />
                  }
                  label={content}
                />
              </div>
            );
          })}
        </FormGroup>

        <br />
        <Button
          className={classes.button}
          onClick={() => this.onSaveButtonClick()}
          disabled={this.state.selectedStores.length === 0}
          color="secondary"
          variant="contained"
          fullWidth={true}
        >
          <Typography className={classes.buttonText}>
            Save and Continue
          </Typography>
        </Button>
        <div
          className={
            this.state.selectedStores.length === 0
              ? classes.showSelectWarning
              : classes.hideSelectWarning
          }
        >
          Please select at least one store to continue.
        </div>

        <Button
          onClick={() => this.onOpenReportDialog()}
          size="small"
          fullWidth={true}
          disabled={this.state.selectedStores.length === 0}
          className={classes.reportingButton}
        >
          Report a missing store
        </Button>
      </div>
    );
  };

  renderReportDialog = () => {
    return (
      <Dialog
        open={this.state.isReportDialogOpen}
        onClose={this.onCloseReportDialog}
      >
        <DialogTitle>Report a missing store location</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please{" "}
            <a href="mailto:support@bettercart.ca?subject=I%20found%20a%20missing%20store&body=Hi,%0D%0A%0D%0A%20I%20found%20that%20there%20is%20a%20store%20missing%20from%20your%20list.%0D%0A%0D%0AThe%20store%20location%20that%20is%20missing%20is:%0D%0A%0D%0A">
              email us
            </a>{" "}
            if we are missing a store from any of the grocer chains we work
            with.
            <br />
            <br />
            Currently BetterCart works with the following grocery chains:
            <ul>
              <li>Real Canadian Superstore</li>
              <li>Save on Foods</li>
              <li>Sobeys</li>
              <li>The Independent Grocer</li>
              <li>Extra Foods</li>
              <li>Walmart</li>
              <li>Thrify Foods</li>
              <li>Co-op</li>
              <li>Quality Foods</li>
              <li>No Frills</li>
              <li>Foodland</li>
              <li>Atlantic Superstore</li>
              <li>Fortinos</li>
              <li>Independent City Market</li>
              <li>Maxi</li>
              <li>Dominion</li>
              <li>Valu-Mart</li>
              <li>Zehrs</li>
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onCloseReportDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  render() {
    const { classes, session } = this.props;

    //let updatedFilteredStores = this.props.location.storesNearby;
    //let localSavedStoresId = this.state.localSavedStoresId;
    //console.log('updatedFilteredStores'+ JSON.stringify(updatedFilteredStores));
    //console.log('isLoading:'+ this.state.isLoading);

    let updatedFilteredStores = this.state.selectedStores;
    // //Check favoriteStores
    // let favoriteStores=[];
    // if (Helpers.isLoggedIn(this.props.session.user)){
    //     favoriteStores = this.props.session.user.favoriteStores;
    //     if (favoriteStores && favoriteStores.length>0){
    //       for(let store of this.props.location.storesNearby){
    //         if (favoriteStores.includes(store._id)){
    //           updatedFilteredStores.push(store);
    //         }
    //       }
    //     }
    // } else {
    //     // Check saved store at local for guest
    //     const localSavedStoresId = localStorage.getItem('stores');
    //     //console.log(localSavedStoresId);
    //     if (localSavedStoresId && localSavedStoresId.length>0){
    //       for(let store of this.props.location.storesNearby){
    //         if (localSavedStoresId.indexOf(store._id)>=0){
    //           updatedFilteredStores.push(store);
    //         }
    //       }
    //     }
    //   }

    //console.log(updatedFilteredStores);
    //this.props.setFilteredStores(updatedFilteredStores);

    this.props.location.storesNearby.forEach((store) => {
      let selected = false;
      for (let s of updatedFilteredStores) {
        if (s._id === store._id) {
          selected = true;
        }
      }
      this.state[store._id] = selected;
    });

    const postal = this.props.location.postal; //localStorage.getItem("postal");
    const proximity = this.props.searchfilters.proximity || 35; //parseFloat(localStorage.getItem("proximity") || 35);
    // console.log(proximity);

    return (
      <div className={classes.root}>
        <Grid container spacing={0} className={classes.mainGrid}>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className={classes.leftGrid}
          >
            <List className={classes.locationList}>
              <ListItem className={classes.introTextContainer}>
                <Typography color="primary" className={classes.introText}>
                  Select the stores you’d like to compare prices at:
                </Typography>
                <div className={classes.currentLocationContainer}>
                  <div>Currently showing stores within&nbsp;</div>
                  <div>
                    {proximity} km of{" "}
                    <Link
                      onClick={() => this.onEditButtonClick()}
                      to={"#"}
                      className={classes.currentLocationLink}
                      title={"Click to change location"}
                    >
                      {postal}
                    </Link>
                    .
                  </div>
                </div>
                {/* <Link
                      onClick={() => this.onEditButtonClick()}
                      to={"#"}
                      className={classes.changeLocation}
                      title={"Go back"}
                    >
                     <ArrowBackIcon/>
                    </Link> */}
              </ListItem>
              <ListItem>
                {//this.renderForm()
                this.renderVerticalTabs()}
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={false} sm={false} md={6} lg={6} xl={6}>
            <div className={classes.content}>
              <div className={classes.map}>
                <Map
                  location={this.props.location}
                  handleMapUpdate={this.handleMapUpdate}
                />
              </div>
            </div>
          </Grid>
        </Grid>
        {this.renderReportDialog()}
        <Alert ref={this.alertPopup} />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  location: state.location,
  session: state.session,
  searchfilters: state.searchfilters,
  isLoadingTodaysDeals: state.products.isLoadingTodaysDeals,
});

const mapDispatchToProps = (dispatch, props) => ({
  setFilteredStores: (stores) => {
    dispatch(setFilteredStores(stores));
  },
  fetchStoreFeaturedProducts: (
    cart = [],
    filteredStores,
    latitude,
    longitude
  ) =>
    dispatch(
      fetchStoreFeaturedProducts(
        (cart = []),
        filteredStores,
        latitude,
        longitude
      )
    ),
  setLocation: (postal, latitude, longitude) =>
    dispatch(setLocation(postal, latitude, longitude)),
  setLocationAndGetStoresNearby: (postal, latitude, longitude, proximity) =>
    dispatch(
      setLocationAndGetStoresNearby(postal, latitude, longitude, proximity)
    ),
  saveFavoriteStores: (stores, postal) => {
    dispatch(saveFavoriteStores(stores, postal));
  },
  clearSearchHistory: () => dispatch(clearSearchHistory()),
});

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(StoresPicker))
);
