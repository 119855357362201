import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import WhatInStockBanner from "./help/WhatInStockBanner";
import ProductStatusPageBottom from "./help/ProductStatusPageBottom";
import LearnMoreAboutProjectFooter from "./help/LearnMoreAboutProjectFooter";
// import { setLocationAndUpdateStoresNearby } from "../../actions/location";
import StoreList from "./help/StoreList";
import ReactGA from "react-ga";

import AppBarBackButton from "./help/AppBarBackButton";

const styles = (theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  notifyMessage:{
    maxWidth: "750px",
    margin:"8px 8px 8px 8px",
    padding:"8px",
    border:"1px solid rgb(230, 230, 230)",
    borderRadius: "4px",
    backgroundColor:"rgb(242, 242, 242)",
    textAlign: "center",
    fontFamily: "Montserrat",
    fontSize: "16px",
    // textJustify: "inter-word",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      fontWeight:"italic"
    },
    "& strong":{
      fontFamily: "Montserrat",
    }
  },
  descriptionContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "nowrap",
    paddingTop: "8px",
  },
  descriptionText: {
    // fontWeight: "bold",
    fontFamily: "Montserrat",
    fontSize: "25px",
    // lineHeight: "23px",
    marginLeft: "-100",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "23px",
    },
    // padding: theme.spacing(2),
  },
  linkChange: {
    fontWeight: "bold",
    fontFamily: "Montserrat",
    fontSize: "25px",
    // lineHeight: "23px",
    color: theme.palette.secondary.main,
    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "23px",
    },
    // '&:hover': {
    //     color: '#fff'
    // }
  },
});

class ProductStatusPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes, location } = this.props;
    return (
      <div className={classes.root}>
        <AppBarBackButton />
        <WhatInStockBanner />
        <Typography className={classes.notifyMessage}>
        After you shop at any of the stores below, simply click on <strong>"Update"</strong>, and tell your community if there is "<strong>A lot</strong>," "<strong>A little</strong>", or "<strong>None</strong>" available. 
        <br/><strong>Thank you for paying it forward!</strong>
        </Typography>  
        <div className={classes.descriptionContainer}>
          <Typography className={classes.descriptionText}>
            Showing stock levels near <strong>{location.postal}</strong> &nbsp;
          </Typography>
          <Link to={"/location-picker"} className={classes.linkChange}>
            Change
          </Link>
        </div>
             
        <StoreList location={location} />
        <ProductStatusPageBottom />
        <LearnMoreAboutProjectFooter />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  location: state.location,
  session: state.session,
});
const mapDispatchToProps = (dispatch, props) => ({});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProductStatusPage)
);
