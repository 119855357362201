import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
  TableSortLabel ,
  Typography,
  IconButton,
  Paper
} from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from "react-redux";
import FilterBox from "./FilterBox";
import StatusUpdate from "./StatusUpdate";
import NoteUpdate from './NoteUpdate';

import axios from "axios";
import envConfig from "../../../bin/env.config";
import { convertToViewDate } from "../../../utility/utility";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  table: {
    minWidth: 350,
    "& tbody>.MuiTableRow-root:hover": {
      background: "#DDD",
    },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
}));
export function SortLabel(props){
  const classes = useStyles();
  return (
    <TableSortLabel 
                {...props}
                className={classes.sortLabel}
                IconComponent={props=>(props.hideSortIcon?'':<FontAwesomeIcon icon={faSort} />)}
              >
                {props.noWrap?(<span style={{paddingRight:'5px',whiteSpace:'nowrap'}}>{props.children}</span>):
                (<span style={{paddingRight:'5px'}}>{props.children}</span>)}               
                </TableSortLabel>
  )
}
export function updatedTime(dt){
  return new Date(dt).toLocaleString('en-US')
} 
export default function CheckNotUp2DateProductsPage() {
  const history = useHistory();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  // const [showID, setShowID] = useState(false);
  const [limit, setLimit] = useState(50);
  const [skip, setSkip] = useState(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("checked_at");

  const [dataStatus, setDataStatus] = useState({list:[],dict:{}});  
  const [dataSource, setDataSource] = useState([]);  
  const [filter, setFilter]=useState({q:'',city: "",status:"1",company_id:0})
  const token = useSelector((state) => state.session.token);
  
  const loader = document.querySelector(".loader");
  const showLoader = () => loader.classList.remove("loader--hide");

  const hideLoader = () => {
    loader.classList.add("loader--hide");
    // updateContainer.classList.add("loader--hide");
  };
  useEffect(() => {
    const fetchStatus = async () => {
      showLoader();
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/check-prices-data/status`;      
      const result = await axios.get(url, {
        headers: {
          Authorization: token,
        },
      });
      let list = result.data.data
      let dict = Object.assign({}, ...list.map((x) => ({[x.id]: x})));
      setDataStatus({list:list,dict:dict});
      
      setIsLoading(false);
      hideLoader();
    };
    fetchStatus();
  }, []);
  useEffect(() => {
    
      const fetchData = async () => {
        showLoader();
        setIsLoading(true);
        const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/check-prices-data/products-not-up-to-date?orderBy=${orderBy}&order=${order}&limit=${limit}&skip=${skip}`;
        const result = await axios.post(url,filter, {
          headers: {
            Authorization: token,
          },
        });
        setDataSource(result.data.data);
        setIsLoading(false);
        hideLoader();
      };
      fetchData();
    
  }, [filter,skip, order, orderBy]);
  const handleSort=(column)=>{
    setOrder(order==='desc'?'asc':'desc');
    setOrderBy(column);
    setSkip(0);
  }
  return (
    <>
      <AppBar position="sticky" color="inherit">
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={() => history.goBack()}
            aria-label="Close"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            Out of date tool
          </Typography>
          {dataStatus.list.length&&<FilterBox doSearch={setFilter} dataStatus={dataStatus}/>}
        </Toolbar>
      </AppBar>
      {isLoading ? (
        <Grid
          className={classes.root}
          container
          alignItems="center"
          justify="center"
        >
          <div>Loading ...</div>
        </Grid>
      ) : (
        <Grid
          className={classes.root}
          container
          alignItems="center"
          justify="center"
        >
          <TableContainer component={Paper}>
            <Table className={classes.table} stickyHeader aria-label="simple table">
              <TableHead >
                <TableRow>
                  {/* <TableCell align="center">data_year_week</TableCell> */}
                  <TableCell align="center" >                    
                    <SortLabel onClick={()=>handleSort('product_matching_group_id')}>pmgId</SortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <SortLabel onClick={()=>handleSort('product_id')}>pId</SortLabel>                    
                  </TableCell>
                  <TableCell align="center">                    
                    <SortLabel onClick={()=>handleSort('store_id')}>sId</SortLabel>  
                  </TableCell>
                  <TableCell align="left">
                    <SortLabel onClick={()=>handleSort('city')}>City</SortLabel>
                  </TableCell>
                  <TableCell align="left">
                  <SortLabel onClick={()=>handleSort('chain_name')}>Chain</SortLabel></TableCell>
                  <TableCell align="left"><SortLabel onClick={()=>handleSort('store_name')}>Store</SortLabel></TableCell>
                  <TableCell align="left">
                  <SortLabel onClick={()=>handleSort('product_name_on_store')}>Product</SortLabel>
                    </TableCell>
                  <TableCell align="center">
                  <SortLabel onClick={()=>handleSort('last_collected_price_at')}>Last collected at</SortLabel></TableCell>
                  <TableCell align="center">
                    <SortLabel onClick={()=>handleSort('diff_days')}>Diff days</SortLabel>                    
                  </TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Note</TableCell>
                  <TableCell align="center"><SortLabel onClick={()=>handleSort('checked_at')}>Last updated</SortLabel></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataSource.map((row, i) => (
                  <>
                    <TableRow>
                      {/* <TableCell align="center">{row.data_year_week}</TableCell> */}
                      <TableCell align="center">
                        <a href={`https://dev.bettercart.ca/matching-group/${row.product_matching_group_id}`} target="_blank">
                          {row.product_matching_group_id}
                        </a>
                      </TableCell>
                     
                      <TableCell align="center">
                        <a href={`https://dev.bettercart.ca/product/${row.product_id}`} target="_blank">
                          {row.product_id}
                        </a>
                      </TableCell>
                      <TableCell align="center">{row.store_id}</TableCell>
                      <TableCell align="left">{row.city}</TableCell>
                      <TableCell align="left">{row.chain_name}</TableCell>
                      <TableCell align="left">{row.store_name}</TableCell>
                      <TableCell align="left">
                        <a href={row.external_link} target="_blank">
                        {row.product_name_on_store}
                        </a>
                      </TableCell>
                      <TableCell align="center"><span style={{transition:'pre'}}>{convertToViewDate(row.last_collected_price_at)}</span></TableCell>
                      <TableCell align="center">{row.diff_days}</TableCell>
                      <TableCell align="center"><StatusUpdate dataStatus={dataStatus} oodItem={row}/></TableCell>
                      <TableCell align="center"><NoteUpdate oodItem={row}/></TableCell>
                      <TableCell align="center"><span style={{transition:'pre'}}>{updatedTime(row.checked_at)}</span></TableCell>
                      
                    </TableRow>                   
                  </>
                ))}
              </TableBody>             
            </Table>
          </TableContainer>
        </Grid>
      )}
    </>
  );
}
