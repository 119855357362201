import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

// import LearnMoreDialog from './DialogFull';

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    background: theme.palette.secondary.main,
    paddingTop: "18px",
    paddingBottom: "23px",
    [theme.breakpoints.up("sm")]: {},
    [theme.breakpoints.up("lg")]: {
      // marginTop: theme.spacing(10),
    },
    [theme.breakpoints.up("lxl")]: {
      // marginTop: theme.spacing(10),
    },
    [theme.breakpoints.up("xl")]: {
      // marginTop: theme.spacing(2),
    },
  },

  learnMoreButton: {
    display: "block",
    //width:"258px",
    height: "44px",
    padding: "2px 10px",
    border: "solid 1px #FFF",
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.up("sm")]: {
      //width:"458px",
      height: "70px",
      padding: "4px 20px",
    },
    // color:"#FFF",
    // "&:hover": {
    //   color: "#fff",
    // },
  },
  learnMoreButtonText: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: "12px",
    //lineHeight: "15px",
    color: "#FFF",
    [theme.breakpoints.up("sm")]: {
      fontSize: "25px",
      //lineHeight: "15px",
    },
  },
  copyrightContainer: {
    marginTop: "32px",
    marginBottom: "23px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginTop: "12px",
      marginBottom: "12px",
    },
  },
  copyrightText: {
    fontFamily: "Montserrat",
    color: "#fff",
    fontSize: "16px",
    // lineHeight: "12px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      lineHeight: "12px",
    },
  },
  copyrightSeparator: {
    fontFamily: "Montserrat",
    color: "#fff",
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
      fontSize: "10px",
      lineHeight: "12px",
    },
  },
}));
export default function LearnMoreAboutProjectFooter() {
  const classes = useStyles();
  const history = useHistory();

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  //const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleOnOpen = () => {
    history.push("learn-more");
  };

  return (
    <div className={classes.container}>
      <Button
        className={classes.learnMoreButton}
        onClick={handleOnOpen}
        //disabled={ !this.state.searchValue || this.state.searchValue.length < 6 }
        variant="contained"
        color="secondary"
        disableElevation
      >
        <Typography className={classes.learnMoreButtonText}>
          learn more about this project
        </Typography>
      </Button>
      <div className={classes.copyrightContainer}>
        <Typography className={classes.copyrightText}>
          © {currentYear} BetterCart Technologies Inc. &nbsp;|&nbsp; Terms &
          Conditions
        </Typography>
      </div>
      {/* <LearnMoreDialog
        comName="LearnMore"
        open={isDialogOpen}
        onClose={handleOnClose}
      /> */}
    </div>
  );
}
