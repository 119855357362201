import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Toolbar from '@material-ui/core/Toolbar';
// import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { Utility } from '../../utility/utility';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import globalStyles from '../../styles/styles';
import axios from 'axios';
import envConfig from '../../bin/env.config';

const styles = theme => ({
  root: {},
  buttonProgress: {
    ...globalStyles.buttonSpinnerProgress
  },
  success: {
    color: theme.palette.secondary.main,
    fontWeight: "bold",
    textAlign:"center",
    fontSize: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  notifyMessage:{
    maxWidth: "750px",
    // color: theme.palette.secondary.main,
    // margin:"8px 8px 8px 8px",
    padding:"8px",
   // border:"1px solid rgb(230, 230, 230)",
   // borderRadius: "4px",
   // backgroundColor:"rgb(242, 242, 242)",
    textAlign: "center",
    fontFamily: "Montserrat",
    fontSize: "16px",
    // textJustify: "inter-word",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      fontWeight:"italic"
    },
  },
});

class ResetPasswordRequestForm extends Component {

  constructor (props) {
    super(props);
    this.state = {
                    fields: {
                      password1: '',
                      password2: ''
                    },
                    errors: {},
                    isLoading: false,
                    isSuccess: false,
                    isError: false,
                    token: '',
                    tokenType: 0
                  };
    this.onValueChange = this.onValueChange.bind(this);
    this.keyPress = this.keyPress.bind(this);
  }

  componentDidMount () {
    //alert(this.props.match.params.token);
    const token = this.props.match.params.token;
    this.setState({token:token});
    // setSignUpLoader(false);
    // setSignUpError(null);  
  }

  loadTokenInformation = (token) => {
    // const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/users/resetpasswordtoken`;
    // axios.post(url, { token: this.state.token })
    //   .then((response) => {
    //     //In case 1: invalid token
    //     //In case 2: token expired
    //     //In case 3: token correct
    //     if (response.data.success) {
    //       this.setState({
    //         isLoading: false,  
    //         tokenType: response.data.type
    //       });
    //     }else{
    //       this.setState({
    //         isLoading: false,
    //         isError: true
    //       });
    //     }        
    //   })
    //   .catch((err) => {
    //     this.setState({
    //       isLoading: false,
    //       isError: true
    //     });
    //   });
  }

  onValueChange = (e) => {
    e.persist();   
    this.setState((state) => {
      return state.fields[e.target.name] = e.target.value
    });
  } 
  keyPress(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      //this.onResetPassword();
      // console.log(e);
    }
  }
  validateForm = () => {
    let fields = this.state.fields;
    let isFormValid = true;
    let errors = {};

    if (!Utility.isSet(fields['password1']) || !fields['password1'] || fields['password1'].length === 0) {
      errors['password1'] = `Password is required`;
      isFormValid = false;
    }

    if (!Utility.isSet(fields['password2']) || !fields['password2'] || fields['password2'].length === 0) {
      errors['password2'] = `Password is required`;
      isFormValid = false;
    }

    if (fields['password1'] !== fields['password2']) {
      errors['password2'] = `The retype passwords don't match`;
      isFormValid = false;
    }

    this.setState({
      errors: errors
    });

    return isFormValid;
  };

  onResetPassword = () => {
    const errors = {};
    if (!this.validateForm()) {
      // console.log(this.state)
      return;
    }

    this.setState({
      isLoading: true
    });
    const postData = {
      password: this.state.fields.password1,
      token:  this.state.token
    };
    const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/users/resetpassword`;
    axios
      .put(url, postData)  
      .then((response) => {
        // console.log(response);
        //In case 1: Error change pass --> show warning
        //In case 2: Success change pass --> show welcome message --> do you want login 
        if (response.data.success) {
         // console.log('here');
          this.setState({
            isLoading: false,          
            isSuccess: true,
            errors: errors
          });
        }else{
          // console.log(response.)
         // console.log('here1');
          errors['message'] = response.err_msg;
          this.setState({
            isLoading: false,
            isError: true,
            errors: errors
          });
        }  
       
      })
      .catch((err) => {        
        //console.log(err);
        if (err.response.data.success===false) {
          errors['message'] = err.response.data.err_msg;
          this.setState({
            isLoading: false,
            isError: true,
            errors: errors
          });
        }else{
          errors['message'] = "Your reset password link has expired. ";
          this.setState({
            isLoading: false,
            isError: true,
            errors: errors
          });
        }      
      });
  }

  onBack = () => {
    this.props.history.push('/');
  }

  // hidePasswordResetNotification = () =>{
  //   this.setState({
  //     showPasswordResetNotification: false
  //   })
  // }

  renderForm = () => {
    const { classes } = this.props;
    return (
      <form className={classes.container} noValidate autoComplete="off" >        
        {
          this.state.errors['message'] && (
            <Typography
              variant="subtitle1"
              color="error"
              className={classes.notifyMessage}
            >
              { this.state.errors['message'] }
            </Typography>
          )
        }

        <TextField
          label="New password"
          name="password1"
          type="password"
          placeholder="Enter your new password"
          fullWidth
          margin="normal"
          variant="outlined"
          onChange= { this.onValueChange }        
          InputLabelProps={{
            shrink: true
          }}
          error={!!this.state.errors["password1"]}
          helperText={this.state.errors["password1"]}
        />
        <TextField
          label="Re-enter new password"
          name="password2"
          type="password"
          placeholder="Re-enter your new password"
          fullWidth
          margin="normal"
          variant="outlined"
          onChange= { this.onValueChange }        
          InputLabelProps={{
            shrink: true
          }}
          error={!!this.state.errors["password2"]}
          helperText={this.state.errors["password2"]}
        />
        
        <Button
          onClick={ this.onResetPassword }
          color="primary"
          variant="contained"
          fullWidth={ true } 
          disabled={ this.state.isLoading||this.state.isError||(this.state.fields['password1'].length===0)||(this.state.fields['password2'].length===0) }
          >
          Reset Password
         {this.state.isLoading && <CircularProgress size={24} className={classes.buttonProgress} /> }
        </Button>

        <br />
        <br />
      </form>
    );
  }

  renderSuccess = () => {
    const { classes } = this.props;
    return (
      <div>
        <Typography className={classes.success}>
          Your password has been reset successfully
        </Typography>

        <br />

        <Button
          onClick={ this.onBack }
          color="secondary"
          variant="contained"
          fullWidth={ true } 
          >
          Go to home page
        </Button>
      </div>
    );
  }

  render () {
    return (
      <div>
       <AppBar position="sticky" color="inherit">
          <Toolbar>
            <IconButton
                onClick={this.onBack}
              >
              <CloseIcon/>
            </IconButton>
            <Typography variant="subtitle1" color="inherit">
              Reset Password
            </Typography>
          </Toolbar>
        </AppBar>
        <br />
        <Grid container alignItems="center" alignContent="center" justify="center">
          <Grid item xs={10} sm={10} md={6} lg={4} xl={4}>
            { this.state.isSuccess ? this.renderSuccess() : this.renderForm() }
          </Grid>
        </Grid>
      </div>
    );
  }
};


export default withRouter(withStyles(styles)(ResetPasswordRequestForm));
