import React, { Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";

import {
  addFavoriteProduct,
  removeFavoriteProduct,
  addAlertProduct,
  removeAlertProduct,
} from "../../../actions/session";
import {
  setFavoriteProductError,
  setFavoriteProductSuccess,
} from "../../../actions/user";
import { searchProduct } from "../../../actions/products";
import { clearSearchHistory } from "../../../actions/products";
import SearchList from "../SearchList/SearchList";
// import ProductPage from '../../Product/ProductPage';
import TodaysDealsPage from "../../TodaysDeals/TodaysDealsPage";
import ProductItem from "../../Product/ProductItem";
import ProductShareDialog from "../../Product/ProductShareDialog";
import ProductViewDialog from "../../Product/ProductViewDialog";

import Slider from "react-slick";
import { startAddProductToCart } from "../../../actions/cart";
import StoreLogo from "../../StoreLogo/StoreLogo";
import { Helpers } from "../../../utility/helpers";
import LoginPage from "../../Login/LoginPage";
import ReactGA from "react-ga";

import Alert from "../../Alert/Alert";
const EditProductDialog = lazy(() =>
  import("../../FixThePrice/EditProductDialog")
);

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  grow: {
    flexGrow: 1,
  },
  resultHeaderNotFound: {
    padding: "32px 8px 8px 8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    "& p": {
      //fontFamily: 'Montserrat',
      fontWeight: "500",
      fontSize: "1.5rem",
      textAlign: "center",
      color: "#2650a2",
      marginBottom: "30px",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "30px",
        maxWidth: "300px",
      },
    },
    marginBottom: "30px",
    //
  },
  buttonGoBack: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "458px",
    // height: theme.spacing(6),
    height: "56px",
    [theme.breakpoints.down("sm")]: {
      width: "258px",
      height: "38px",
    },
    "& span": {
      fontSize: "20px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
  },

  resultHeaderFound: {
    padding: "8px 8px 8px 8px",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#eee",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    "& p": {
      fontWeight: "500",
      fontSize: "1.5rem",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        // fontSize: "12px",
      },
    },
  },
  slider: {
    marginBottom: theme.spacing(5) + "px",
    padding: 0,
    paddingLeft: "45px",
    paddingRight: "45px",
  },
  shareButton: {
    display: "inline-block",
    marginRight: "10px",
    "&:hover": {
      opacity: "0.5",
    },
  },
  linkChange: {
    fontWeight: "bold",
    fontFamily: "Montserrat",
    fontSize: "1.5rem",
    // lineHeight: "23px",
    color: theme.palette.secondary.main,
    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      //fontSize: "14px",
      paddingTop: "8px",
      lineHeight: "23px",
    },
    // '&:hover': {
    //     color: '#fff'
    // }
  },
});

class SearchPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productModalOpen: false,
      isLoginModalOpen: false,
      isShareModalOpen: false,
      productSelectedForViewing: undefined,
      isProductShareDialogOpen: false,
      productShare: undefined,
      isProductViewDialogOpen: false,
      productView: undefined,
      isEditProductDialogOpen: false,
      isSearchGroupDialogOpen: false,
      productMatching: undefined,
      removeProductFromState: undefined,
      updateProductFromState: undefined,
    };
    ReactGA.pageview("Search");
    this.alertPopup = React.createRef();
  }
  openAlertPopup = (severity, message) => {
    this.alertPopup.current.doAlert(severity, message);
  };
  showAlertProduct = (isAdded) => {
    isAdded
      ? this.openAlertPopup(
          "success",
          "Product has been added to your alerts list"
        )
      : this.openAlertPopup(
          "error",
          "Product has been removed from your alerts list"
        );
  };
  showFavoriteProduct = (isAdded) => {
    isAdded
      ? this.openAlertPopup(
          "success",
          "Product has been added to your favorites"
        )
      : this.openAlertPopup(
          "error",
          "Product has been removed from your favorites"
        );
  };
  showShareSuccess = () => {
    this.openAlertPopup(
      "success",
      "A cool item has been sent to your friend' email"
    );
  };

  onSearchItemSelect = (product, productSource) => {
    this.props.startAddProductToCart(product, productSource);
  };

  // onProductModalView = (product) => {
  //   Helpers.collectClick(product.productID, 2); //View product detail : 2
  //   this.props.history.push(`product/${product._id}`);
  // };

  onProductModalView = (product) => {
    Helpers.collectClick(product.productID, 2); //View product detail : 2
    this.setState({ isProductViewDialogOpen: true, productView: product });
    ReactGA.pageview("ProductDetail");
  };
  handleOnProductViewDialogClose = () => {
    this.setState({ isProductViewDialogOpen: false });
  };
  handleOnProductShareDialogOpen = (product) => {
    Helpers.collectClick(product.productID, 5); //Click on share deal : 5
    this.setState({ isProductShareDialogOpen: true, productShare: product });
    ReactGA.pageview("TellAFriendAProduct");
  };
  handleOnProductShareDialogClose = () => {
    this.setState({ isProductShareDialogOpen: false });
  };
  handleOnEditProductDialogOpen = (
    product,
    productMatching,
    removeProductFromState,
    updateProductFromState
  ) => {
    // alert('test');
    this.setState({
      isEditProductDialogOpen: true,
      productView: product,
      productMatching: productMatching,
      removeProductFromState: removeProductFromState,
      updateProductFromState: updateProductFromState,
    });
  };
  handleOnSearchGroupDialogOpen = () => {
    // alert('test');
    this.setState({
      isSearchGroupDialogOpen: true,
    });
  };
  handleOnEditProductDialogClose = () => {
    this.setState({ isEditProductDialogOpen: false });
  };
  handleOnSearchGroupDialogClose = () => {
    this.setState({ isSearchGroupDialogOpen: false });
  };
  onProductLiked = (product, isFavoriteProduct) => {
    console.log(product);
    Helpers.collectClick(product.productID, 1); //add to favorite : 1
    if (!Helpers.isLoggedIn(this.props.session.user)) {
      this.setState({
        isLoginModalOpen: true,
      });
    } else {
      if (isFavoriteProduct) {
        this.props.removeFavoriteProduct(product);
        this.showFavoriteProduct(false);
      } else {
        this.props.addFavoriteProduct(product);
        this.showFavoriteProduct(true);
      }
    }
  };

  onProductAlert = (product, isAlertProduct) => {
    //console.log(product);
    Helpers.collectClick(product.productID, 6); //Click on get alert: 6
    if (!Helpers.isLoggedIn(this.props.session.user)) {
      this.setState({
        isLoginModalOpen: true,
      });
    } else {
      if (isAlertProduct) {
        this.props.removeAlertProduct(product);
        this.showAlertProduct(false);
      } else {
        this.props.addAlertProduct(product);
        this.showAlertProduct(true);
      }
    }
  };

  onShareSuccessHandler = () => {
    this.setState({
      isShareModalOpen: false,
    });
    this.showShareSuccess();
  };

  onLoginSuccessHandler = () => {
    this.setState({
      isLoginModalOpen: false,
    });
  };

  closeLoginPageModal = () => {
    this.setState({
      isLoginModalOpen: false,
    });
  };
  onButtonGoBackClick = () => {
    //Clear filter
    //this.props.history.push('/home');
    Helpers.resetSearchId();
    // console.log('here');
    this.props.clearSearchHistory();
  };
  onSearchAllCategoriesInstead = () => {
    Helpers.collectSearch(this.props.searchTerm, "All Categories");
    this.props.searchProduct(this.props.searchTerm, "All Categories");
  };
  showLoadingState = () => {
    const { classes } = this.props;
    const sliderSettings = {
      // lazyLoad: 'ondemand',
      dots: true,
      infinite: false,
      arrows: false,
      speed: 500,
      slidesToShow: 4.5,
      slidesToScroll: 4,
      // centerMode: true,
      // autoplay: true,
      // autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ],
    };

    // <div className={classes.todaysDealsSkeleton}>
    //           <Skeleton width={200}  />
    //         </div>
    return (
      <div>
        <Slider {...sliderSettings} className={classes.slider}>
          {[0, 1, 2, 3, 4, 5].map((id) => {
            return <ProductItem isLoading={true} key={id} />;
          })}
        </Slider>
        <Slider {...sliderSettings} className={classes.slider}>
          {[0, 1, 2, 3, 4, 5].map((id) => {
            return <ProductItem isLoading={true} key={id} />;
          })}
        </Slider>
      </div>
    );
  };

  componentDidMount() {
    Helpers.resetSearchId();
  }
  render() {
    const {
      classes,
      isSearching,
      searchResult,
      searchTerm,
      searchCategory,
    } = this.props;

    let isAdmin = Helpers.isAdmin(this.props.session.user);
    // console.log('re render' + this.props.isSearching);
    //console.log(this.props);
    //console.log(searchResult);
    return (
      <div className={classes.root}>
        {searchResult.length === 0 &&
          !isSearching &&
          (!searchTerm || searchTerm.length === 0) && (
            <TodaysDealsPage
              productSelect={(product) => {
                this.onSearchItemSelect(product, "todaysDeals");
              }}
              productView={this.onProductModalView}
              productShare={this.handleOnProductShareDialogOpen}
              productLike={this.onProductLiked}
              productAlert={this.onProductAlert}
              showAlert={this.openAlertPopup}
              openEditProductDialog={this.handleOnEditProductDialogOpen}
              isAdmin={isAdmin}
            />
          )}
        {isSearching ? (
          this.showLoadingState()
        ) : (
          <div className={"searchpage"}>
            {searchTerm &&
              (searchResult.length === 0 ? (
                <div className={classes.resultHeaderNotFound}>
                  <Typography>
                    Sorry, we didn't find any matches for "{searchTerm}" in the
                    category: {searchCategory}
                  </Typography>
                  {searchCategory !== "All Categories" && (
                    <Button
                      className={classes.buttonGoBack}
                      onClick={() => this.onSearchAllCategoriesInstead()}
                      variant="outlined"
                      color="secondary"
                      disableElevation
                    >
                      Search in all categories <Hidden smDown>instead</Hidden>
                    </Button>
                  )}
                  <br />
                  <Button
                    className={classes.buttonGoBack}
                    onClick={() => this.onButtonGoBackClick()}
                    variant="contained"
                    color="secondary"
                    disableElevation
                  >
                    Go Back
                  </Button>{" "}
                  <br />
                </div>
              ) : (
                <div className={classes.resultHeaderFound}>
                  <Typography>
                    We found these matches for "{searchTerm}" in your
                    area.&nbsp;
                  </Typography>
                  <Link
                    onClick={() => this.onButtonGoBackClick()}
                    to={"#"}
                    className={classes.linkChange}
                  >
                    Go back to Best Deals
                  </Link>
                </div>
              ))}
            {searchResult.map((companyData) => {
              return (
                <div key={companyData.company._id}>
                  <StoreLogo company={companyData.company} />
                  <SearchList
                    companyData={companyData}
                    productSelect={this.onSearchItemSelect}
                    productView={this.onProductModalView}
                    productShare={this.handleOnProductShareDialogOpen}
                    productLike={this.onProductLiked}
                    productAlert={this.onProductAlert}
                    showAlert={this.openAlertPopup}
                    openEditProductDialog={this.handleOnEditProductDialogOpen}
                    isAdmin={isAdmin}
                  />
                  <br />
                </div>
              );
            })}
          </div>
        )}
        {this.state.productShare && (
          <ProductShareDialog
            open={this.state.isProductShareDialogOpen}
            onClose={this.handleOnProductShareDialogClose}
            product={this.state.productShare}
          />
        )}
        {this.state.productView && (
          <ProductViewDialog
            open={this.state.isProductViewDialogOpen}
            onClose={this.handleOnProductViewDialogClose}
            product={this.state.productView}
            productSelect={this.onSearchItemSelect}
            // productView={this.onProductModalView}
            productShare={this.handleOnProductShareDialogOpen}
            productLike={this.onProductLiked}
            productAlert={this.onProductAlert}
            showAlert={this.openAlertPopup}
            isAdmin={isAdmin}
            // openEditProductDialog={this.handleOnEditProductDialogOpen}
          />
        )}
        {/* //Fix pricing */}
        {isAdmin && (
          <Suspense fallback={<div>Loading...</div>}>
            <EditProductDialog
              product={this.state.productView}
              productMatching={this.state.productMatching}
              removeProductFromState={this.state.removeProductFromState}
              updateProductFromState={this.state.updateProductFromState}
              open={this.state.isEditProductDialogOpen}
              onClose={this.handleOnEditProductDialogClose}
              openAlertPopup={this.openAlertPopup}
              openSearchGroupDialog={this.handleOnSearchGroupDialogOpen}
            />          
          </Suspense>
        )}
        <LoginPage
          open={this.state.isLoginModalOpen}
          onClose={this.closeLoginPageModal}
          onLoginSuccess={this.onLoginSuccessHandler}
          // title={LoginTitle}
          // subTitle={LoginSubTitle}
          messageNumber={0}
        />

        <Alert ref={this.alertPopup} />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  isSearching: state.products.isSearching,
  errorOccurred: state.products.errorOccurred,
  searchResult: state.products.searchResult,
  isLoadingTodaysDeals: state.products.isLoadingTodaysDeals,
  searchTerm: state.products.searchTerm,
  searchCategory: state.products.searchCategory,
  // todaysDeals: state.products.todaysDeals,
  session: state.session,
  cart: state.cart.items,
});

const mapDispatchToProps = (dispatch, props) => ({
  startAddProductToCart: (product, productSource) =>
    dispatch(startAddProductToCart(product, productSource)),
  addFavoriteProduct: (user, product) =>
    dispatch(addFavoriteProduct(user, product)),
  removeFavoriteProduct: (product) => dispatch(removeFavoriteProduct(product)),
  addAlertProduct: (user, product) => dispatch(addAlertProduct(user, product)),
  removeAlertProduct: (product) => dispatch(removeAlertProduct(product)),
  setFavoriteProductError: (value) => dispatch(setFavoriteProductError(value)),
  setFavoriteProductSuccess: (value) =>
    dispatch(setFavoriteProductSuccess(value)),
  clearSearchHistory: () => dispatch(clearSearchHistory()),
  searchProduct: (
    searchTerm,
    searchCategory,
    cart,
    latitude,
    longitude,
    filteredStores
  ) =>
    dispatch(
      searchProduct(
        searchTerm,
        searchCategory,
        cart,
        latitude,
        longitude,
        filteredStores
      )
    ),
});

export default withRouter(
  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SearchPage))
);
