import React from "react";
import { Helmet } from "react-helmet";
import MetaTags from "react-meta-tags";
import ReactGA from "react-ga";

export default function Heading(props) {
  switch (props.page) {
    case "notfoundpage":
      return (
        <MetaTags>
          <title>Oops!</title>
          <meta
            id="og-title"
            property="og:title"
            content="Oops!"
          />
          <meta
            id="meta-description"
            name="description"
            content="Oops, we couldn't find the page you were looking for."
          />
        </MetaTags>
      );
    case "productpage":
      return (
        <MetaTags>
          <title>Ooh that looks like a good item on BetterCart.ca</title>
          <meta
            id="og-title"
            property="og:title"
            content="BetterCart - Save Money On Your Groceries Today!"
          />
          <meta
            id="meta-description"
            name="description"
            content="BetterCart.ca is the only site that compares grocery prices in Canada. With BetterCart.ca, you keep more money in your pocket every time you shop."
          />
        </MetaTags>
      );
    case "helppage":
      return (
        <Helmet>
          <title>BetterCart - Save Money On Your Groceries Today!</title>
          <meta
            name="description"
            content="BetterCart.ca is the only site that compares grocery prices in Canada. With BetterCart.ca, you keep more money in your pocket every time you shop."
          />
          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
          ></script>
        </Helmet>
      );
    case "cartpage":
      return (
        <Helmet>
          <title>BetterCart - Save Money On Your Groceries Today!</title>
          <meta
            name="description"
            content="BetterCart.ca is the only site that compares grocery prices in Canada. With BetterCart.ca, you keep more money in your pocket every time you shop."
          />
        </Helmet>
      );
    case "home":
      return (
        <Helmet>
          <title>BetterCart - Save Money On Your Groceries Today!</title>
          <meta
            name="description"
            content="BetterCart.ca is the only site that compares grocery prices in Canada. With BetterCart.ca, you keep more money in your pocket every time you shop."
          />
        </Helmet>
      );
    case "landingpage":
      return (
        <Helmet>
          <script>
            {`
                {
                  !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                  n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
                  n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
                  t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
                  document,'script','https://connect.facebook.net/en_US/fbevents.js');

                  // fbq.disablePushState = true; //not recommended, but can be done

                  fbq('init', '198138651370525');
                  fbq('track', 'PageView');

                  <!-- Google Tag Manager -->
                  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','GTM-PK5TRM8');
                  <!-- End Google Tag Manager -->                  

                 }
              `}
          </script>
          <title>BetterCart - Save Money On Your Groceries Today!</title>
          <meta
            name="description"
            content="BetterCart.ca is the only site that compares grocery prices in Canada. With BetterCart.ca, you keep more money in your pocket every time you shop."
          />
        </Helmet>
      );
    default:
      return (
        <Helmet>
        <title>BetterCart - Save Money On Your Groceries Today!</title>
        <meta
          name="description"
          content="BetterCart.ca is the only site that compares grocery prices in Canada. With BetterCart.ca, you keep more money in your pocket every time you shop."
        />
      </Helmet>
      );
  }
}
