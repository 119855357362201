import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import StoreLogoOnline from "../../StoreLogo/StoreLogoOnline";
import DirectionMap from "./DirectionMap";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    // width:"100",
    display: "grid",
    gridTemplateRows: "65px 40px 1fr 68px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateRows: "65px 30px 1fr 94px",
    },
  },
  storeTextContainer: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  storeText: {
    fontWeight: "500",
    fontFamily: "Montserrat",
    fontSize: "25px",
    lineHeight: "34px",
    [theme.breakpoints.down("sm")]: {
      //display: 'none'
      fontSize: "14px",
      lineHeight: "23px",
    },
  },
  map: {
    //border: '1px solid #ffffaa',
    display: "flex",
    width: "100%",
    //maxWidth: 800,
    //paddingTop: "14px",
    paddingLeft: "0px",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      //display: 'none'
    },
    "& .leaflet-container": {
      height: "100%",
      width: "100%",
    },
    // "& .leaflet-routing-container": {
    //   // display:"none",
    //   width: '320px',
    //   backgroundColor: 'white',
    //   paddingTop: '4px',
    //   transition: 'all 0.2s ease',
    //   boxSizing: 'border-box'
    // }
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "center",
    width: "100%",
    background: theme.palette.secondary.main,
    //background: '#12C50D',
    color: "#fff",
    paddingTop:"16px"
    //height: "57px",
  },

  buttonLink: {
    fontWeight: "bold",
    fontSize: "27px",
    fontFamily: "Montserrat",
    textDecoration: "none",
    color: "#FFF",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
}));

export default function StoreOnMapGrid(props) {
  const classes = useStyles();
  const { store } = props;
  const company = { name: store.companyName, companyLogoLink:store.companyLogoLink };
 
  const position = [
    parseFloat(localStorage.getItem("latitude")),
    parseFloat(localStorage.getItem("longitude")),
  ];
  //https://www.google.ca/maps/dir/
  //1015+Patrick+Crescent,+Saskatoon,+SK/
  //Real+Canadian+Superstore,+2901+8+St+E,+Saskatoon,+SK+S7H+0V4/@52.1292782,-106.5976147,
  //14z
  const urlGoogleCa = "https://www.google.ca/maps/dir/";
  const fromPosition = position; 
  const url = urlGoogleCa.concat(
    fromPosition.join(),
    "/",
    store.storeName.split(" ").join("+"),"/@",
    store.latitude,
    ",",
    store.longitude
  );
  return (
    <div className={classes.root}>
      <StoreLogoOnline company={company} />
      <div className={classes.storeTextContainer}>
        <Typography className={classes.storeText} noWrap={true}>
          {Math.round(store.distance * 100) / 100}km away - {store.storeName}
        </Typography>
      </div>
      <div className={classes.map}>
        <DirectionMap store={store} />
      </div>
      <div className={classes.footer}>
        <a className={classes.buttonLink} target={"_blank"} rel={"noopener noreferrer"}  href={url}>
          Get Directions
        </a>
      </div>
    </div>
  );
}
