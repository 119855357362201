import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import envConfig from "../../bin/env.config";


import Slider from "react-slick";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import { startPriceCompare } from "../../actions/pricecomparison";
import ProductMatch from "../PriceComparison/ProductMatch";

import ProductItem from "./ProductItem";
import StoreLogo from "../StoreLogo/StoreLogo";

import Skeleton from "react-loading-skeleton";

import Heading from "../Heading/Heading";

const sliderSettings = {
  // lazyLoad: 'ondemand',
  dots: true,
  infinite: false,
  arrows: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  // centerMode: true,
  // autoplay: true,
  // autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 320,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const styles = (theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  productItem: {
    margin: theme.spacing(1),
  },
  grow: {
    flexGrow: 1,
  },
  storeMatchContainer: {
    margin: theme.spacing(2),
    paddingBottom: theme.spacing(5),
  },
  storeColumn: {},
  otherStoresTitle: {
    paddingTop: 0,
    marginTop: 0,
  },
  slider: {
    paddingLeft: "55px",
    paddingRight: "55px",
    "& .slick-dots": {
      display: "none !important",
    },
  },
  shareButton: {
    display: "inline-block",
    marginRight: "10px",
    "&:hover": {
      opacity: "0.5",
    },
  },
  snackbarRoot: {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    borderWidth: "2px",
    borderStyle: "solid",
    backgroundColor: "#fff",
    fontWeight: "bold",
    marginTop: "60px",
    marginRight: "-10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  snackbarRootError: {
    color: "#ff0000",
    borderColor: "#ff0000",
    borderWidth: "2px",
    borderStyle: "solid",
    backgroundColor: "#fff",
    fontWeight: "bold",
    marginTop: "60px",
    marginRight: "-10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class ProductDetail extends Component {
  productLoaded = false;

  state = {};

  constructor(props) {
    super(props);
    this.state = {  
      selectedStores: undefined,
    };
    this.state.selectedStores = this.props.searchfilters.filteredStores;   
  }
  
  componentDidMount() {
    if (this.productLoaded === false && this.props.product) {
      this.loadPage();
      this.productLoaded = true;      
    }
  }
  
  loadPage = () => {
    const params = {
      latitude: this.props.location.latitude,
      longitude: this.props.location.longitude,
      products: [],
    };

    const product = this.props.product;
    
    params.products.push({
      productID:product.productID,
      name: product.name,
      brand: product.brand,
      size: product.size,
      brandKeywords: product.brandKeywords,
      nounKeywords: product.nounKeywords,
      adjectivesKeywords: product.adjectivesKeywords,
      sizeKeywords: product.sizeKeywords,
      categoryType: product.categoryType,
      immediateAdjective: product.immediateAdjective,
    });
    // console.log(params);
    this.props.startPriceCompare(params);
  };
  renderStoreMatchesLoadingState = () => {
    const { classes } = this.props;
    return (
      <div className={classes.storeMatchContainer}>
        <div className={"productPageSlider"}>
          <Slider {...sliderSettings} className={classes.slider}>
            {this.props.location.storesNearby.map((store) => {
              return (
                <div>
                  <div style={{ fontSize: 24, marginBottom: 10 }}>
                    <Skeleton width={200} />
                  </div>
                  <ProductMatch
                    isLoading={true}
                    products={[]}
                    onProductPick={(product) => {
                      console.log(product);
                    }}
                  />
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    );
  };
  checkIsDuplicate = (productBeingViewed, storeMatch) => {
    // console.log("productBeingViewed.store.storeID:"+productBeingViewed.store.storeID);
    // console.log("storeMatch.store._id:"+storeMatch.store._id);
    //const store = storeMatch;
    if (productBeingViewed.store.storeID === storeMatch.store._id) {
      if (productBeingViewed._id === storeMatch.matches[0].selectedProduct._id)
        return true;
      //Todo: Need update for case this store has many matched products
    }
    return false;
  };
  renderStoreMatches = () => {
    const { classes } = this.props;

    const { priceComparisonResult } = this.props.pricecomparison;
    // const { productBeingViewed } = this.props.productBeingViewed;
    // console.log(priceComparisonResult);
    // console.log(productBeingViewed);
    return (
          <Grid container spacing={1}>
            {priceComparisonResult.length > 0 &&
              priceComparisonResult.map((storeResult) => {
                if (
                  this.checkIsDuplicate(
                    this.props.product,
                    storeResult
                  )
                ) {
                  // console.log('here');
                  return "";
                }
                return (                 
                    <Grid item xs={12} sm={6} md={4}>
                      <StoreLogo
                        store={storeResult.store}
                        company={storeResult.store.companyData}
                      />
                      <ProductMatch
                        isLoading={false}
                        showAddToCartButton={true}
                        productMatchData={storeResult.matches[0]}
                        onProductPick={(product) => {
                          console.log(product);
                        }}
                      />
                    </Grid>                 
                );
              })}
          </Grid>
    );
  };

  renderRoot = () => {
    const { classes } = this.props;
    return (
      <div>
        <Grid
          className={classes.root}
          container
          spacing={12}
          direction="column"
          alignItems="center"
          justify="center"
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {
              <ProductItem
                className={classes.productItem}
                page="detail"
                horizontal={window.innerWidth < 740 ? false : true}
                isLoading={ !this.props.product}
                product={this.props.product}
                productShare={this.props.productShare}
                // productLike={this.onProductLiked}
                productLike={ this.props.productLike }
                productAlert={ this.props.productAlert}
                // hideProductViewButton={true}
                // showFavoriteButton={true}
                showAlert={this.props.showAlert}
                isAdmin={this.props.isAdmin}
              />
            }
          </Grid>
        </Grid>
        <div>
          <Typography align="center" variant="h6">
            More matches for this product
          </Typography>
          {          
          this.props.pricecomparison.isLoading
            ? this.renderStoreMatchesLoadingState()
            : this.renderStoreMatches()}
        </div>
      </div>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Heading page={"productpage"}/>

        {this.renderRoot()}
        
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  session: state.session,
  location: state.location,
  cart: state.cart.items,
  searchfilters: state.searchfilters,
  pricecomparison: state.pricecomparison,
});

const mapDispatchToProps = (dispatch, props) => ({
  startPriceCompare: (params) => dispatch(startPriceCompare(params)),
});

export default withRouter(
  withStyles(styles)(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ProductDetail)
  )
);
