import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import AppRouter from './routers/AppRouter'
import { SW_INIT, SW_UPDATE } from './store/types';
import * as serviceWorker from './registerServiceWorker'

import { Provider } from 'react-redux'
import store from './store/store'

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import blue from '@material-ui/core/colors/blue'


import ReactGA from 'react-ga'
// import TagManager from 'react-gtm-module'

import envConfig from './bin/env.config'

ReactGA.initialize(envConfig.googleAnalytics)

// const tagManagerArgs = {
//   gtmId: 'GTM-PK5TRM8'
// }

// TagManager.initialize(tagManagerArgs)

// const advancedMatching = {}

// src: https://material-ui.com/customization/themes/
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2650a2'
    },
    secondary: {
      main: '#00b04f',
      contrastText: '#fff'
    },
    primaryLight: {
      main: '#577fcc'
    }
    // ,
    // secondary: { main: '#11cb5f' }, // This is just green.A700 as hex.
  },
  breakpoints: {
    // Define custom breakpoint values.
    // These will apply to Material-UI components that use responsive
    // breakpoints, such as `Grid` and `Hidden`. You can also use the
    // theme breakpoint functions `up`, `down`, and `between` to create
    // media queries for these breakpoints
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      lxl: 1500,
      xl: 1920
    }
  },
  overrides: {
    MuiButton: {
      label: {
        fontFamily: 'Montserrat',
        fontWeight:"600"
      },
    },
  },
})

const jsx = (
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <AppRouter />
    </MuiThemeProvider>
  </Provider>
)

// navigator.geolocation.getCurrentPosition(
//   (position) => {
//     const { latitude, longitude } = position.coords;
//     console.log(latitude);
//     console.log(longitude);
//   },
//   () => {
//   }
// );



ReactDOM.render(jsx, document.getElementById('root'))
// serviceWorker.register({
//   onSuccess: () => store.dispatch({ type: SW_INIT }),
//   onUpdate: registration =>
//     store.dispatch({ type: SW_UPDATE, payload: registration }),
// });
serviceWorker.unregister();
