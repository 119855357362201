import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import axios from "axios";
import envConfig from "../../../../bin/env.config";
import store from "../../../../store/store";

const TotalLeftBlock = forwardRef((props, ref) => {
  const [totalLeft, setTotalLeft] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const fetchTotalLeftData = async (searchText, selectedCategoryId,showMatchNumbers, simSource,selectedList2MatchingStoreId) => {
    setIsLoading(true);
    // console.log('here: fetchTotalData');
    const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/pbs/total-left`;
    // const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/pbs/total`;
    const result = await axios.post(
      url,
      {
        q: searchText,
        categoryId: selectedCategoryId,
        showMatchNumbers:showMatchNumbers,   
        simSource: simSource,
        list2MatchingStoreId: selectedList2MatchingStoreId,
      },
      {
        headers: {
          Authorization: JSON.stringify(store.getState().session.token),
        },
      }
    );
    // console.log(result.data.data);
    setTotalLeft(result.data.data);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchTotalLeftData('','-1','0','0','-1','_ve','0');
  }, []);
  useImperativeHandle(ref, () => ({
    fetchTotalLeftData: fetchTotalLeftData,
  }));
  return (
    <>
      {isLoading ? `Loading ...` : `${totalLeft}`}
    </>
  );
});
export default TotalLeftBlock;
