import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import withMobileDialog from '@material-ui/core/withMobileDialog';

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';
import FacebookIcon from '@material-ui/icons/Facebook';


import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';


import SignUpForm from './SignUpForm';
import LoginForm from './LoginForm';
import envConfig from '../../bin/env.config';

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

// import { login, setLogInLoader, setLogInError } from '../../actions/session';
import { facebookLogin,setLogInError } from '../../actions/session';

import loginMessage from './data/loginMessages';

import { Helpers } from "../../utility/helpers";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
  loginDialogContent: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    //height: '700px',
    maxWidth: '600px',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '350px',
    }  
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#111111',
  },
  loginCard: {
  },
  loginLogo: {
    width: '250px',
    margin: '20px auto',
    display: 'block',    
  },
  loginTitle: {    
    fontWeight: 'bold',    
    fontSize: '28px',    
    fontFamily: 'Montserrat',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    }    
  },
  loginSubTitle: {    
    fontWeight: 'bold',    
    fontSize: '20px',      
    fontFamily: 'Montserrat',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    }    
  },
  capTextButton: {   
    fontWeight: 'bold', 
    fontFamily: 'Montserrat',
    textTransform:'capitalize !important',
    "& span>svg":{
      paddingRight:"4px"
    }    
  },
  
});

class LoginPage extends Component {

  imageURL = `./resources/images/cart-background.jpg`;

  constructor (props) {
    super(props);
    this.state = {
      showEmailSignUpForm: true,
      showEmailLogInForm: false,       
    }; 
    this.state.width = window.innerWidth;
  }

  onEntering = () => {
    this.setState({
      showEmailSignUpForm: true,
      showEmailLogInForm: false,       
    });
  }

  showSignUpForm = () => {
    this.setState({
      showEmailSignUpForm: true,
      showEmailLogInForm: false
    }); 
  }

  onShowLoginForm = () => {
    
    this.setState({
      showEmailSignUpForm: false,
      showEmailLogInForm: true
    });
  }

  onFacebookLogin = (data) => {
    const id = data['id'];
    const firstName = data['first_name'];
    const lastName = data['last_name'];
    const email = data['email'];
    this.props.facebookLogin(id, firstName, lastName, email)
      .then(() => {
        this.onLoginSuccess();
      });
  }

  onLoginSuccess=()=>{
     //Check if not have postal code --> redirect to choose postal code
      //If not have stores picked  --> redirect to choose store
      const {postal} = this.props.location;
      const {filteredStores} = this.props.searchfilters;
      if(!postal){this.props.history.push("/");}
      else{
        // console.log(this.props);
        if(!(Array.isArray(filteredStores) && filteredStores.length)){         
          this.props.history.push("/stores");
        }
      }
      Helpers.updateUserId();
      this.props.onLoginSuccess();
  }
  onClose = () => {
    this.props.onClose();
    this.props.setLogInError(null);
  };
  
  render () {
    const { classes, fullScreen, messageNumber } = this.props;
    let title =  this.props.title;
    let subTitle =  this.props.subTitle;
    if((!title)&&Number.isInteger(messageNumber)){

      title =  loginMessage[messageNumber].title;
      subTitle =  loginMessage[messageNumber].subTitle;
    } 
    if (this.state.showEmailLogInForm){
      title = 'Welcome back, please log in below';
      subTitle=''
    }    
    return (
      
      <Dialog
        fullScreen={ this.state.width<400? true: false }
        fullWidth={ this.state.width<400? true: false }
        onEntering={ this.onEntering }        
        open={this.props.open}
        onClose={this.props.onClose}
        TransitionComponent={Transition}
        aria-labelledby="responsive-dialog-title"
      >        
     
        <DialogTitle >
          <Button                    
            onClick={ this.onClose }
            className={classes.closeButton}
          >
            CLOSE
          </Button>
        </DialogTitle>
        <DialogContent className={ classes.loginDialogContent }>            

            <Typography className={ classes.loginTitle } color="secondary" align="center">{title}</Typography>
            
            <Typography className={ classes.loginSubTitle } color="primary" align="center">{subTitle}</Typography>
              {
                ( !this.state.showEmailLogInForm) &&(
                  <div>
                    
                    <br />
                    <FacebookLogin
                      appId={ envConfig.facebookAppID} 
                      isMobile={true}
                      cookie={true}
                      xfbml={true}
                      autoLoad={false}   
                      redirectUri="https://api.bettercart.ca/api/v1/auth/facebook"
                      disableMobileRedirect={true}        
                      fields="first_name, last_name,email"                      
                      callback={this.onFacebookLogin}
                      render={renderProps => (
                        <Button
                          color="primary"
                          variant="outlined"
                          fullWidth={ true }
                          disabled={ this.props.session.showFacebookLoginLoader }
                          onClick={renderProps.onClick}
                          className={ classes.capTextButton }
                          
                        >
                          <FacebookIcon/>Log in or Sign up with Facebook
                         {this.props.session.showFacebookLoginLoader && <CircularProgress size={24} className={classes.buttonProgress} /> }
                        </Button>
                      )}
                    />


                    <br />
                    <br />

                    <Button
                      color="primary"
                      variant="outlined"
                      fullWidth={ true }
                      onClick={ this.onShowLoginForm }
                      className={ classes.capTextButton }
                    >
                      Log in with Email
                    </Button>
                  </div>                  
                )

              }
              
              {
                this.state.showEmailSignUpForm && (
                  <SignUpForm 
                    onLoginSuccess={ this.onLoginSuccess }
                    onShowLogInForm={ this.onShowLoginForm }
                  />
                )
              }

              {
                this.state.showEmailLogInForm && (
                  <LoginForm 
                    onLoginSuccess={ this.onLoginSuccess }
                    showSignUpForm={ this.showSignUpForm }
                  />
                )
              }


        </DialogContent>
      </Dialog>
    );
  }
};

const mapStateToProps = (state, props) => ({
  location: state.location,
  searchfilters: state.searchfilters,
  session: state.session
});

const mapDispatchToProps = (dispatch, props) => ({
  facebookLogin: (fbID, firstName, lastName, email) => dispatch(facebookLogin(fbID, firstName, lastName, email)),
  setLogInError: (loginError) => dispatch(setLogInError(loginError)),
});

export default withMobileDialog()(withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginPage))));

// export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginForm)));
