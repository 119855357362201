import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import { Utility } from "../../utility/utility";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";

import ErrorMessage from "../Error/ErrorMessage";
import { signup } from "../../actions/session";
import globalStyles from "../../styles/styles";
import axios from "axios";
import envConfig from "../../bin/env.config";

const styles = (theme) => ({
  root: {},
  buttonProgress: {
    ...globalStyles.buttonSpinnerProgress,
  },
  messageText: {
    fontWeight: "bold",
    fontSize: "20px",
    fontFamily: "Montserrat",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  newsletterText: {
    "& .MuiTypography-body1": {
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
  },
});

function debounce(fn, delay) {
  return (args) => {
    clearTimeout(fn.id);

    fn.id = setTimeout(() => {
      fn.call(this, args);
    }, delay);
  };
}
class SignUpForm extends Component {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
    // Debounce
    this.checkIsExist = debounce(this.checkIsExist, 1500);
  }

  componentDidMount() {
    this.setState(this.getDefaultState());
  }

  getDefaultState = () => {
    return {
      fields: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        newsletter: true,
      },
      isLoading: true,
      errors: {},
    };
  };

  onValueChange = (e) => {
    e.persist();
    this.setState((state) => {
      return (state.fields[e.target.name] = e.target.value);
    });
  };

  onEmailChange = (e) => {
    e.persist();
    const email = e.target.value;
    this.setState((state) => {
      return (state.fields[e.target.name] = email);
    });

    if (!email || email.length === 0 || !Utility.isEmailValid(email)) {
      return;
    }
    
    this.checkIsExist(email,this.state.errors) ;   
    
  };
  checkIsExist = async (email, isValid = true) => {
    const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/users/email/availability/${email}`;
    let res = await axios.get(url);    
    let newError = ''
    if(!!res.data.data.available){
      newError =  `Your email address is existing on BetterCart`;
      isValid = false;
    }else{
      isValid = true;
    }
    this.setState((state) => {
      return (state.errors["email"] = newError);
    });
  };
  validateForm = () => {
    let fields = this.state.fields;
    let isFormValid = true;
    let errors = {};

    if (
      !Utility.isSet(fields["firstName"]) ||
      fields["firstName"] === null ||
      fields["firstName"].length === 0
    ) {
      errors["firstName"] = `First Name is required`;
      isFormValid = false;
    }

    if (
      !Utility.isSet(fields["lastName"]) ||
      fields["lastName"] === null ||
      fields["lastName"].length === 0
    ) {
      errors["lastName"] = `Last Name is required`;
      isFormValid = false;
    }

    if (
      !Utility.isSet(fields["email"]) ||
      fields["email"] === null ||
      fields["email"].length === 0
    ) {
      errors["email"] = `Email is required`;
      isFormValid = false;
    }

    if (!Utility.isEmailValid(fields["email"])) {
      errors["email"] = `Please enter a valid email address`;
      isFormValid = false;
    }

    if (
      !Utility.isSet(fields["password"]) ||
      !fields["password"] ||
      fields["password"].length === 0
    ) {
      errors["password"] = `Password is required`;
      isFormValid = false;
    }
    
    this.setState({
      errors: errors,
    });

    this.checkIsExist(fields["email"], isFormValid);

    return isFormValid;
  };

  onSignUp = () => {
    if (!this.validateForm()) {
      return;
    }

    this.props
      .signup({
        firstName: this.state.fields.firstName,
        lastName: this.state.fields.lastName,
        email: this.state.fields.email,
        password: this.state.fields.password,
        newsletter: this.state.newsletter,
      })
      .then(() => {
        this.props.onLoginSuccess();
      });
  };

  onResetPasswordRequest = () => {
    this.props.history.push("/resetpassword/request");
  };

  onNewsletter = (event) => {
    this.setState({
      newsletter: event.target.checked,
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <form className={classes.container} noValidate autoComplete="off">
        {/* {this.props.session.signUpError && <ErrorMessage />} */}
        {/* <Button
          variant="text"
          color="inherit"
          className={ classes.logInButton }
          onClick={ this.props.onShowLogInForm }
          fullWidth={ true } 
          >
          Already a member? Log In
        </Button> */}
        <br />
        <Divider />
        <br />
        <Typography
          className={classes.messageText}
          color="primary"
          align="center"
        >
          New here? Sign up by email
        </Typography>
        <TextField
          label="First Name"
          name="firstName"
          placeholder="Enter your first name"
          fullWidth
          margin="normal"
          variant="outlined"
          onChange={this.onValueChange}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!this.state.errors["firstName"]}
          helperText={this.state.errors["firstName"]}
        />

        <TextField
          label="Last Name"
          name="lastName"
          placeholder="Enter your last name"
          fullWidth
          margin="normal"
          variant="outlined"
          onChange={this.onValueChange}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!this.state.errors["lastName"]}
          helperText={this.state.errors["lastName"]}
        />

        <TextField
          label="Email"
          name="email"
          type="email"
          placeholder="Enter your email"
          fullWidth
          margin="normal"
          variant="outlined"
          onChange={this.onEmailChange}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!this.state.errors["email"]}
          helperText={this.state.errors["email"]}
        />

        <TextField
          label="Password"
          name="password"
          type="password"
          placeholder="Enter your password"
          fullWidth
          margin="normal"
          variant="outlined"
          onChange={this.onValueChange}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!this.state.errors["password"]}
          helperText={this.state.errors["password"]}
        />
        <br />
        <br />
        <FormControlLabel
          control={
            <Checkbox
              name="newsletter"
              checked={this.state.newsletter}
              onChange={this.onNewsletter}
            />
          }
          label="Like good deals? Get the week's best in your email!"
          className={classes.newsletterText}
        ></FormControlLabel>

        <br />
        <br />

        <Button
          onClick={this.onSignUp}
          color="primary"
          variant="contained"
          fullWidth={true}
          disabled={this.props.session.showSignUpLoader}
        >
          Sign Up
          {this.props.session.showSignUpLoader && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>

        <br />
        {/* { <Button
          fullWidth={true}
          variant="text"
          color="inherit"
          className={ classes.logInButton }
          onClick={ this.onResetPasswordRequest }
          >
          Reset Password
        </Button>
        } */}

        <br />
      </form>
    );
  }
}

const mapStateToProps = (state, props) => ({
  session: state.session,
});

const mapDispatchToProps = (dispatch, props) => ({
  signup: (user) => dispatch(signup(user)),
});

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUpForm))
);
