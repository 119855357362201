import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";

import ProductMatchingItem from "./ProductMatchingItem";

import axios from "axios";
import envConfig from "../../bin/env.config";
import store from "../../store/store";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
}));

export default function ProductMatchingGroupPage(props) {
  const history = useHistory();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [productMatches, setProductMatches] = useState([]);
  const [productMatchingGroup, setProductMatchingGroup] = useState({});
  const productMatchingGroupId = props.match.params.id;

  const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/group/${productMatchingGroupId}`;
  // const { productShare, productSelect, productView, productLike, productAlert, showAlert } = props;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const result = await axios(url, {
        headers: {
          Authorization: JSON.stringify(store.getState().session.token),
        },
      });
      console.log(result);
      setProductMatchingGroup(result.data.data.original)
      setProductMatches(result.data.data.matches);
      setIsLoading(false);
    };

    fetchData();
    // console.log(productMatchings);
  }, []);
  const handMatchesClick = () => {
    // console.log(productMatches);
    if (productMatches.length > 0) {
      history.push(`/matching-potential/${productMatchingGroup._product_id}`);
    }
  };
  const handViewAllClick = () => {
    history.push("/matching-groups");
  };

  return (
    <>
      <AppBar position="sticky" color="inherit">
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={() => history.goBack()}
            aria-label="Close"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            Product matching group #{productMatchingGroupId}
          </Typography>
          <div className={classes.grow} />
          <Button
            variant="contained"
            color="secondary"
            onClick={handMatchesClick}
          >
            +Matches
          </Button>
          &nbsp;
          <Button
            variant="contained"
            color="primary"
            onClick={handViewAllClick}
          >
            All <Hidden mdDown>product</Hidden> groups
          </Button>
        </Toolbar>
      </AppBar>

      {isLoading ? (
        <Grid className={classes.root} container>
          <div>Loading ...</div>
        </Grid>
      ) : (
        <Grid className={classes.root} container>
          {productMatches.map((item) => (
            <ProductMatchingItem
              key={item.id}
              productMatch={item}
              showType={"ingroup"}
            />
          ))}
        </Grid>
      )}
    </>
  );
}
