const userDefaultState = {
  favoriteProductLoader: false,
  favoriteProductError: null,
  showFavoriteProductSuccess: false,

  removeFavoriteProductLoader: false,
  removeFavoriteProductError: null,
  showRemoveFavoriteProductSuccess: false,

  removeAlertProductLoader: false,
  removeAlertProductError: null,
  showRemoveAlertProductSuccess: false,

  savedCartLoader: false,
  savedCaartError: null,
  showSavedCartSuccess: false,

  removeSavedCartLoader: false,
  removeSavedCaartError: null,
  showRemoveSavedCartSuccess: false,

  isLoadingUserProfile: false,
  errorOccurredLoadingUserProfile: false, 

};

export default (state = userDefaultState, action) => {
  switch (action.type) {
    case 'SET_FAVORITE_PRODUCT_LOADER' :
      return {
        ...state,
        favoriteProductLoader: action.favoriteProductLoader
      };

    case 'SET_FAVORITE_PRODUCT_ERROR' :
      return {
        ...state,
        favoriteProductError: action.favoriteProductError
      };

    case 'SHOW_FAVORITE_PRODUCT_SUCCESS' :
      return {
        ...state,
        showFavoriteProductSuccess: action.showFavoriteProductSuccess
      };
 
    case 'SET_REMOVE_FAVORITE_PRODUCT_LOADER' :
      return {
        ...state,
        removeFavoriteProductLoader: action.removeFavoriteProductLoader
      };

    case 'SET_REMOVE_FAVORITE_PRODUCT_ERROR' :
      return {
        ...state,
        removeFavoriteProductError: action.removeFavoriteProductError
      };

    case 'SHOW_REMOVE_FAVORITE_PRODUCT_SUCCESS' :
      return {
        ...state,
        showRemoveFavoriteProductSuccess: action.showRemoveFavoriteProductSuccess
      };

      //-------------

      case 'SET_ALERT_PRODUCT_LOADER' :
      return {
        ...state,
        alertProductLoader: action.alertProductLoader
      };

    case 'SET_ALERT_PRODUCT_ERROR' :
      return {
        ...state,
        alertProductError: action.alertProductError
      };

    case 'SHOW_ALERT_PRODUCT_SUCCESS' :
      return {
        ...state,
        showAlertProductSuccess: action.showAlertProductSuccess
      };
 
    case 'SET_REMOVE_ALERT_PRODUCT_LOADER' :
      return {
        ...state,
        removeAlertProductLoader: action.removeAlertProductLoader
      };

    case 'SET_REMOVE_ALERT_PRODUCT_ERROR' :
      return {
        ...state,
        removeAlertProductError: action.removeAlertProductError
      };

    case 'SHOW_REMOVE_ALERT_PRODUCT_SUCCESS' :
      return {
        ...state,
        showRemoveAlertProductSuccess: action.showRemoveAlertProductSuccess
      };
      //-------------

    case 'SET_SAVED_CART_LOADER' :
      return {
        ...state,
        savedCartLoader: action.savedCartLoader
      };

    case 'SET_SAVED_CART_ERROR' :
      return {
        ...state,
        savedCaartError: action.savedCaartError
      };

    case 'SHOW_SAVED_CART_SUCCESS' :
      return {
        ...state,
        showSavedCartSuccess: action.showSavedCartSuccess
      };

    case 'SET_REMOVE_SAVED_CART_LOADER' :
      return {
        ...state,
        removeSavedCartLoader: action.removeSavedCartLoader
      };

    case 'SET_REMOVE_SAVED_CART_ERROR' :
      return {
        ...state,
        removeSavedCaartError: action.removeSavedCaartError
      };

    case 'SHOW_REMOVE_SAVED_CART_SUCCESS' :
      return {
        ...state,
        showRemoveSavedCartSuccess: action.showRemoveSavedCartSuccess
      };

    case 'SET_USER_PROFILE_LOADER' :
      return {
        ...state,
        isLoadingUserProfile: action.isLoadingUserProfile
      };

    case 'SET_USER_PROFILE_ERROR' :
      return {
        ...state,
        errorOccurredLoadingUserProfile: action.errorOccurredLoadingUserProfile
      };

    
    default:
      return state;
  }
};
