import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// import Clear from '@material-ui/icons/Clear';
import Delete from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import LoginPage from "../Login/LoginPage";

import { Helpers } from "../../utility/helpers";
import { Utility } from "../../utility/utility";
import Grid from "@material-ui/core/Grid";
import ReactToPrint from "react-to-print";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import ErrorMessage from "../Error/ErrorMessage";

import ReactGA from "react-ga";

import { addSavedCart } from "../../actions/session";
import { setSavedCartSuccess } from "../../actions/user";

import {
  startRemoveProductToCart,
  startClearCart,
  updateProduct,
} from "../../actions/cart";
import { Hidden } from "@material-ui/core";

import Alert from "../Alert/Alert";
import PriceComparisonSummaryDialog from "../PriceComparisonSummary/PriceComparisonSummaryDialog";

const styles = (theme) => ({
  root: {
    width: "600px",
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "300px",
    },
  },
  productImage: {
    width: 50,
    height: 50,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: 25,
      height: 25,
    },
  },
  card: {
    boxShadow: "none",
  },
  small: {
    "& svg": {
      fontSize: 18,
    },
  },
  listInstructions: {
    color: "#aaa",
    display: "block",
    paddingTop: theme.spacing(2),
    textAlign: "center",
  },
  itemActions: {
    display: "flex",
    alignItems: "center",
    "@media(print)": {
      display: "none",
    },
  },
  moneyBag: {
    fontSize: "1.4em",
  },
  seeComparisons: {
    padding: "0 22px",
    height: "40px",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    marginBottom: "20px",
  },
  header: {
    textAlign: "center",
    paddingBottom: "20px",
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    fontSize: "28px",
    fontFamily: "Montserrat",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  subTitle: {
    color: theme.palette.secondary.main,
    fontWeight: "bold",
    fontSize: "14px",
    fontFamily: "Montserrat",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },
  actionButton: {
    margin: "5px",
    padding: "5px",
    width: "90% !important",
    fontWeight: "bold",
    fontFamily: "Montserrat",
  },
  buttonText: {
    fontFamily: "Montserrat",
    [theme.breakpoints.down("sm")]: {
      fontSize: "11px",
    },
  },
});

class Cart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoginModalOpen: false,
      showSavedCartSuccess: false,
      isCartNameModalOpen: false,
      cartName: "",
      errors: {},
      isPriceComparisonSummaryDialogOpen:false
    };
    this.alertPopup = React.createRef();
    ReactGA.pageview("/YourList");
  }

  showAlert = (severity, message) => {
    this.alertPopup.current.doAlert(severity, message);
  };
  removeProduct = (product) => {
    this.props.startRemoveProductToCart(product);
    this.showAlert("error", "Success! removed from your shopping cart");
  };

  onHideSavedCartSuccess = () => {
    this.setState({
      isCartNameModalOpen: false,
      cartName: "",
    });
    this.props.setSavedCartSuccess(false);
  };

  renderSubHeader = () => {
    const productCount = this.props.cart.length;
    return `${productCount} item${
      productCount > 1 || productCount == 0 ? "s" : ""
    }`;
  };

  // openViewAllDialog = (companyData) => {
  //   this.setState({
  //     isViewAllDialogOpen: true,
  //     viewAllDialogData: companyData,
  //   });
  // };

  processProduct = () => {
    // const { customSeeMySavingsHandlerFn } = this.props;

    // // customSeeMySavingsHandlerFn={ () => { if (customSeeMySavingsHandlerFn) { customSeeMySavingsHandlerFn() } } }

    // if (customSeeMySavingsHandlerFn) {
    //   customSeeMySavingsHandlerFn();
    // } else {
    //   this.props.history.push("/pricecomparesummary");
    // }
    this.openPriceComparisonSummaryDialog();
  };

  onCartNameChange = (e) => {
    this.setState({
      cartName: e.target.value,
    });
  };

  closeCartDrawer = () => {
    this.setState({ isCartDrawerOpen: false });
  };

  openLoginPageModal = () => {
    this.setState({ isLoginModalOpen: true });
  };
  closeLoginPageModal = () => {
    this.setState({ isLoginModalOpen: false });
  };
  openPriceComparisonSummaryDialog = () => {
    this.setState({ isPriceComparisonSummaryDialogOpen: true });
  };
  closePriceComparisonSummaryDialog = () => {
    this.setState({ isPriceComparisonSummaryDialogOpen: false });
  };

  openCartNameModal = () => {
    this.setState({ isCartNameModalOpen: true });
    // console.log("setting to true");
  };

  closeCartNameModal = () => {
    this.setState({ isCartNameModalOpen: false });
    // console.log("setting to false");
  };

  increaseQuantity = (product) => {
    product.quantity++;
    this.props.updateProduct(product);
    //#20200605 this.showAlert("success", "Success! added to your shopping cart");
  };

  decreaseQuantity = (product) => {
    product.quantity--;
    this.props.updateProduct(product);
    //#20200605 this.showAlert("error", "Success! removed from your shopping cart");
  };

  onSaveCartHandler = () => {
    const { session } = this.props;
    // console.log("in save cart handler");
    if (!Helpers.isLoggedIn(session.user)) {
      this.openLoginPageModal();
      return;
    }

    this.openCartNameModal();
  };

  startCartSaving = () => {
    const { session, cart } = this.props;

    if (!this.validateForm()) {
      return;
    }

    const cartForSaving = [];
    for (let product of cart) {
      const p = { ...product };
      p.sourceProductID = product._id;
      cartForSaving.push(p);
    }

    this.props.addSavedCart(this.state.cartName, cartForSaving);
  };

  validateForm = () => {
    let isFormValid = true;
    let errors = {};

    if (
      !Utility.isSet(this.state.cartName) ||
      !this.state.cartName ||
      this.state.cartName.length === 0
    ) {
      errors["cartName"] = `Cart Name is required`;
      isFormValid = false;
    }

    this.setState({
      errors: errors,
    });

    return isFormValid;
  };

  onLoginSuccessHandler = () => {
    this.closeLoginPageModal();
    this.onSaveCartHandler();
  };

  render() {
    const { classes, hideRemoveButtons } = this.props;

    return (
      <div className={classes.root}>
        <Card className={classes.card}>
          <CardContent>
            <div className={classes.header}>
              <Typography className={classes.title}>
                Your shopping cart:
              </Typography>
              <Typography className={classes.subTitle}>
                If you need to make any changes to your list or want to share it
                with someone, go for it!
              </Typography>
            </div>
            <Grid container spacing="24">
              {this.props.cart.length !== 0 ? (
                <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                  <Button
                    //size="small"
                    fullWidth={true}
                    className={classes.actionButton}
                    onClick={this.onSaveCartHandler}
                    variant="outlined"
                  >
                    Save
                  </Button>
                </Grid>
              ) : null}
              {this.props.cart.length !== 0 ? (
                <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                  <Button
                    //size="small"
                    fullWidth={true}
                    className={classes.actionButton}
                    onClick={this.onSaveCartHandler}
                    variant="outlined"
                  >
                    Share
                  </Button>
                </Grid>
              ) : null}
              {this.props.cart.length !== 0 ? (
                <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                  <ReactToPrint
                    trigger={() => (
                      <Button
                        className={classes.actionButton}
                        //size="small"
                        fullWidth={true}
                        onClick={this.onPrint}
                        variant="outlined"
                      >
                        Print
                      </Button>
                    )}
                    content={() => this.componentRef}
                  />
                </Grid>
              ) : null}
              {this.props.cart.length !== 0 ? (
                <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                  <Button
                    disabled={this.props.cart.length === 0}
                    onClick={() => this.props.startClearCart()}
                    fullWidth={true}
                    variant="outlined"
                    //size="small"
                    className={classes.actionButton}
                  >
                    Clear
                  </Button>
                </Grid>
              ) : null}
            </Grid>
            {this.props.cart.length > 0 ? (
              <React.Fragment>
                <span className={classes.listInstructions}>
                  Change quantities or remove products from your list below:
                </span>

                <List component="nav" ref={(el) => (this.componentRef = el)}>
                  {this.props.cart.map((product) => (
                    <ListItem key={product._id} disableGutters={true}>
                      <img
                        className={classes.productImage}
                        src={product.imageURL}
                      />
                      <Typography
                        variant="body1"
                        style={{ flex: 1 }}
                        gutterBottom={true}
                      >
                        {Helpers.fullProductNameWithBrand(product)}{" "}
                        {Helpers.productSize(product)}
                        <br />
                        <b>
                          {Helpers.isSpecialDiscount(product)
                            ? Helpers.dealText(product)
                            : Helpers.pricingText(product)}
                        </b>
                      </Typography>

                      <div className={classes.itemActions}>
                        <IconButton
                          className={classes.small}
                          size="small"
                          title="Decrease quantity"
                          onClick={() => {
                            this.decreaseQuantity(product);
                          }}
                          disabled={product.quantity == 1}
                        >
                          <Remove />
                        </IconButton>
                        <span>{product.quantity}</span>
                        <IconButton
                          className={classes.small}
                          size="small"
                          title="Increase quantity"
                          onClick={() => {
                            this.increaseQuantity(product);
                          }}
                        >
                          <Add />
                        </IconButton>

                        {!hideRemoveButtons && (
                          // <Typography variant="caption">
                          //   <ListItemIcon className={classes.small} aria-label="Remove product" onClick={ () => this.removeProduct(product) }>
                          //     <Delete />
                          //   </ListItemIcon>
                          // </Typography>
                          <IconButton
                            className={classes.small}
                            size="small"
                            title="Remove product"
                            onClick={() => {
                              this.removeProduct(product);
                            }}
                          >
                            <Delete />
                          </IconButton>
                        )}
                      </div>
                    </ListItem>
                  ))}
                </List>
              </React.Fragment>
            ) : (
              <span className={classes.listInstructions}>
                Your cart is empty
              </span>

              // <Typography color="textSecondary" variant="caption" align="center">
              //   Your list is empty
              // </Typography>
            )}
          </CardContent>
          <CardActions>
            <Button
              color="primary"
              className={classes.seeComparisons}
              disabled={this.props.cart.length === 0}
              size="large"
              variant="contained"
              fullWidth={true}
              onClick={this.processProduct}
            >
              <Hidden smDown>See price comparisons across stores </Hidden>
              <Hidden smUp>See store comparisons</Hidden>
            </Button>
          </CardActions>
        </Card>

        <LoginPage
          open={this.state.isLoginModalOpen}
          onClose={this.closeLoginPageModal}
          onLoginSuccess={this.onLoginSuccessHandler}
          title="To save your grocery list with BetterCart, log in or sign up below:"
          subTitle=""
        />
        <PriceComparisonSummaryDialog
           open={this.state.isPriceComparisonSummaryDialogOpen}
           onClose={this.closePriceComparisonSummaryDialog}
        />
        <Dialog
          open={
            this.state.isCartNameModalOpen &&
            this.props.user.showSavedCartSuccess === false
          }
          onClose={this.closeCartNameModal}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Save your list</DialogTitle>
          <DialogContent>
            {this.props.user.savedCaartError && <ErrorMessage />}

            <DialogContentText>
              Please enter a name for your list.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              variant="outlined"
              label="Cart Name"
              type="text"
              fullWidth
              onChange={this.onCartNameChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {this.state.errors["cartName"] && (
              <Typography variant="caption" color="error">
                {this.state.errors["cartName"]}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeCartNameModal} color="primary">
              Cancel
            </Button>
            <Button
              onClick={this.startCartSaving}
              color="primary"
              variant="contained"
              disabled={this.props.user.savedCartLoader}
            >
              Save
              {this.props.user.savedCartLoader && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.props.user.showSavedCartSuccess}
          autoHideDuration={3000}
          onClose={this.onHideSavedCartSuccess}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">Your list has been saved</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.onHideSavedCartSuccess}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
        <Alert ref={this.alertPopup} />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  session: state.session,
  cart: state.cart.items,
  user: state.user,
});

const mapDispatchToProps = (dispatch, props) => ({
  startRemoveProductToCart: (product) =>
    dispatch(startRemoveProductToCart(product)),
  startClearCart: () => dispatch(startClearCart()),
  updateProduct: (product) => dispatch(updateProduct(product)),
  addSavedCart: (cartName, products) =>
    dispatch(addSavedCart(cartName, products)),
  setSavedCartSuccess: (showSavedCartSuccess) =>
    dispatch(setSavedCartSuccess(showSavedCartSuccess)),
});

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Cart)
  )
);
