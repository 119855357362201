import React, { useState, useEffect, useRef } from "react";
import { useHistory, Link } from "react-router-dom";
import { withStyles, makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Chip from "@material-ui/core/Chip";
import Alert from "../Alert/Alert";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";

import Button from "@material-ui/core/Button";
import Slider from "@material-ui/core/Slider";
import Badge from "@material-ui/core/Badge";
import SearchBox from "../ProductMatching/SearchBox";
import axios from "axios";
import envConfig from "../../bin/env.config";
import {
  fetchCategories
} from "../../actions/categories";
import { useDispatch, useSelector } from "react-redux";

import { Helpers } from "../../utility/helpers";

import TableRowLoadMore from "./TableRowLoadMore";
import ReviewPanel from "./Components/ReviewPanel";

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "8px",
    paddingTop: "8px",
  },
  toolbarRight: {
    minHeight: "64px",
  },
  toolbarTop: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    width:"100%"
  },
  
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  drawerFooter: {
    borderTop: "2px solid green",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "18px",
    //paddingBottom: "px",
  },
  drawerFooterContainer: {
    height: "300px",
    width: drawerWidth,
    overflow: "auto",
  },
  drawerFooterImage: {
    width: 200,
    height: 200,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  reviewPanel: {
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    justifyContent: "start",
  },
  productImage: {
    objectFit: "contain",
    width: 50,
    height: 50,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: 25,
      height: 25,
    },
    cursor: "pointer",
  },
  acceptContainer: {
    backgroundColor: "#DDD",
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  sliderContainer: {
    width: 300,
    paddingRight: 20,
  },
  totalBlock: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
    minHeight: "64px",
    backgroundColor: theme.palette.primary.main,
    // fontSize:"36px",
    color: "#FFF",
  },
  selectListGroup:{
    color: theme.palette.secondary.main,
    // fontSize:"1.2rem",
    backgroundColor:"#fff",
    marginRight:"8px",
    '& div':{
      paddingLeft:"8px"
    }
  },
  coopListLink: {
    textDecoration: "none",
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  totalLeftBlock: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexWrap:"nowrap",
    width: "100%",
    minHeight: "30px",
    backgroundColor: theme.palette.secondary.main,
    fontSize:"1rem",
    color: "#FFF",
  },
}));
const ThresholdSlider = withStyles({
  root: {
    color: "#52af77",
    // height: 8,
    padding: 0,
  },
  thumb: {
    height: 18,
    width: 18,
    backgroundColor: "#fff",
    border: "1px solid #fff",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
    color: "#52af77",
  },
  track: {
    height: 2,
    borderRadius: 4,
  },
  rail: {
    height: 2,
    borderRadius: 4,
  },
})(Slider);
export default function ProductSimilarPage(props) {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch()

  const [loadMore, setLoadMore] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [limit, setLimit] = useState(50);
  const [skip, setSkip] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("no");
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState({});
  const [acceptedGroup, setAcceptedGroup] = useState([]);
  

  const categories = useSelector(state => state.categories.categories);
  const token = useSelector(state => state.session.token);
 
  useEffect(() => {
    categories.length===0&&dispatch(fetchCategories());
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {   
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/similar/list?orderBy=${orderBy}&order=${order}&limit=${limit}&skip=${skip}`;
      const result = await axios.post(
        url,
        {
          q: searchText,
        },
        {
          headers: {
            Authorization: JSON.stringify(token),
          },
        }
      );
      if (skip === 0) {
        setGroups(result.data.data);

        if (selectedGroup) {
          const found = result.data.data.some(
            (el) => el.id === selectedGroup.id
          );
          !found && setSelectedGroup(result.data.data[0]);
        } else {
          setSelectedGroup(result.data.data[0]);
          console.log(result.data.data[0]);
        }
      } else {
        setGroups([...groups, ...result.data.data]);
      }
      setLoadMore(result.data.data.length === limit);     
    };

    fetchData();
  }, [
    searchText,   
    skip,
    order,
    orderBy  
  ]);
  const doLoadMore = (isVisible) => {
    isVisible && setSkip(skip + limit);
  };
  const doSearch = (q) => {
    setSearchText(q);
    setSkip(0);    
  };
  
  const moveToNextGroup = ()=>{
    if (selectedGroup && selectedGroup.id > 0) {
      let retGroups = groups.filter((p) => p.id != selectedGroup.id);
      setGroups(retGroups);
      setSelectedGroup(retGroups[0]);
      setAcceptedGroup([
        selectedGroup,
        ...acceptedGroup.filter((p) => p.id != selectedGroup.id),
      ]);
    }    
  }
  const handleAccept = (e) => {
    moveToNextGroup()
  };
  const handleAllWrong = (e) => {
    moveToNextGroup()
  };
 
 

  

  const handleSelectedGroupClick = (group) => {
    setSelectedGroup(group);
  };
 

  return (
    <div className={classes.root}>
      {/* <CssBaseline /> */}
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={() => history.goBack()}
            aria-label="Close"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            {selectedGroup && (
              <>
                {/* {selectedGroup.product_upc}{`. `}   */}
                {selectedGroup.name}
                {selectedGroup.size &&
                  `(${selectedGroup.size})`}
              </>
            )}
          </Typography>  
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      > 
      <div className={classes.totalBlock}>
          ADQ Similar Product Matching Tool
        </div>
        <div className={classes.toolbar}>
          <SearchBox
            doSearch={doSearch}
            value={searchText}
            searchOnChange={false}
          />{" "}
        </div>
        <Divider />
        <div className={classes.drawerContainer}>
          <List className={classes.listProducts}>
            {groups.length > 0 &&
              groups.map((group, index) => (
                <ListItem
                  button
                  key={group.id}
                  selected={
                    selectedGroup ? group.id === selectedGroup.id : false
                  }
                  onClick={() => handleSelectedGroupClick(group)}
                >
                  <ListItemIcon>
                    <img
                      className={classes.productImage}
                      alt={group.name}
                      src={
                        group.image_url
                          ? group.image_url
                          : "/resources/images/no-picture-available.png"
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${group.no}.${ Helpers.titleCase(group.name)}`}
                    secondary={`${group.size}`}
                  />
                  {/* <ListItemSecondaryAction>
                    {!group.accept_count && (
                      <Chip label={group.matched_count} color="primary" />
                    )}
                    {group.accept_count > 0 && (
                      <Badge color="primary" badgeContent={group.accept_count}>
                        <Chip label={group.matched_count} color="secondary" />
                      </Badge>
                    )}
                  </ListItemSecondaryAction> */}
                </ListItem>
              ))}
          </List>
          {loadMore && <TableRowLoadMore doLoadMore={doLoadMore} colSpan={4} />}
        </div>
       
        <div className={classes.drawerFooter}>
          <div className={classes.drawerFooterContainer}>
            <List className={classes.listProducts}>
              {acceptedGroup.length > 0 &&
                acceptedGroup.map((group, index) => (
                  <ListItem
                    button
                    key={group.id}
                    selected={
                      selectedGroup ? group.id === selectedGroup.id : false
                    }
                    onClick={() => handleSelectedGroupClick(group, true)}
                  >
                    <ListItemIcon>
                      <img
                        className={classes.productImage}
                        alt={group.product.name}
                        src={
                          group.product.image_url
                            ? group.product.image_url
                            : "/resources/images/no-picture-available.png"
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${group.no}.${ Helpers.titleCase(group.product.name)}`}
                      secondary={`${group.product.size} #${group.product_upc}`}
                    />
                  </ListItem>
                ))}
            </List>
          </div>         
        </div>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbarRight} />
        <div className={classes.reviewPanel}>
          {selectedGroup && (
            <ReviewPanel
              selectedGroup={selectedGroup}             
              handleAccept={handleAccept}
              handleAllWrong={handleAllWrong}
            />
          )}
        </div>
        
      </main>
      {/* <Alert ref={alertPopup} /> */}
    </div>
  );
}
