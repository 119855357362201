export class Utility {

  static isSet (val) {
    return val !== undefined;
  }

  static isEmailValid (email) {
    const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return EMAIL_REGEXP.test(email);
  }

  static isPhoneValid (phone) {
    // const PHONE_REGEXP = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    const PHONE_REGEXP = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i;
    return PHONE_REGEXP.test(phone);
  }

  static isNumber (value) {
    return typeof value === 'number';
  }

  static isString (value) {
    return typeof value === 'string';
  }

  static isEmpty (value) {
    return (!value || 0 === value.length);
  }

  static isArray (value) {
    return value.constructor === Array;
  }

  static isObject (value) {
    return value !== null && typeof value === 'object';
  }

  static roundTo2Decimal (num) {
    return Math.round(num * Math.pow(10, 2)) / Math.pow(10, 2);
  }

  static roundTo1Decimal (num) {
    return Math.round(num * Math.pow(10, 1)) / Math.pow(10, 1);
  }  
};
const nth = function(d) {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:  return "st";
    case 2:  return "nd";
    case 3:  return "rd";
    default: return "th";
  }
}
export function convertToViewDate(inputDate){
  const tempDate = new Date(inputDate)
  const date = tempDate.getDate();
  // const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][tempDate.getMonth()]
  const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][tempDate.getMonth()]
  const year = tempDate.getFullYear();
  return `${month} ${date}${nth(date)}, ${year}`;
} 