import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Fab from "@material-ui/core/Fab";
import ReactGA from "react-ga";
const useStyles = makeStyles((theme) => ({
 
  dialogRoot:{
   
  },
  introduction: {
    display: "flex",
    position: "relative",
    padding: "0px",
    minHeight: "400px",
    width: "100%",
    backgroundColor: "#f6f6f6",
  },
  introMessage: {
   // paddingLeft: "70px",
    //margin: '15px',
    minHeight: "50px",

    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
    
      paddingLeft: 0,
      maxWidth: "85%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  introMessageText: {
    paddingTop: "50px",
    fontWeight: "bold",
    fontSize: "50px",
    fontFamily: "Montserrat",
    lineHeight: "1.2em",
    marginBottom: "0.2em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "26px",
      paddingTop: "20px",
    },
  },
  introDetailMessageText: {
    fontWeight: "bold",
    fontSize: "16px",
    fontFamily: "Montserrat",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
  },
  
  introImage2: {
    width: "800px",
    marginLeft: "auto",
    marginRight: "2em",
    [theme.breakpoints.down("sm")]: {
      height: "180px",
      width: "200px",
    },
  },
   
  introImageMob1: {
    maxWidth: "100%",
    width: "324px",
    height: "auto",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  introImageMob1b: {
    maxWidth: "100%",
    width: "324px",
    height: "auto",
    display: "block",
    marginTop: "20px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  introImageMob2a: {
    maxWidth: "100%",
  },
  introImageMob2b: {
    maxWidth: "100%",
    width: "324px",
    height: "auto",
    display: "block",
    marginTop: "20px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  introImageMob3: {
    maxWidth: "100%",
    width: "324px",
    height: "auto",
    display: "block",
    marginTop: "20px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  introImageMob4: {
    maxWidth: "100%",
    width: "324px",
    height: "auto",
    display: "block",
    marginTop: "20px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  introImageMob5: {
    maxWidth: "100%",
    width: "324px",
    height: "auto",
    display: "block",
    marginTop: "20px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  introImageMob6: {
    maxWidth: "100%",
    width: "324px",
    height: "auto",
    display: "block",
    marginTop: "20px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  introCloseButton: {
    right: "10px",
    paddingTop: "10px",
    position: "absolute",
  },
  introStepText: {
    //paddingTop: "26px",
    fontWeight: "bold",
    fontSize: "32px",
    fontFamily: "Montserrat",
    [theme.breakpoints.up("sm")]: {
      paddingLeft:"20px",
      paddingRight:"20px"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "26px",
      paddingTop: "10px",
      lineHeight: "1.2em",
    },
  },
  introStepTextCol: {
    paddingTop: "52px",
    fontWeight: "bold",
    fontSize: "32px",
    fontFamily: "Montserrat",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  introStepButtonContainer:{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    height: "100px"
  },
  introStepButton: {
    // paddingTop: '10px',
    position: "relative",
    marginRight: "8px",
    [theme.breakpoints.down("sm")]: {
      // marginLeft: "auto",
      marginTop: "20px",
    },
  },
  needHelpButton:{
    position:"fixed",
    bottom: "10px",
    right: "10px",
    fontSize: "14px",
    textTransform: "capitalize",
    zIndex: "99",
    backgroundColor: theme.palette.primary.main,
    padding: "15px",
    borderRadius: "50%",   
  }
}));

export default function Introduction(props) {
  const [step, setStep] = useState(1);
  const classes = useStyles();
  const { setHideInstruction, hideInstruction } = props;

  useEffect(() => {
    if (hideInstruction) {
      setStep(0);
    }
  });
  const onClickCloseButton = () => {
    setStep(0);
    setHideInstruction();
  };
  const onClickOpenButton =()=>{
    setStep(1);
    setHideInstruction(false);
  }
  const onClickShowIntroductionButton = () => {
    setStep(2);
  };
  const onClickNextButton = () => {
    let nextStep = step + 1;
    setStep(nextStep);
  };
  const onClickBackButton = () => {
    let backStep = step - 1;
    setStep(backStep);
  };
  const renderIntroductionWelcome = () => {
    return (
      <div className={classes.introduction}>
        <div className={classes.introMessage}>
            <img
              src="./resources/images/introImageMob1.png"
              className={classes.introImageMob1}
            />
          <Typography color="secondary" className={classes.introMessageText}>
            Welcome to your savings!
          </Typography>
          <Typography
            color="secondary"
            className={classes.introDetailMessageText}
          >
            We compare grocery prices so you always get the best deals.
          </Typography>
          <br />         
          <Button
            size="large"
            variant="contained"
            color="secondary"
            onClick={onClickShowIntroductionButton}
          >
            Show me how BetterCart works
           </Button>
          
            <img
              src="./resources/images/introImageMob1b.png"
              className={classes.introImageMob1b}
            />
          
        </div>
       
        <Button
          className={classes.introCloseButton}
          onClick={onClickCloseButton}
        >
          Close
        </Button>
      </div>
    );
  };
  const renderIntroductionStep1 = () => {
    return (
      <div className={classes.introduction}>
        <div className={classes.introMessage}>          
            <img
              src="./resources/images/introImageMob2a.png"
              className={classes.introImageMob2a}
            />
          
          <Typography color="secondary" className={classes.introStepText}>
            Step 1: &nbsp;Search for a product
          </Typography>
                  
            
              <img
                src="./resources/images/introImageMob2b.gif"
                className={classes.introImageMob2b}
              />
           
          <div className={classes.introStepButtonContainer}>
           <Button
            size="large"
            variant="contained"
            color="secondary"
            className={classes.introStepButton}
            onClick={onClickBackButton}
          >
            Back
          </Button>

          <Button
            size="large"
            variant="contained"
            color="secondary"
            className={classes.introStepButton}
            onClick={onClickNextButton}
          >
            Next
          </Button>
          </div>
          <br/>
        </div>
        <Button
          className={classes.introCloseButton}
          onClick={onClickCloseButton}
        >
          Close
        </Button>
      </div>
    );
  };

  const renderIntroductionStep2 = () => {
    return (
      <div className={classes.introduction}>
        <div className={classes.introMessage}>
          
          
            <br />
            <br />
            <Typography color="secondary" className={classes.introStepText}>
              Step 2: &nbsp;Click on a product to view price comparisons
            </Typography>
           
              <img
                src="./resources/images/introImageMob3.png"
                className={classes.introImageMob3}
              />
           
           <div className={classes.introStepButtonContainer}>
           <Button
            size="large"
            variant="contained"
            color="secondary"
            className={classes.introStepButton}
            onClick={onClickBackButton}
          >
            Back
          </Button>

          <Button
            size="large"
            variant="contained"
            color="secondary"
            className={classes.introStepButton}
            onClick={onClickNextButton}
          >
            Next
          </Button>
          </div>
            <br />
            <br />
         
        </div>
        <Button
          className={classes.introCloseButton}
          onClick={onClickCloseButton}
        >
          Close
        </Button>
      </div>
    );
  };

  const renderIntroductionStep3 = () => {
    return (
      <div className={classes.introduction}>
        <div className={classes.introMessage}>
          
         
            <br />
            <br />
            <Typography color="secondary" className={classes.introStepText}>
              Step 3: &nbsp;We'll show you the prices at all your stores
            </Typography>
            <img
              src="./resources/images/introImageMob4.png"
              className={classes.introImageMob4}
            />
            <div className={classes.introStepButtonContainer}>
           <Button
            size="large"
            variant="contained"
            color="secondary"
            className={classes.introStepButton}
            onClick={onClickBackButton}
          >
            Back
          </Button>

          <Button
            size="large"
            variant="contained"
            color="secondary"
            className={classes.introStepButton}
            onClick={onClickNextButton}
          >
            Next
          </Button>
          </div>
            <br />
            <br />
         
        </div>
        <Button
          className={classes.introCloseButton}
          onClick={onClickCloseButton}
        >
          Close
        </Button>
      </div>
    );
  };
  const renderIntroductionStep4 = () => {
    return (
      <div className={classes.introduction}>
        <div className={classes.introMessage}>
        
            <br />
            <br />
            <Typography color="secondary" className={classes.introStepText}>
              Step 4: &nbsp;Add it to your shopping cart
            </Typography>
          
         
          <Typography color="secondary">
            You don’t even have to pick the cheapest, we’ll find that for you!
          </Typography>
          
            <img
              src="./resources/images/introImageMob5.png"
              className={classes.introImageMob5}
            />
          
        
          <div className={classes.introStepButtonContainer}>
           <Button
            size="large"
            variant="contained"
            color="secondary"
            className={classes.introStepButton}
            onClick={onClickBackButton}
          >
            Back
          </Button>

          <Button
            size="large"
            variant="contained"
            color="secondary"
            className={classes.introStepButton}
            onClick={onClickNextButton}
          >
            Next
          </Button>
          </div>
          <br />
          <br />
        </div>
        <Button
          className={classes.introCloseButton}
          onClick={onClickCloseButton}
        >
          Close
        </Button>
      </div>
    );
  };

  const renderIntroductionStep5 = () => {
    return (
      <div className={classes.introduction}>
        <div className={classes.introMessage}>
         
            <br />
            <br />
         
          <Typography color="secondary" className={classes.introStepText}>
            Step 5: &nbsp;View price comparisons for your whole list and save
            big money!
          </Typography>
          <br />
         
            <img
              src="./resources/images/introImageMob6.png"
              className={classes.introImageMob6}
            />
         <div className={classes.introStepButtonContainer}>
           
         <Button
            size="large"
            variant="contained"
            color="secondary"
            className={classes.introStepButton}
            onClick={onClickBackButton}
          >
            Back
          </Button>
          <Button
            size="large"
            variant="contained"
            color="secondary"
            className={classes.introStepButton}
            onClick={onClickCloseButton}
          >
            Start
          </Button>
          </div>
          
          <br />
          <br />
         
        </div>
        <Button
          className={classes.introCloseButton}
          onClick={onClickCloseButton}
        >
          Close
        </Button>
      </div>
    );
  };
  return (
    <>
    <Dialog
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      open={!hideInstruction}
      onClose={onClickCloseButton}
      className={classes.dialogRoot}
    >
     
        {step === 1 && renderIntroductionWelcome()}
        {step === 2 && renderIntroductionStep1()}
        {step === 3 && renderIntroductionStep2()}
        {step === 4 && renderIntroductionStep3()}
        {step === 5 && renderIntroductionStep4()}
        {step === 6 && renderIntroductionStep5()}
     
    </Dialog>
    {/* {hideInstruction&&<Fab color="primary" className={classes.needHelpButton} onClick={onClickOpenButton}>Help</Fab>} */}
    </>
  );
}
