const priceComparisonDefaultState = {
  isLoading: true,
  errorOccurred: false,
  selectedStoreCart:undefined,
  priceComparisonResult: [],
  storesWithBestDeals: [],
  bestDeals: [],
  products: []
};

export default (state = priceComparisonDefaultState, action) => {
  switch (action.type) {
    case 'SET_LOADING' :
      return {
        ...state,
        isLoading: action.isLoading,
        priceComparisonResult: [],
        storesWithBestDeals: [],
        errorOccurred: false
      };
    case 'SET_ERROR_OCCURRED' :
      return {
        ...state,
        isLoading: false,
        errorOccurred: action.errorOccurred
      };
    case 'SET_PRICE_COMPARISON_RESULT' :
      const data = parseComparisonResult(state, action);
      return data; 
    
    case 'LOAD_PRICE_COMPARISON_RESULT_FROM_LOCAL' :                
        return {
          ...state,
          isLoading: false,
          errorOccurred: false,
          priceComparisonResult: action.data.priceComparisonResult,
          storesWithBestDeals: action.data.storesWithBestDeals,
          bestDeals: action.data.bestDeals
        };

    case 'SET_PRODUCTS' :
      return {
        ...state,
        products: action.products
      }; 
    case 'SET_SELECTED_STORE_CART' :           
      localStorage.setItem('selectedstorecart', JSON.stringify(action.selectedStoreCart));

      return {
        ...state,        
        selectedStoreCart: action.selectedStoreCart
      };

    case 'UPDATE_PRODUCT_PICK' :
      const product = {...action.product};
      const store = action.store; 
      const prodIndex = action.row; 
      const storeIndex = action.col;

      //  console.log('priceComparisonResult:'+ JSON.stringify(state.priceComparisonResult));
      //  console.log(storeIndex);
      //  console.log(storeIndex);
      //  console.log(prodIndex);

      const storeToUpdate = state.priceComparisonResult[storeIndex];

      // update "selected" product in the pricecomparison.result[storeIndex][prodIndex]
      // product.quantity = state.bestDeals[prodIndex].matches[0].selectedProduct.quantity;
      storeToUpdate.matches[prodIndex].selectedProduct = product;

      // priceComparisonResult: [],
      // storesWithBestDeals: [],
      // bestDeals: [],

      // update bestStore
      const oldBestDeals = state.bestDeals;
      oldBestDeals[prodIndex] = {
        store: storeToUpdate.store,
        matches: [storeToUpdate.matches[prodIndex]]
      };

      // console.log([storeToUpdate.matches[prodIndex]]);
      
      let priceComparison ={
        ...state,        
        bestDeals: [...oldBestDeals]
      }
      localStorage.setItem('pricecomparison', JSON.stringify(priceComparison));
      
      return {
        ...state,
        bestDeals: [...oldBestDeals]
      };
    default:
      return state;
  }
};

function parseComparisonResult (state, action) {
  const priceComparisonResult = action.priceComparisonResult.result;
  const storesWithBestDeals = action.priceComparisonResult.storesWithBestDeals;
  const cartItems = action.cart;
  const bestDeals = [];   

  let index = 0;
  for (let result of priceComparisonResult) {
    
    let pIndex = 0;
    for (let m of result.matches) {
      
      if (cartItems.length > pIndex) {
        m.quantity = cartItems[pIndex].quantity;
      }
      pIndex++;
    }
      
    index++;
  }

  let productIndex = 0;
  for (let storeWithBestDeal of storesWithBestDeals) {

    if (!storeWithBestDeal) {
      continue;
    }

    // find this in the result
    for (let result of priceComparisonResult) {
      if (result.store._id === storeWithBestDeal._id) {
        const productMatch = {...result.matches[productIndex]};

        // if (productMatch && productMatch.selectedProduct) {
        //   productMatch.selectedProduct.quantity = cartItems[productIndex].quantity;
        // }

        //console.log('cartItems.length:'+ cartItems.length);
        if (cartItems.length > productIndex) {
          productMatch.quantity = cartItems[productIndex].quantity;
        }

        bestDeals.push({
          store: storeWithBestDeal,
          matches: [productMatch]
        });
        break;
      }
    }
    
    productIndex++;
  }
  const priceComparison ={
    ...state,
    isLoading: false,
    errorOccurred: false,
    priceComparisonResult: priceComparisonResult,
    storesWithBestDeals: storesWithBestDeals,
    bestDeals: bestDeals
  }
  localStorage.setItem('pricecomparison', JSON.stringify(priceComparison));
  return {
    ...state,
    isLoading: false,
    errorOccurred: false,
    priceComparisonResult: priceComparisonResult,
    storesWithBestDeals: storesWithBestDeals,
    bestDeals: bestDeals
  }
}
