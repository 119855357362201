import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import Button from "@material-ui/core/Button";
import axios from "axios";
import envConfig from "../../../bin/env.config";
import store from "../../../store/store";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "flex-end",
    // justifyItems: 'stretch',
    alignItems: "center",
  },
}));
// import StoreIcon from "@material-ui/icons/Store";

export default function MatchingGroup({ productToMatch, matchingGroup }) {
  const history = useHistory();
  const classes = useStyles();
  const handleMatchClick = () => {
    const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/match`;
    const postData = {
      productId: productToMatch.id,
      groupId: matchingGroup.id,
    };
    // console.log(postData);
    axios
      .post(url, postData, {
        headers: {
          Authorization: JSON.stringify(store.getState().session.token),
        },
      })
      .then((response) => {
        history.push(`/matching-potential/${productToMatch._id}`);
      })
      .catch((err) => {
        console.log(err);
      });

    // onRemove&&onRemove(productMatch.id);
  };

  return (
    <TableRow>
      <TableCell style={{ width: 200 }}>{matchingGroup.brand_name}</TableCell>
      <TableCell >{matchingGroup.name}</TableCell>
      <TableCell style={{ width: 100 }} align="center">{matchingGroup.size}</TableCell>
      <TableCell style={{ width: 100 }} align="center">
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          // className={classes.removeButton}
          onClick={handleMatchClick}
        >
          Match
        </Button>
      </TableCell>
    </TableRow>
  );
}
// export const MemoizedProductToMatch = React.memo(ProductToMatch);
