import React from 'react'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
  root: {
    display: '100%'
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    alignItems: 'right',
    background: '#000',
    // background: '#12C50D',
    padding: '8px',
    [theme.breakpoints.down('sm')]: {
      // flexDirection: "column",
    }
  },
  button: {
    marginTop: '2px',
    marginRight:'8px',
    color: '#fff',
    border:'solid 1px #fff',
    '&:hover': {
      color: '#000',
      background:'#DDD'
    },
  },
  buttonText: {
    fontFamily: 'Montserrat',
    textTransform: "none"   
  }
}))
export default function AdminBanner (props) {
  const history = useHistory()
  const classes = useStyles()
  const goToURL = (url) => {
    history.push(url)    
  }
  return (
    <div className={classes.container}>
       <Button
        className={classes.button}
        onClick={() => goToURL('/link-manage/dashboard')}
        // disabled={ !this.state.searchValue || this.state.searchValue.length < 6 }
        variant='outlined'
        size="small"
        disableElevation
      >
        <Typography className={classes.buttonText} noWrap>
          Admin Dashboard
        </Typography>
      </Button> 
      {/* <Button
        className={classes.button}
        onClick={() => goToURL('/parser-manage/dashboard')}
        // disabled={ !this.state.searchValue || this.state.searchValue.length < 6 }
        variant='outlined'
        size="small"
        disableElevation
      >
        <Typography className={classes.buttonText} noWrap>
          Parser log
        </Typography>
      </Button>  */}
      <Button
        className={classes.button}
        onClick={() => goToURL('/matching/check-not-up-to-date-products')}
        // disabled={ !this.state.searchValue || this.state.searchValue.length < 6 }
        variant='outlined'
        size="small"
        disableElevation
      >
        <Typography className={classes.buttonText} noWrap>
          Check Data
        </Typography>
      </Button>    
      {/* <Button
        className={classes.button}
        onClick={() => goToURL('/matching/ml-review')}
        // disabled={ !this.state.searchValue || this.state.searchValue.length < 6 }
        variant='outlined'
        size="small"
        disableElevation
      >
        <Typography className={classes.buttonText} noWrap>
          ML Results
        </Typography>
      </Button> */}
      <Button
        className={classes.button}
        onClick={() => goToURL('/matching-groups')}
        // disabled={ !this.state.searchValue || this.state.searchValue.length < 6 }
        variant='outlined'
        size="small"
        disableElevation
      >
        <Typography className={classes.buttonText} noWrap>
          Product Groups
        </Typography>
      </Button>
      {/* <Button
        className={classes.button}
        onClick={() => goToURL('/similar-groups')}
        // disabled={ !this.state.searchValue || this.state.searchValue.length < 6 }
        variant='outlined'
        size="small"
        disableElevation
      >
        <Typography className={classes.buttonText} noWrap>
          Similar Groups
        </Typography>
      </Button> */}
      {/* <Button
        className={classes.button}
        onClick={() => goToURL('/matching/sim/review')}
        // disabled={ !this.state.searchValue || this.state.searchValue.length < 6 }
        variant='outlined'
        size="small"
        disableElevation
      >
        <Typography className={classes.buttonText} noWrap>
          Sim 2 Review
        </Typography>
      </Button> */}
      
      <Button
        className={classes.button}
        onClick={() => goToURL('/matching/pbs/review')}
        // disabled={ !this.state.searchValue || this.state.searchValue.length < 6 }
        variant='outlined'
        size="small"
        disableElevation
      >
        <Typography className={classes.buttonText} noWrap>
          Matching Tool
        </Typography>
      </Button>   
      {/* <Button
        className={classes.button}
        onClick={() => goToURL('/matching/golden-test')}
        // disabled={ !this.state.searchValue || this.state.searchValue.length < 6 }
        variant='outlined'
        size="small"
        disableElevation
      >
        <Typography className={classes.buttonText} noWrap>
          Golden Test
        </Typography>
      </Button>    */}
      {/* <Button
        className={classes.button}
        onClick={() => goToURL('/matching/store-test')}
        // disabled={ !this.state.searchValue || this.state.searchValue.length < 6 }
        variant='outlined'
        size="small"
        disableElevation
      >
        <Typography className={classes.buttonText} noWrap>
          Store Test
        </Typography>
      </Button>   */}
      
    </div>
  )
}
