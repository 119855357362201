const statusStock= {
  0:{
    "_id": 0,
    "name": "Unknown",
    "color": "#ACACAC"
  },
  3:{
    "_id": 3,
    "name": "A Lot",
    "color": "#00B04F"
  },
  2:{
    "_id": 2,
    "name": "A Little",
    "color": "#D8AE00"
  },
  1:{
    "_id": 1,
    "name": "None",
    "color": "#ED2121"
  }
};
export default statusStock;
