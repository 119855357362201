import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// import { startPriceCompare, setSelectedStoreCart} from '../../actions/pricecomparison';

import PriceComparisonSummaryPageDialog from "./PriceComparisonSummaryPageDialog";
import PriceComparisonDialog from "../PriceComparison/PriceComparisonDialog";
import CartStorePageDialog from "../CartStore/CartStorePageDialog";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    //marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

export default function PriceComparisonSummaryDialog(props) {
  const [currentView, setCurrentView] = useState('PriceComparisonSummaryPageDialog');
  const classes = useStyles();
  const { open, onClose, isLoading, onSelectStoreCart, priceComparisonResult} = props;
  // const onSelectStoreCart =(store) =>{
  //   this.props.setSelectedStoreCart(store);
  //   this.props.history.push('/cartstore');
  // }
  const doClose=()=>{
    if(currentView!='PriceComparisonSummaryPageDialog'){
      setCurrentView('PriceComparisonSummaryPageDialog')
    }else{
      onClose();
    }
  }
  const showSelectedStore=()=>{
    setCurrentView('CartStorePageDialog')
  }
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar position="sticky" color="inherit">
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={doClose}
              aria-label="Close"
            >
              <ArrowBackIcon />
            </IconButton>
            {/* <button onClick={()=>setCurrentView('PriceComparisonSummaryPageDialog')}>Summary</button> */}
            {/* <button onClick={()=>setCurrentView('PriceComparisonDialog')}>Comparison</button> */}
            {/* <button onClick={()=>setCurrentView('CartStorePageDialog')}>CartSore</button> */}
          </Toolbar>
        </AppBar>
        {currentView==='PriceComparisonSummaryPageDialog'&&(<PriceComparisonSummaryPageDialog viewSideBySide={()=>setCurrentView('PriceComparisonDialog')} showSelectedStore={showSelectedStore} />)}
        {currentView==='PriceComparisonDialog'&&(<PriceComparisonDialog showSelectedStore={showSelectedStore} />)}
        {currentView==='CartStorePageDialog'&&(<CartStorePageDialog showSelectedStore={showSelectedStore} />)}
        {/* <PriceComparisonSummary     
                  isLoading={ isLoading }     
                  selectStoreCart = {this.onSelectStoreCart}       
                  matchedData={ priceComparisonResult }
                /> */}
    </Dialog>
  );
}
